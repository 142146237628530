import React, { useState } from "react";
import travelIcon from "../../../assets/arrival.svg";
import trainIcon from "../../../assets/trainicon.svg";
import busIcon from "../../../assets/busicon.svg";
import carIcon from "../../../assets/caricon.svg";
import lunchIcon from "../../../assets/dinerIcon.svg";
import breakfastIcon from "../../../assets/cafeIcon.svg";
import dinerIcon from "../../../assets/Corasiny.svg";
import snackIcon from "../../../assets/snack.svg";
import buildingIcon from "../../../assets/building.svg";
import activityIcon from "../../../assets/activityicon.svg";
import otherIcon from "../../../assets/other.svg";
import landmarkIcon from "../../../assets/landmark.svg"
import airbnbIcon from "../../../assets/airbnb.svg"
import AccomodationCreate from "./newacomo";
import NewActivityCreateForm from "./newactivity";
import NewTravelCreateForm from "./newtravel";
import MealCreateForm from "./newmeal";

const RightSideContainer = ({ category, onAccommodationClick, onTravelClick, onMealClick, onActivityClick }) => {
  return (
    <div className="flex justify-end items-center">
      {category === "Travel" && (
        <>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onTravelClick('Plane')}>
            <img src={travelIcon} alt={'travel'} className="w-5 h-5 mr-4" />
            Plane
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onTravelClick('Bus')}>
            <img src={busIcon} alt={'travel'} className="w-5 h-5 mr-4" />
            Bus
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onTravelClick('Train')}>
            <img src={trainIcon} alt={'travel'} className="w-5 h-5 mr-4" />
            Train
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onTravelClick('Car')}>
            <img src={carIcon} alt={'travel'} className="w-5 h-5 mr-4" />
            Car
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onTravelClick('Other')}>
            <img src={otherIcon} alt={'travel'} className="w-5 h-5 mr-4" />
            Other
          </button>
        </>
      )}

      {category === "Activity" && (
        <>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onActivityClick('Tour')}>
            <img src={activityIcon} alt={'activity'} className="w-5 h-5 mr-4" />
            Tour
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onActivityClick('Landmark')}>
            <img src={landmarkIcon} alt={'landmark'} className="w-5 h-5 mr-4" />
            Landmark
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onActivityClick('Other')}>
            <img src={otherIcon} alt={'activity'} className="w-5 h-5 mr-4" />
            Other
          </button>
        </>
      )}

      {category === "Meal" && (
        <>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onMealClick('Breakfast')}>
            <img src={breakfastIcon} alt={'meal'} className="w-5 h-5 mr-4" />
            Breakfast
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onMealClick('Lunch')}>
            <img src={lunchIcon} alt={'meal'} className="w-5 h-5 mr-4" />
            Lunch
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onMealClick('Dinner')}>
            <img src={dinerIcon} alt={'meal'} className="w-5 h-5 mr-4" />
            Dinner
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onMealClick('Snack')}>
            <img src={snackIcon} alt={'meal'} className="w-5 h-5 mr-4" />
            Snack
          </button>
        </>
      )}

      {category === "Accommodation" && (
        <>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onAccommodationClick('Hotel')}>
            <img src={buildingIcon} alt={'accommodation'} className="w-5 h-5 mr-4" />
            Hotel
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onAccommodationClick('Airbnb')}>
            <img src={airbnbIcon} alt={'accommodation'} className="w-5 h-5 mr-4" />
            Airbnb
          </button>
          <button className="flex items-center bg-basic text-black py-2 px-4 rounded mr-2" onClick={() => onAccommodationClick('Other')}>
            <img src={otherIcon} alt={'accommodation'} className="w-5 h-5 mr-4" />
            Other
          </button>
        </>
      )}
    </div>
  );
};



const DropdownComponent = ({ city, userEmail }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAccommodation, setSelectedAccommodation] = useState("");
  const [selectedMeal, setSelectedMeal] = useState("");
  const [selectedTravel, setSelectedTravel] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");
  const [showAccommodationCreate, setShowAccommodationCreate] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  const handleAccommodationClick = (accommodation) => {
    setSelectedAccommodation(accommodation);
    setShowAccommodationCreate(true);
  };

  const handleTravelClick = (travel) => {
    setSelectedTravel(travel);
    setShowAccommodationCreate(true);
  };

  const handleMealClick = (food) => {
    setSelectedMeal(food);
    setShowAccommodationCreate(true);
  };

  const handleActivityClick = (activity) => {
    setSelectedActivity(activity);
    setShowAccommodationCreate(true);
  };

  const handleCloseModal = () => {
    setDropdownOpen(false);
    setShowAccommodationCreate(false);
  };

  const handleSuccess = () => {
    handleCloseModal();
  };

  return (
    <div className=" relative z-50 flex gap-4 border border-dashed py-6 px-4 lgmeduim:px-2 border-light_green rounded-md bg-white my-4">
      <div className="flex pl-3 md:pl-6">
        <div className="relative z-10">
          <button
            onClick={handleDropdownToggle}
            className="bg-black text-white py-2 px-4 rounded"
          >
            New Activity
          </button>

          {isDropdownOpen && (
            <div className="absolute left-0 mt-2 bg-white border border-dashed px-4 py-2 rounded">
              <p className="cursor-pointer hover:text-light_green" onClick={() => handleCategoryClick('Travel')}>
                Travel
              </p>
              <p className="cursor-pointer hover:text-light_green" onClick={() => handleCategoryClick('Activity')}>
                Activity
              </p>
              <p className="cursor-pointer hover:text-light_green" onClick={() => handleCategoryClick('Meal')}>
                Meal
              </p>
              <p className="cursor-pointer hover:text-light_green" onClick={() => handleCategoryClick('Accommodation')}>
                Accommodation
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end flex-grow">
        <RightSideContainer
          category={selectedCategory}
          city={city}
          onAccommodationClick={handleAccommodationClick}
          onTravelClick={handleTravelClick}
          onMealClick={handleMealClick}
          onActivityClick={handleActivityClick} // Here, corrected the prop name
        />
      </div>
      
      {/* Modal for AccommodationCreate */}
      {showAccommodationCreate && (
        <div className="fixed top-0 left-10 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="left-10 bg-white rounded w-50 h-60 overflow-y-auto relative">
            <button
              className="absolute top-0 left-0 m-2 text-gray-600"
              onClick={handleCloseModal}
            >
              X
            </button>
            <div className="flex justify-center items-center h-20">
              <h2 className="text-center font-bold">
                New{" "}
                {selectedCategory === "Accommodation"
                  ? "Accommodation"
                  : selectedCategory}
              </h2>
            </div>
            {/* Render respective component based on selectedCategory */}
            {selectedCategory === "Accommodation" && (
              <AccomodationCreate
                selectedAccommodation={selectedAccommodation}
                city={city}
                userEmail={userEmail}
                onSuccess={handleSuccess}
              />
            )}
            {selectedCategory === "Activity" && (
              <NewActivityCreateForm 
                selectedActivity={selectedActivity}
                city={city}
                userEmail={userEmail}
                onSuccess={handleSuccess} />
            )}
            {selectedCategory === "Travel" && (
              <NewTravelCreateForm 
                selectedTravel={selectedTravel}
                city={city}
                userEmail={userEmail}
                onSuccess={handleSuccess} />
            )}
            {selectedCategory === "Meal" && (
              <MealCreateForm 
                selectedMeal={selectedMeal}
                city={city}
                userEmail={userEmail}
                onSuccess={handleSuccess} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
