import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Food } from "../../../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../../../ui-components/utils";
import { DataStore } from "aws-amplify/datastore";
import { post } from 'aws-amplify/api';

export default function FoodEditForm(props) {
  const {
    idProp,
    food: foodModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    onClose,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    startdate: "",
    time: "",
    image: "",
    category: "",
    latitude: "",
    longitude: "",
    address: "",
    type: "",
    foodtype: "",
    numberofpeople: "",
    price: "",
    telephone: "",
    website: "",
    place: "", // Add place to initial values
  };
  const [name, setName] = React.useState(initialValues.name);
  const [startdate, setStartdate] = React.useState(initialValues.startdate);
  const [time, setTime] = React.useState(initialValues.time);
  const [image, setImage] = React.useState(initialValues.image);
  const [category, setCategory] = React.useState(initialValues.category);
  const [latitude, setLatitude] = React.useState(initialValues.latitude);
  const [longitude, setLongitude] = React.useState(initialValues.longitude);
  const [address, setAddress] = React.useState(initialValues.address);
  const [type, setType] = React.useState(initialValues.type);
  const [foodtype, setFoodtype] = React.useState(initialValues.foodtype);
  const [numberofpeople, setNumberofpeople] = React.useState(initialValues.numberofpeople);
  const [price, setPrice] = React.useState(initialValues.price);
  const [telephone, setTelephone] = React.useState(initialValues.telephone);
  const [website, setWebsite] = React.useState(initialValues.website);
  const [place, setPlace] = React.useState(initialValues.place); // State for place
  const [errors, setErrors] = React.useState({});
  const [foodRecord, setFoodRecord] = React.useState(foodModelProp);

  const resetStateValues = () => {
    const cleanValues = foodRecord ? { ...initialValues, ...foodRecord } : initialValues;
    setName(cleanValues.name);
    setStartdate(cleanValues.startdate);
    setTime(cleanValues.time);
    setImage(cleanValues.image);
    setCategory(cleanValues.category);
    setLatitude(cleanValues.latitude);
    setLongitude(cleanValues.longitude);
    setAddress(cleanValues.address);
    setType(cleanValues.type);
    setFoodtype(cleanValues.foodtype);
    setNumberofpeople(cleanValues.numberofpeople);
    setPrice(cleanValues.price);
    setTelephone(cleanValues.telephone);
    setWebsite(cleanValues.website);
    setPlace(cleanValues.place); // Reset place
    setErrors({});
  };

  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp ? await DataStore.query(Food, idProp) : foodModelProp;
      setFoodRecord(record);
    };
    queryData();
  }, [idProp, foodModelProp]);

  React.useEffect(resetStateValues, [foodRecord]);

  const validations = {
    name: [],
    startdate: [],
    time: [],
    image: [],
    category: [],
    latitude: [],
    longitude: [],
    address: [],
    type: [],
    foodtype: [],
    numberofpeople: [],
    price: [],
    telephone: [{ type: "Phone" }],
    website: [{ type: "URL" }],
    place: [], // Add validation for place if needed
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFindClick = async () => {
    try {
      if (!place) {
        console.error('Place is empty');
        return;
      }
      console.log(`Finding place: ${place}`);
      
      const restOperation = await post({
        apiName: 'test2',
        path: '/items',
        options: {
          body: {
            query: place,
          }
        }
      });

      console.log('call', restOperation);
      const { body } = await restOperation.response;
      const response = await body.json();
      
      console.log('POST call succeeded:', response);
      if (response && response.places && response.places.length > 0) {
        const placeData = response.places[0];
        setAddress(placeData.formattedAddress);
        setTelephone(placeData.internationalPhoneNumber);
        setWebsite(placeData.websiteUri);
        setLatitude(placeData.location.latitude);
        setLongitude(placeData.location.longitude);
        setImage(placeData.photoUrl);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          startdate,
          time,
          image,
          category,
          latitude,
          longitude,
          address,
          type,
          foodtype,
          numberofpeople,
          price,
          telephone,
          website,
          place, // Include place in model fields
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(runValidationTasks(fieldName, modelFields[fieldName]));
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Food.copyOf(foodRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
          onClose(); 
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "FoodUpdateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>

      <TextField
        label="Place"
        isRequired={false}
        isReadOnly={false}
        value={place}
        onChange={(e) => {
          let { value } = e.target;
          setPlace(value);
        }}
        {...getOverrideProps(overrides, "place")}
      ></TextField>
      <Button onClick={handleFindClick} style={{ backgroundColor: "#2CAFBC", color: "white" }}>
        Find
      </Button>

      <TextField
        label="Startdate"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={startdate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate: value,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.startdate ?? value;
          }
          if (errors.startdate?.hasError) {
            runValidationTasks("startdate", value);
          }
          setStartdate(value);
        }}
        onBlur={() => runValidationTasks("startdate", startdate)}
        errorMessage={errors.startdate?.errorMessage}
        hasError={errors.startdate?.hasError}
        {...getOverrideProps(overrides, "startdate")}
      ></TextField>

      <TextField
        label="Time"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={time}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time: value,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.time ?? value;
          }
          if (errors.time?.hasError) {
            runValidationTasks("time", value);
          }
          setTime(value);
        }}
        onBlur={() => runValidationTasks("time", time)}
        errorMessage={errors.time?.errorMessage}
        hasError={errors.time?.hasError}
        {...getOverrideProps(overrides, "time")}
      ></TextField>

      <TextField
        label="Image"
        isRequired={false}
        isReadOnly={false}
        value={image}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image: value,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.image ?? value;
          }
          if (errors.image?.hasError) {
            runValidationTasks("image", value);
          }
          setImage(value);
        }}
        onBlur={() => runValidationTasks("image", image)}
        errorMessage={errors.image?.errorMessage}
        hasError={errors.image?.hasError}
        {...getOverrideProps(overrides, "image")}
      ></TextField>

      <TextField
        label="Category"
        isRequired={false}
        isReadOnly={false}
        value={category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category: value,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          if (errors.category?.hasError) {
            runValidationTasks("category", value);
          }
          setCategory(value);
        }}
        onBlur={() => runValidationTasks("category", category)}
        errorMessage={errors.category?.errorMessage}
        hasError={errors.category?.hasError}
        {...getOverrideProps(overrides, "category")}
      ></TextField>

      <TextField
        label="Latitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={latitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude: value,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.latitude ?? value;
          }
          if (errors.latitude?.hasError) {
            runValidationTasks("latitude", value);
          }
          setLatitude(value);
        }}
        onBlur={() => runValidationTasks("latitude", latitude)}
        errorMessage={errors.latitude?.errorMessage}
        hasError={errors.latitude?.hasError}
        {...getOverrideProps(overrides, "latitude")}
      ></TextField>

      <TextField
        label="Longitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={longitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude: value,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.longitude ?? value;
          }
          if (errors.longitude?.hasError) {
            runValidationTasks("longitude", value);
          }
          setLongitude(value);
        }}
        onBlur={() => runValidationTasks("longitude", longitude)}
        errorMessage={errors.longitude?.errorMessage}
        hasError={errors.longitude?.hasError}
        {...getOverrideProps(overrides, "longitude")}
      ></TextField>

      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address: value,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>

      <TextField
        label="Type"
        isRequired={false}
        isReadOnly={false}
        value={type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type: value,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.type ?? value;
          }
          if (errors.type?.hasError) {
            runValidationTasks("type", value);
          }
          setType(value);
        }}
        onBlur={() => runValidationTasks("type", type)}
        errorMessage={errors.type?.errorMessage}
        hasError={errors.type?.hasError}
        {...getOverrideProps(overrides, "type")}
      ></TextField>

      <TextField
        label="Foodtype"
        isRequired={false}
        isReadOnly={false}
        value={foodtype}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype: value,
              numberofpeople,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.foodtype ?? value;
          }
          if (errors.foodtype?.hasError) {
            runValidationTasks("foodtype", value);
          }
          setFoodtype(value);
        }}
        onBlur={() => runValidationTasks("foodtype", foodtype)}
        errorMessage={errors.foodtype?.errorMessage}
        hasError={errors.foodtype?.hasError}
        {...getOverrideProps(overrides, "foodtype")}
      ></TextField>

      <TextField
        label="Numberofpeople"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={numberofpeople}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople: value,
              price,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.numberofpeople ?? value;
          }
          if (errors.numberofpeople?.hasError) {
            runValidationTasks("numberofpeople", value);
          }
          setNumberofpeople(value);
        }}
        onBlur={() => runValidationTasks("numberofpeople", numberofpeople)}
        errorMessage={errors.numberofpeople?.errorMessage}
        hasError={errors.numberofpeople?.hasError}
        {...getOverrideProps(overrides, "numberofpeople")}
      ></TextField>

      <TextField
        label="Price"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={price}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price: value,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.price ?? value;
          }
          if (errors.price?.hasError) {
            runValidationTasks("price", value);
          }
          setPrice(value);
        }}
        onBlur={() => runValidationTasks("price", price)}
        errorMessage={errors.price?.errorMessage}
        hasError={errors.price?.hasError}
        {...getOverrideProps(overrides, "price")}
      ></TextField>

      <TextField
        label="Telephone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={telephone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone: value,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.telephone ?? value;
          }
          if (errors.telephone?.hasError) {
            runValidationTasks("telephone", value);
          }
          setTelephone(value);
        }}
        onBlur={() => runValidationTasks("telephone", telephone)}
        errorMessage={errors.telephone?.errorMessage}
        hasError={errors.telephone?.hasError}
        {...getOverrideProps(overrides, "telephone")}
      ></TextField>

      <TextField
        label="Website"
        isRequired={false}
        isReadOnly={false}
        value={website}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website: value,
              place,
            };
            const result = onChange(modelFields);
            value = result?.website ?? value;
          }
          if (errors.website?.hasError) {
            runValidationTasks("website", value);
          }
          setWebsite(value);
        }}
        onBlur={() => runValidationTasks("website", website)}
        errorMessage={errors.website?.errorMessage}
        hasError={errors.website?.hasError}
        {...getOverrideProps(overrides, "website")}
      ></TextField>

      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || foodModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || foodModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
