import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Dashboard/DashbordLayout/DashboardLayout";
import TripsCreateFormmod from "./TripsCreateForm";
import DropZoneInputExample from "./imagepicker";

const Newtripscreen = () => {
     

  return (
  <DashboardLayout className="">
  <div className="welcome-message pl-10">
    <h2 className="text-2xl font-semibold mb-4">Where's your next adventure?</h2>
    <p>Start planning your journey</p>
   <div className="pl-10 w-4/5">
  <TripsCreateFormmod></TripsCreateFormmod>
  </div>

   </div>
</DashboardLayout>
  );
};

export default Newtripscreen;
