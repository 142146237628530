import React, { useState } from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Food, Useritins } from "../../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../../ui-components/utils";
import awsmobile from "../../../aws-exports";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from 'aws-amplify/storage';
import DropZoneInputExample from "../../../pages/Newtrip/imagepicker";
import { deleteTemporaryFile } from "./deletetemp";
import { post } from 'aws-amplify/api';

export default function MealCreateForm({ onSuccess, ...props }) {
  const {
    clearOnSuccess = true,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    selectedMeal,
    city,
    userEmail,
    ...rest
  } = props;

  const initialValues = {
    name: "",
    startdate: "",
    time: "",
    image: "",
    category: "Food",
    tripsID: city.id,
    latitude: "",
    longitude: "",
    address: "",
    type: selectedMeal,
    foodtype: "",
    numberofpeople: "",
    price: "",
    telephone: "",
    website: "",
    place: "",  // Add place to initial values
  };

  const [name, setName] = useState(initialValues.name);
  const [startdate, setStartdate] = useState(initialValues.startdate);
  const [time, setTime] = useState(initialValues.time);
  const [image, setCoverimage] = useState(initialValues.image);
  const [category, setCategory] = useState(initialValues.category);
  const [latitude, setLatitude] = useState(initialValues.latitude);
  const [longitude, setLongitude] = useState(initialValues.longitude);
  const [address, setAddress] = useState(initialValues.address);
  const [type, setType] = useState(initialValues.type);
  const [foodtype, setFoodtype] = useState(initialValues.foodtype);
  const [numberofpeople, setNumberofpeople] = useState(initialValues.numberofpeople);
  const [price, setPrice] = useState(initialValues.price);
  const [telephone, setTelephone] = useState(initialValues.telephone);
  const [website, setWebsite] = useState(initialValues.website);
  const [place, setPlace] = useState(initialValues.place);  // State for place
  const [errors, setErrors] = useState({});
  const [s3Key, sets3key] = useState(null);
  const [fileType, setfiletype] = useState(null);
  const [tempFile, settempfile] = useState(null);

  const resetStateValues = () => {
    setName(initialValues.name);
    setStartdate(initialValues.startdate);
    setTime(initialValues.time);
    setCoverimage(initialValues.image);
    setCategory(initialValues.category);
    setLatitude(initialValues.latitude);
    setLongitude(initialValues.longitude);
    setAddress(initialValues.address);
    setType(initialValues.type);
    setFoodtype(initialValues.foodtype);
    setNumberofpeople(initialValues.numberofpeople);
    setPrice(initialValues.price);
    setTelephone(initialValues.telephone);
    setWebsite(initialValues.website);
    setPlace(initialValues.place);  // Reset place
    setErrors({});
  };

  const validations = {
    name: [],
    startdate: [],
    time: [],
    image: [],
    category: [],
    latitude: [],
    longitude: [],
    address: [],
    type: [],
    foodtype: [],
    numberofpeople: [],
    price: [],
    telephone: [{ type: "Phone" }],
    website: [{ type: "URL" }],
    place: [],  // Add validation for place if needed
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFilesChange = async ({ files, imageSrc }) => {
    try {
      if (!files || !imageSrc) {
        console.log('Files or imageSrc is null or undefined. Exiting handleFilesChange.');
        return;
      }

      console.log('Current Files:', files);
      console.log('Current Image:', imageSrc);

      const fileType = files[0].type.split('/').pop().toLowerCase();
      let convertedUri = imageSrc;
      let customFileName;

      if (fileType !== 'jpg' && fileType !== 'jpeg') {
        const blob = await fetch(imageSrc).then((response) => response.blob());
        const tempFile = new File([blob], `custom_${Date.now()}.jpg`, { type: 'image/jpeg' });
        convertedUri = URL.createObjectURL(tempFile);
        customFileName = tempFile.name;
      }

      const fileName = (fileType === 'jpg' || fileType === 'jpeg') ? files[0].name : customFileName;

      console.log('Converted URI:', convertedUri);
      console.log('File Name:', fileName);

      const s3Key1 = `images/${Date.now()}-${fileName}`;
      const imageUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${s3Key1}`;

      setCoverimage(imageUrl);
      sets3key(s3Key1);
      setfiletype(fileType);
      settempfile(convertedUri);

      console.log('key', s3Key1);
      console.log('type', fileType);
    } catch (error) {
      console.error(error);
    }
  };

  const pathToImageFile = async (tempFile, fileType, s3Key) => {
    try {
      const response = await fetch(tempFile);
      const blob = await response.blob();

      console.log('key', blob);
      console.log('key', s3Key);
      console.log('type', fileType);

      const result = await uploadData({
        key: s3Key,
        data: blob,
        options: {
          contentType: `image/${fileType}`,
        }
      }).result;

      console.log('Succeeded: ', result);
    } catch (error) {
      console.log('Error : ', error);
    }
  };

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleFindClick = async () => {
    try {
      if (!place) {
        console.error('Place is empty');
        return;
      }

      console.log(`Finding place: ${place}`);

      const restOperation = await post({
        apiName: 'test2',
        path: '/items',
        options: {
          body: {
            query: place,
          }
        }
      });

      console.log('call', restOperation);
      const { body } = await restOperation.response;
      const response = await body.json();

      console.log('POST call succeeded:', response);
      if (response && response.places && response.places.length > 0) {
        const placeData = response.places[0];
        setAddress(placeData.formattedAddress);
        setTelephone(placeData.internationalPhoneNumber);
        setWebsite(placeData.websiteUri);
        setLatitude(placeData.location.latitude);
        setLongitude(placeData.location.longitude);
        const foodType = placeData.types[1].replace(/_/g, ' ');
        setFoodtype(foodType);
        setCoverimage(placeData.photoUrl); // Note the updated property name for photo URL
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          startdate,
          time,
          image,
          category,
          latitude,
          longitude,
          address,
          type,
          foodtype,
          numberofpeople,
          price,
          telephone,
          website,
          tripsID: city.id, // Ensure tripsID is included here
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const savedTrip = await DataStore.save(new Food(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }

          await pathToImageFile(tempFile, fileType, s3Key);
          handleSuccess();

          await DataStore.save(
            new Useritins({
              tripsID: city.id,
              userid: userEmail,
              type: 'Food',
              elementid: savedTrip.id
            })
          );

        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "FoodCreateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>

<Flex
        direction="row"
        alignItems="center"
        alignSelf="flex-end"
        marginTop="auto"
      >
        <TextField
          label="Place"
          isRequired={false}
          isReadOnly={false}
          value={place}
          onChange={(e) => {
            const { value } = e.target;
            setPlace(value);
          }}
          {...getOverrideProps(overrides, "place")}
        ></TextField>
        <Flex
          marginLeft={20}
          direction="row"
          alignItems="center"
          alignSelf="flex-end"
          marginTop="auto"
        >
          <Button
            onClick={handleFindClick}
            style={{ backgroundColor: "#2CAFBC", color: "white" }}
            {...getOverrideProps(overrides, "findButton")}
          >
            Find
          </Button>
        </Flex>
      </Flex>

      <TextField
        label="Meal Type"
        isRequired={false}
        isReadOnly={false}
        value={type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type: value,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.type ?? value;
          }
          if (errors.type?.hasError) {
            runValidationTasks("type", value);
          }
          setType(value);
        }}
        onBlur={() => runValidationTasks("type", type)}
        errorMessage={errors.type?.errorMessage}
        hasError={errors.type?.hasError}
        {...getOverrideProps(overrides, "type")}
      ></TextField>

      <TextField
        label="Date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={startdate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate: value,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.startdate ?? value;
          }
          if (errors.startdate?.hasError) {
            runValidationTasks("startdate", value);
          }
          setStartdate(value);
        }}
        onBlur={() => runValidationTasks("startdate", startdate)}
        errorMessage={errors.startdate?.errorMessage}
        hasError={errors.startdate?.hasError}
        {...getOverrideProps(overrides, "startdate")}
      ></TextField>

      <TextField
        label="Time"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={time}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time: value,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.time ?? value;
          }
          if (errors.time?.hasError) {
            runValidationTasks("time", value);
          }
          setTime(value);
        }}
        onBlur={() => runValidationTasks("time", time)}
        errorMessage={errors.time?.errorMessage}
        hasError={errors.time?.hasError}
        {...getOverrideProps(overrides, "time")}
      ></TextField>

      <TextField
        label="Food type"
        isRequired={false}
        isReadOnly={false}
        value={foodtype}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype: value,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.foodtype ?? value;
          }
          if (errors.foodtype?.hasError) {
            runValidationTasks("foodtype", value);
          }
          setFoodtype(value);
        }}
        onBlur={() => runValidationTasks("foodtype", foodtype)}
        errorMessage={errors.foodtype?.errorMessage}
        hasError={errors.foodtype?.hasError}
        {...getOverrideProps(overrides, "foodtype")}
      ></TextField>

      <TextField
        label="Telephone"
        placeholder="+XX-XXX-XXX-XXXX"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={telephone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone: value,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.telephone ?? value;
          }
          if (errors.telephone?.hasError) {
            runValidationTasks("telephone", value);
          }
          setTelephone(value);
        }}
        onBlur={() => runValidationTasks("telephone", telephone)}
        errorMessage={errors.telephone?.errorMessage}
        hasError={errors.telephone?.hasError}
        {...getOverrideProps(overrides, "telephone")}
      ></TextField>

      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address: value,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>

      <TextField
        label="People coming"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={numberofpeople}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople: value,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.numberofpeople ?? value;
          }
          if (errors.numberofpeople?.hasError) {
            runValidationTasks("numberofpeople", value);
          }
          setNumberofpeople(value);
        }}
        onBlur={() => runValidationTasks("numberofpeople", numberofpeople)}
        errorMessage={errors.numberofpeople?.errorMessage}
        hasError={errors.numberofpeople?.hasError}
        {...getOverrideProps(overrides, "numberofpeople")}
      ></TextField>

      <TextField
        label="Price"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={price}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value);
          value = Math.min(value, 4);
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price: value,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.price ?? value;
          }
          if (errors.price?.hasError) {
            runValidationTasks("price", value);
          }
          setPrice(value);
        }}
        onBlur={() => runValidationTasks("price", price)}
        errorMessage={errors.price?.errorMessage}
        hasError={errors.price?.hasError}
        {...getOverrideProps(overrides, "price")}
      ></TextField>

      <TextField
        label="Website"
        isRequired={false}
        isReadOnly={false}
        value={website}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website: value,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.website ?? value;
          }
          if (errors.website?.hasError) {
            runValidationTasks("website", value);
          }
          setWebsite(value);
        }}
        onBlur={() => runValidationTasks("website", website)}
        errorMessage={errors.website?.errorMessage}
        hasError={errors.website?.hasError}
        {...getOverrideProps(overrides, "website")}
      ></TextField>

      <TextField
        label="Image"
        isRequired={false}
        isReadOnly={false}
        value={image}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              startdate,
              time,
              image: value,
              category,
              latitude,
              longitude,
              address,
              type,
              foodtype,
              numberofpeople,
              price,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.image ?? value;
          }
          if (errors.image?.hasError) {
            runValidationTasks("image", value);
          }
          setCoverimage(value);
        }}
        onBlur={() => runValidationTasks("image", image)}
        errorMessage={errors.image?.errorMessage}
        hasError={errors.image?.hasError}
        {...getOverrideProps(overrides, "image")}
      ></TextField>



      <DropZoneInputExample onFilesChange={handleFilesChange} />

      <Flex justifyContent="space-between" {...getOverrideProps(overrides, "CTAFlex")}>
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex gap="15px" {...getOverrideProps(overrides, "RightAlignCTASubFlex")}>
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
