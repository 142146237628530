import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Travel } from "../../../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../../../ui-components/utils";
import { DataStore } from "aws-amplify/datastore";
import { post } from 'aws-amplify/api';

export default function TravelEditForm(props) {
  const {
    idProp,
    travel: travelModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    onClose,
    overrides,
    ...rest
  } = props;
  
  const initialValues = {
    type: "",
    departuredate: "",
    arrivaldate: "",
    departuretime: "",
    arrivaltime: "",
    confirmation: "",
    name: "",
    carrier: "",
    destination: "",
    origin: "",
    category: "",
    latitude: "",
    longitude: "",
    image: "",
    address: "",
    number: "",
    website: "",
    place: "", // Add place to initial values
  };

  const [type, setType] = React.useState(initialValues.type);
  const [departuredate, setDeparturedate] = React.useState(initialValues.departuredate);
  const [arrivaldate, setArrivaldate] = React.useState(initialValues.arrivaldate);
  const [departuretime, setDeparturetime] = React.useState(initialValues.departuretime);
  const [arrivaltime, setArrivaltime] = React.useState(initialValues.arrivaltime);
  const [confirmation, setConfirmation] = React.useState(initialValues.confirmation);
  const [name, setName] = React.useState(initialValues.name);
  const [carrier, setCarrier] = React.useState(initialValues.carrier);
  const [destination, setDestination] = React.useState(initialValues.destination);
  const [origin, setOrigin] = React.useState(initialValues.origin);
  const [category, setCategory] = React.useState(initialValues.category);
  const [latitude, setLatitude] = React.useState(initialValues.latitude);
  const [longitude, setLongitude] = React.useState(initialValues.longitude);
  const [image, setImage] = React.useState(initialValues.image);
  const [address, setAddress] = React.useState(initialValues.address);
  const [number, setNumber] = React.useState(initialValues.number);
  const [website, setWebsite] = React.useState(initialValues.website);
  const [place, setPlace] = React.useState(initialValues.place); // State for place
  const [errors, setErrors] = React.useState({});
  const [travelRecord, setTravelRecord] = React.useState(travelModelProp);

  const resetStateValues = () => {
    const cleanValues = travelRecord ? { ...initialValues, ...travelRecord } : initialValues;
    setType(cleanValues.type);
    setDeparturedate(cleanValues.departuredate);
    setArrivaldate(cleanValues.arrivaldate);
    setDeparturetime(cleanValues.departuretime);
    setArrivaltime(cleanValues.arrivaltime);
    setConfirmation(cleanValues.confirmation);
    setName(cleanValues.name);
    setCarrier(cleanValues.carrier);
    setDestination(cleanValues.destination);
    setOrigin(cleanValues.origin);
    setCategory(cleanValues.category);
    setLatitude(cleanValues.latitude);
    setLongitude(cleanValues.longitude);
    setImage(cleanValues.image);
    setAddress(cleanValues.address);
    setNumber(cleanValues.number);
    setWebsite(cleanValues.website);
    setPlace(cleanValues.place); // Reset place
    setErrors({});
  };

  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp ? await DataStore.query(Travel, idProp) : travelModelProp;
      setTravelRecord(record);
    };
    queryData();
  }, [idProp, travelModelProp]);

  React.useEffect(resetStateValues, [travelRecord]);

  const validations = {
    type: [],
    departuredate: [],
    arrivaldate: [],
    departuretime: [],
    arrivaltime: [],
    confirmation: [],
    name: [],
    carrier: [],
    destination: [],
    origin: [],
    category: [],
    latitude: [],
    longitude: [],
    image: [],
    address: [],
    number: [],
    website: [{ type: "URL" }],
    place: [], // Add validation for place if needed
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFindClick = async () => {
    try {
      if (!place) {
        console.error('Place is empty');
        return;
      }
      console.log(`Finding place: ${place}`);
      
      const restOperation = await post({
        apiName: 'test2',
        path: '/items',
        options: {
          body: {
            query: place,
          }
        }
      });

      console.log('call', restOperation);
      const { body } = await restOperation.response;
      const response = await body.json();
      
      console.log('POST call succeeded:', response);
      if (response && response.places && response.places.length > 0) {
        const placeData = response.places[0];
        setAddress(placeData.formattedAddress);
        setNumber(placeData.internationalPhoneNumber);
        setWebsite(placeData.websiteUri);
        setLatitude(placeData.location.latitude);
        setLongitude(placeData.location.longitude);
        setImage(placeData.photoUrl);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          type,
          departuredate,
          arrivaldate,
          departuretime,
          arrivaltime,
          confirmation,
          name,
          carrier,
          destination,
          origin,
          category,
          latitude,
          longitude,
          image,
          address,
          number,
          website,
          place, // Include place in model fields
        };
        
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(runValidationTasks(fieldName, modelFields[fieldName]));
            return promises;
          }, [])
        );
        
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Travel.copyOf(travelRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
          onClose(); 
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "TravelUpdateForm")}
      {...rest}
    >
      <TextField
        label="Type"
        isRequired={false}
        isReadOnly={false}
        value={type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type: value,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.type ?? value;
          }
          if (errors.type?.hasError) {
            runValidationTasks("type", value);
          }
          setType(value);
        }}
        onBlur={() => runValidationTasks("type", type)}
        errorMessage={errors.type?.errorMessage}
        hasError={errors.type?.hasError}
        {...getOverrideProps(overrides, "type")}
      ></TextField>

      <TextField
        label="Place"
        isRequired={false}
        isReadOnly={false}
        value={place}
        onChange={(e) => {
          let { value } = e.target;
          setPlace(value);
        }}
        {...getOverrideProps(overrides, "place")}
      ></TextField>
      <Button onClick={handleFindClick} style={{ backgroundColor: "#2CAFBC", color: "white" }}>
        Find
      </Button>

      <TextField
        label="Departuredate"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={departuredate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate: value,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.departuredate ?? value;
          }
          if (errors.departuredate?.hasError) {
            runValidationTasks("departuredate", value);
          }
          setDeparturedate(value);
        }}
        onBlur={() => runValidationTasks("departuredate", departuredate)}
        errorMessage={errors.departuredate?.errorMessage}
        hasError={errors.departuredate?.hasError}
        {...getOverrideProps(overrides, "departuredate")}
      ></TextField>

      <TextField
        label="Arrivaldate"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={arrivaldate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate: value,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.arrivaldate ?? value;
          }
          if (errors.arrivaldate?.hasError) {
            runValidationTasks("arrivaldate", value);
          }
          setArrivaldate(value);
        }}
        onBlur={() => runValidationTasks("arrivaldate", arrivaldate)}
        errorMessage={errors.arrivaldate?.errorMessage}
        hasError={errors.arrivaldate?.hasError}
        {...getOverrideProps(overrides, "arrivaldate")}
      ></TextField>

      <TextField
        label="Departuretime"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={departuretime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime: value,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.departuretime ?? value;
          }
          if (errors.departuretime?.hasError) {
            runValidationTasks("departuretime", value);
          }
          setDeparturetime(value);
        }}
        onBlur={() => runValidationTasks("departuretime", departuretime)}
        errorMessage={errors.departuretime?.errorMessage}
        hasError={errors.departuretime?.hasError}
        {...getOverrideProps(overrides, "departuretime")}
      ></TextField>

      <TextField
        label="Arrivaltime"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={arrivaltime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime: value,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.arrivaltime ?? value;
          }
          if (errors.arrivaltime?.hasError) {
            runValidationTasks("arrivaltime", value);
          }
          setArrivaltime(value);
        }}
        onBlur={() => runValidationTasks("arrivaltime", arrivaltime)}
        errorMessage={errors.arrivaltime?.errorMessage}
        hasError={errors.arrivaltime?.hasError}
        {...getOverrideProps(overrides, "arrivaltime")}
      ></TextField>

      <TextField
        label="Confirmation"
        isRequired={false}
        isReadOnly={false}
        value={confirmation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation: value,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.confirmation ?? value;
          }
          if (errors.confirmation?.hasError) {
            runValidationTasks("confirmation", value);
          }
          setConfirmation(value);
        }}
        onBlur={() => runValidationTasks("confirmation", confirmation)}
        errorMessage={errors.confirmation?.errorMessage}
        hasError={errors.confirmation?.hasError}
        {...getOverrideProps(overrides, "confirmation")}
      ></TextField>

      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name: value,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>

      <TextField
        label="Carrier"
        isRequired={false}
        isReadOnly={false}
        value={carrier}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier: value,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.carrier ?? value;
          }
          if (errors.carrier?.hasError) {
            runValidationTasks("carrier", value);
          }
          setCarrier(value);
        }}
        onBlur={() => runValidationTasks("carrier", carrier)}
        errorMessage={errors.carrier?.errorMessage}
        hasError={errors.carrier?.hasError}
        {...getOverrideProps(overrides, "carrier")}
      ></TextField>

      <TextField
        label="Destination"
        isRequired={false}
        isReadOnly={false}
        value={destination}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination: value,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.destination ?? value;
          }
          if (errors.destination?.hasError) {
            runValidationTasks("destination", value);
          }
          setDestination(value);
        }}
        onBlur={() => runValidationTasks("destination", destination)}
        errorMessage={errors.destination?.errorMessage}
        hasError={errors.destination?.hasError}
        {...getOverrideProps(overrides, "destination")}
      ></TextField>

      <TextField
        label="Origin"
        isRequired={false}
        isReadOnly={false}
        value={origin}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin: value,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.origin ?? value;
          }
          if (errors.origin?.hasError) {
            runValidationTasks("origin", value);
          }
          setOrigin(value);
        }}
        onBlur={() => runValidationTasks("origin", origin)}
        errorMessage={errors.origin?.errorMessage}
        hasError={errors.origin?.hasError}
        {...getOverrideProps(overrides, "origin")}
      ></TextField>

      <TextField
        label="Category"
        isRequired={false}
        isReadOnly={false}
        value={category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category: value,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          if (errors.category?.hasError) {
            runValidationTasks("category", value);
          }
          setCategory(value);
        }}
        onBlur={() => runValidationTasks("category", category)}
        errorMessage={errors.category?.errorMessage}
        hasError={errors.category?.hasError}
        {...getOverrideProps(overrides, "category")}
      ></TextField>

      <TextField
        label="Latitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={latitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude: value,
              longitude,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.latitude ?? value;
          }
          if (errors.latitude?.hasError) {
            runValidationTasks("latitude", value);
          }
          setLatitude(value);
        }}
        onBlur={() => runValidationTasks("latitude", latitude)}
        errorMessage={errors.latitude?.errorMessage}
        hasError={errors.latitude?.hasError}
        {...getOverrideProps(overrides, "latitude")}
      ></TextField>

      <TextField
        label="Longitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={longitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude: value,
              image,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.longitude ?? value;
          }
          if (errors.longitude?.hasError) {
            runValidationTasks("longitude", value);
          }
          setLongitude(value);
        }}
        onBlur={() => runValidationTasks("longitude", longitude)}
        errorMessage={errors.longitude?.errorMessage}
        hasError={errors.longitude?.hasError}
        {...getOverrideProps(overrides, "longitude")}
      ></TextField>

      <TextField
        label="Image"
        isRequired={false}
        isReadOnly={false}
        value={image}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image: value,
              address,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.image ?? value;
          }
          if (errors.image?.hasError) {
            runValidationTasks("image", value);
          }
          setImage(value);
        }}
        onBlur={() => runValidationTasks("image", image)}
        errorMessage={errors.image?.errorMessage}
        hasError={errors.image?.hasError}
        {...getOverrideProps(overrides, "image")}
      ></TextField>

      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address: value,
              number,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>

      <TextField
        label="Number"
        isRequired={false}
        isReadOnly={false}
        value={number}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number: value,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.number ?? value;
          }
          if (errors.number?.hasError) {
            runValidationTasks("number", value);
          }
          setNumber(value);
        }}
        onBlur={() => runValidationTasks("number", number)}
        errorMessage={errors.number?.errorMessage}
        hasError={errors.number?.hasError}
        {...getOverrideProps(overrides, "number")}
      ></TextField>

      <TextField
        label="Website"
        isRequired={false}
        isReadOnly={false}
        value={website}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website: value,
              place,
            };
            const result = onChange(modelFields);
            value = result?.website ?? value;
          }
          if (errors.website?.hasError) {
            runValidationTasks("website", value);
          }
          setWebsite(value);
        }}
        onBlur={() => runValidationTasks("website", website)}
        errorMessage={errors.website?.errorMessage}
        hasError={errors.website?.hasError}
        {...getOverrideProps(overrides, "website")}
      ></TextField>

      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || travelModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || travelModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
