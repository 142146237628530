import React from 'react';
import AccomodationEditForm from './editcomponents/acomoedit';
import FoodEditForm from './editcomponents/mealedit';
import TravelEditForm from './editcomponents/traveledit';
import ActivityEditForm from './editcomponents/acitityedit';

const EditItineary = ({ isOpen, onClose, category, onEditSuccess, children }) => {
  const handleEdit = async () => {
     onEditSuccess(); 
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-40 w-full h-full flex justify-center items-center z-50 overflow-y-auto">
          <div className="absolute bg-white rounded w-60 h-70 overflow-y-auto shadow-lg">
            <div className="flex justify-between items-center px-4 py-2 border-b">
              <h2 className="text-lg font-semibold">{category.category} Details</h2>
              <button onClick={onClose}>Close</button>
            </div>
            {category.category === 'Accomodation' && (
              <AccomodationEditForm idProp={category.id} onClose={onClose} />
            )}
            {category.category === 'Food' && (
              <FoodEditForm idProp={category.id} onClose={onClose} />
            )}
            {category.category === 'Travel' && (
              <TravelEditForm idProp={category.id} onClose={onClose} />
            )}
            {category.category === 'Activity' && (
              <ActivityEditForm idProp={category.id} onClose={onClose} />
            )}
            <div className="p-4">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditItineary;
