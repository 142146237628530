    import React, { useState, useEffect } from 'react';
    import { DataStore } from "@aws-amplify/datastore";
    import { Usertrips } from "../../../models";
    import CountryFilter from '../../../pages/Newtrip/countrycard';
    
    

    const UserTripsManagement = ({ onClose, userEmail, city }) => {
        const [trips, setTrips] = useState([]);
        const [showModal, setShowModal] = useState(false);
        const [selectedTrip, setSelectedTrip] = useState(null);
        const [newCity, setNewCity] = useState('');
        const [newCountry, setNewCountry] = useState('');
        const [selectedCountries, setSelectedCountries] = useState([]);
        const [selectednewCountry, setnewCountry] = useState([]);
        const [nameparams, setnameparams] = useState([]);
        const [photoid, setphotoid] = useState([]);
        const [tripCity, setCity] = useState('');


       
        

          const fetchData = async () => {
            try {
                const userTrips = await DataStore.query(Usertrips, (c) => c.and( c => [
                    c.tripsID.eq(city.id),
                    c.user_email.eq(userEmail)
                ]));
        
                // Extract unique values for photoid and name
                const uniquePhotoIds = Array.from(new Set(userTrips.map(trip => trip.photoid)));
                const uniqueNames = Array.from(new Set(userTrips.map(trip => trip.name)));
        
                setTrips(userTrips);
                setphotoid(uniquePhotoIds);
                setnameparams(uniqueNames);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        useEffect(() => {
            fetchData();
        }, [userEmail]);


        const handleAddPlace = async () => {
            try {
                let selectedNewCountryData = {}; // Initialize an empty object for selected new country data
                
                // Check if selectedNewCountry has at least one element
                if (selectednewCountry.length > 0) {
                    selectedNewCountryData = selectednewCountry[0]; // Assign the first element to selectedNewCountryData
                }
                
               
                
                const newTrip = await DataStore.save(
                    new Usertrips({
                        city: tripCity,
                        user_email: userEmail,
                        name: nameparams.toString(),
                        tripsID: city.id,
                        photoid: photoid.toString(),
                        countryName: selectedNewCountryData.countryName || '',
                        countryCallingCode: selectedNewCountryData.countryCallingCode || '', 
                        currencyCode: selectedNewCountryData.currencyCode || '',
                        currencyName: selectedNewCountryData.currencyName || '',
                        officialLanguage: selectedNewCountryData.officialLanguage || '',
                        countryCode: selectedNewCountryData.countryCode || '',
                    })
                );
        
                // Log the newly created trip
                console.log('New trip:', newTrip);
        
                // Close the modal
                setShowModal(false);
        
                // Rerun fetchData to update the list of trips
                fetchData();
            } catch (error) {
                console.error('Error creating new trip:', error);
            }
        };
        
    const handleDeleteTrip = async (tripId) => {
        try {
            console.log(tripId)
       
        await DataStore.delete(Usertrips, tripId);
        fetchData();
        } catch (error) {
        console.error('Error deleting trip:', error);
        }
    };

    const handleEditTrip = (trip) => {
        setSelectedTrip(trip);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTrip(null);
        setNewCity('');
        setNewCountry('');
    };
   
    const handleSaveChanges = async () => {
        try {
          const updatedUserTrip = Usertrips.copyOf(selectedTrip, updated => {
            updated.city = newCity; // Update city name
            if (selectedCountries.length > 0) {
              const selectedCountry = selectedCountries[0];
              updated.countryName = selectedCountry.countryName;
              updated.countryCallingCode = selectedCountry.countryCallingCode;
              updated.currencyCode = selectedCountry.currencyCode;
              updated.currencyName = selectedCountry.currencyName;
              updated.officialLanguage = selectedCountry.officialLanguage;
              updated.countryCode = selectedCountry.countryCode;
            }
          });
      
          await DataStore.save(updatedUserTrip);
          setShowModal(false);
          
          // Fetch data again after saving changes
          fetchData();
        } catch (error) {
          console.error('Error saving changes:', error);
        }
      };

    const handleCountrySelect = (country) => {
        setSelectedCountries([country]); // Update selectedCountries state with the selected country
        
    };

    const handleNewCountrySelect = (country) => {
        setnewCountry([country]);
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white w-40 p-4 rounded-lg shadow-lg">
        <div className='flex flex-row items-center justify-between mb-5'>
        <h2 className="font-bold text-xl">Cities in this trip</h2>  
    <div>
        <button
        onClick={onClose}
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
    </div>
    </div>
            <div className="modal-body">
            {trips.map((trip) => (
            <div key={trip.id} className="flex items-center justify-between mb-5">
            <p className="flex-1">{trip.city}</p>
            <div className="flex">
            <button
                onClick={() => handleEditTrip(trip)}
                className="bg-blue-500 rounded-md px-3 py-1 text-white mr-2"
            >
                Edit
            </button>
            <button
                onClick={() => handleDeleteTrip(trip.id)}
                className="bg-red-500 rounded-md px-3 py-1 text-white"
            >
                Delete
            </button>
            </div>
        </div>
            ))}
            <div >
                <div>
                <label htmlFor="newPlace">Add new City</label>
                <div className='flex flex-row items-center justify-between mb-5'>
                <input
                    type="text"
                    id="newPlace"
                    placeholder="Enter city name"
                    className="mt-5 border border-gray-300 rounded-md px-3 py-2"
                    onChange={(e) => setCity(e.target.value)} // Access e.target.value to get the entered city name
                />

                <CountryFilter
                    selectedCountry={selectednewCountry}
                    onCountrySelect={handleNewCountrySelect} // Pass the handleCountrySelect function
                    />
                
                </div>
                <button onClick={handleAddPlace} className="bg-blue_2 text-white px-4 py-2 rounded-md">Add</button>
                </div>
            </div>
            </div>
        </div>
        {showModal && selectedTrip && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-40 p-4 rounded-lg shadow-lg ml-10">
            
            <div className='flex flex-row items-center justify-between mb-5'>
            <h2 className="font-bold text-xl mb-5">Select New City</h2>  
    <div>
        <button
        onClick={handleCloseModal}
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
    </div>
    </div>
                <div className='flex flex-row items-center justify-between mb-5'>
        <label htmlFor="currentCity">Current City:</label>
        <p id="currentCity" >{selectedTrip.city}</p>
        </div>
        <div className='flex flex-row items-center justify-between mb-5'>
        <label htmlFor="currentCountry">Current Country:</label>
        <p id="currentCountry" >{selectedTrip.countryName}</p>
        </div>
        <div className='flex flex-row items-center justify-between mb-5'>
                <label htmlFor="newCity">New City:</label>
                <input
                    type="text"
                    id="newCity"
                    value={newCity}
                    onChange={(e) => setNewCity(e.target.value)}
                    className="border rounded-lg p-2"
                />
                </div>
                <div className='flex flex-row items-center justify-between mb-5'>
                <label htmlFor="newCountry">New Country:</label>
                <CountryFilter
                    selectedCountry={selectedCountries}
                    onCountrySelect={handleCountrySelect} // Pass the handleCountrySelect function
                    />
                
                </div>
                <div className="flex justify-center space-x-10">
                    <button onClick={handleSaveChanges} className="bg-blue_2 text-white px-4 py-2 rounded-md">
                        Save Changes
                    </button>
                
                    </div>
            </div>
            </div>
        )}
        </div>
    );
    };

    export default UserTripsManagement;