import * as React from 'react';
import {
  Button,
  DropZone,
  Flex,
  Text,
  VisuallyHidden,
} from '@aws-amplify/ui-react';

const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/heif'];

export default function DropZoneInputExample({ onFilesChange }) {
  const [files, setFiles] = React.useState([]);
  const [imageSrc, setImageSrc] = React.useState(null);
  const hiddenInput = React.useRef(null);

  const onFilePickerChange = (event) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }

     const reader = new FileReader();
  reader.onload = async () => {
    setImageSrc(reader.result);

    // Check if onFilesChange is set correctly
    if (typeof onFilesChange === 'function') {
      console.log('onFilesChange is set correctly');
      await onFilesChange({ files, imageSrc: reader.result });
    } else {
      console.error('onFilesChange is not set correctly');
    }
  };

  reader.readAsDataURL(files[0]);
  setFiles(Array.from(files));
};

  return (
    <DropZone
      acceptedFileTypes={acceptedFileTypes}
      onDropComplete={({ acceptedFiles, rejectedFiles }) => {
       setFiles(acceptedFiles);
        onFilesChange({ files: acceptedFiles, imageSrc: imageSrc });
      }}
    >
      <Flex direction="column" alignItems="center">
        <Text>Select image</Text>
        {imageSrc && (
        <img
          src={imageSrc}
          alt="Selected Image"
          style={{ width: '180px', height: '180px', marginTop: '10px' }}
        />
      )}
      {files.map((file) => (
        <Text key={file.name}>{file.name}</Text>
      ))}
        <Button size="small" onClick={() => hiddenInput.current.click()}>
          Browse
        </Button>
      </Flex>
      <VisuallyHidden>
        <input
          type="file"
          tabIndex={-1}
          ref={hiddenInput}
          onChange={onFilePickerChange}
          multiple={false}  
          accept={acceptedFileTypes.join(',')}
        />
      </VisuallyHidden>
      
    </DropZone>
  );
}
