import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Dashboard/DashbordLayout/DashboardLayout";
import CountryFilter from "./countrycard";
import { useLocation, useNavigate } from "react-router-dom";
import { DataStore } from "aws-amplify/datastore";
import { Usertrips, Useritins } from "../../models";
import { fetchUserAttributes } from 'aws-amplify/auth';

const Newtripcityscreen = ({}) => {    
     const [accountinfo, setUserName] = useState({ givenName: "", familyName: "", email: ""});
     const [countryCount, setCountryCount] = useState(1);
     const [nameparams, setnameparams] = useState([])
     const [countries, setCountries] = useState(['']);
     const [citiesCount, setCitiesCount] = useState([]);
     const [cities, setCities] = useState([]);
     const [selectedCountries, setSelectedCountries] = useState([]);
      const [tripId, settripId] = useState([]);
      const [tripname, setTripname] = useState([]);
     const location = useLocation();
    const navigate = useNavigate();


 

 useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await fetchUserAttributes();
      const { given_name, family_name, email } = userInfo;

      const formattedGivenName = given_name.charAt(0).toUpperCase() + given_name.slice(1);
      const formattedFamilyName = family_name.charAt(0).toUpperCase() + family_name.slice(1);
      
      const name = `${formattedGivenName} ${formattedFamilyName}`;

     const nameString = typeof name === 'string' ? name : '';
     const extractedTripId = typeof location.state.tripId === 'string' ? location.state.tripId : '';
  const extractedTripname = typeof location.state.name === 'string' ? location.state.name : '';

    settripId(extractedTripId);
    setTripname(extractedTripname);
 

      setnameparams(nameString);


      setUserName({
        givenName: formattedGivenName,
        familyName: formattedFamilyName,
        email,
      });
    };

    fetchUserInfo();
  }, []);

 const handleCountryCountChange = (count) => {
    setCountryCount(count);
    const newCitiesCount = Array(count).fill(1);
    setCitiesCount(newCitiesCount);
    const newCities = Array(count).fill(['']);
    setCities(newCities);
  };

  const handleCityCountChange = (count, countryIndex) => {
    const updatedCitiesCount = [...citiesCount];
    updatedCitiesCount[countryIndex] = count;
    setCitiesCount(updatedCitiesCount);

    const newCities = Array(count).fill('');
    const updatedCities = [...cities];
    updatedCities[countryIndex] = newCities;
    setCities(updatedCities);
  };

const handleSubmit = async () => {
    // Iterate through selectedCountries and cities arrays
  for (let countryIndex = 0; countryIndex < selectedCountries.length; countryIndex++) {
    const selectedCountry = selectedCountries[countryIndex];
    const countryCities = cities[countryIndex];

    // Iterate through cities within the country
    for (let cityIndex = 0; cityIndex < countryCities.length; cityIndex++) {
      const city = countryCities[cityIndex];

      try {
        // Create a new Usertrip record
        const newTrip = await DataStore.save(
          new Usertrips({
            countryName: selectedCountry.countryName,
            countryCallingCode: selectedCountry.countryCallingCode,
            currencyCode: selectedCountry.currencyCode,
            currencyName: selectedCountry.currencyName,
            officialLanguage: selectedCountry.officialLanguage,
            countryCode: selectedCountry.countryCode,
            city,
            user_email: accountinfo.email,
            tripsID: tripId,
            name: nameparams,
          })
        );

        
    

   const newItin2 = await DataStore.save(
        new Useritins({
          tripsID: tripId,
           userid: accountinfo.email,
           
          type: 'Travel',
        elementid: '69c8c9c5-80be-4c85-be87-f06d6d752bf3' // Store the image URLs in your trip model
        })
      );

      const newItin1 = await DataStore.save(
        new Useritins({
            tripsID: tripId,
           userid: accountinfo.email,
          type: 'Accomodation',
        elementid: 'b34a09e4-9046-427f-948d-3f3b453b98e5' // Store the image URLs in your trip model
        })
      );


        console.log('Usertrip record created successfully', newTrip);
      } catch (error) {
        console.error('Error creating Usertrip record', error);
      }
    }
  }
   navigate("/done");
};

  
      
  return (
  <DashboardLayout className="">
  <div className="pl-10 w-6/6">
      <div className="mt-6">
        <h2 className="text-2xl font-bold">Nice!</h2>
      </div>
      <div className="mt-4 text-left">
        <p className="text-base">{tripname} was successfully created</p>
        <p className="text-base">Let's start with some basic info for your trip</p>
      </div>

      <div className="my-6">
       <div className="flex">
          <div className="bg-white p-4 text-black">
            <p className="font-bold">
              How many countries are you going to visit?
            </p>
          </div>
          <div className="bg-white rounded-full w-20 p-4 ml-4">
            <input
              type="number"
              placeholder="1"
              className="ml-40 text-lg border w-32 p-2 border-zinc-400 text-center"
              onChange={(e) => {
                const count = parseInt(e.target.value, 10) || 1;
                handleCountryCountChange(count);
              }}
            />
          </div>
        </div>

        {Array.from({ length: countryCount }).map((_, countryIndex) => (
          <div key={countryIndex} className="mt-5 pl-4 w-1000">
          <div className="bg-black text-white py-2 px-4  rounded-md border border-zinc-400 mt-4 w-4/5">
            <p className="font-bold">Country {countryIndex + 1}</p>
          </div>
            <CountryFilter
              selectedCountry={selectedCountries} // Pass selected countries as prop
              onCountrySelect={(selected) => {
                const updatedSelectedCountries = [...selectedCountries];
                updatedSelectedCountries[countryIndex] = selected;
                setSelectedCountries(updatedSelectedCountries);
              }}
            />

            <div className="flex mt-4 ">
              <div className="bg-white h-10 mt-5 text-black">
                <p className="font-bold">
                  How many cities are you going to visit in this country?
                </p>
              </div>
              <div className="bg-white rounded-full  h-10 mt-4 pl-24">
                <input
                  type="number"
                  placeholder="1"
                   className=" text-lg border w-32 h-10 border-zinc-400 text-center"
                  onChange={(e) => {
                    const count = parseInt(e.target.value, 10) || 1;
                    handleCityCountChange(count, countryIndex);
                  }}
                />
              </div>
            </div>

            {Array.from({ length: citiesCount[countryIndex] }).map(
              (_, cityIndex) => (
                <div key={cityIndex} className="mt-4 ml-4">
                  <p className="mt-4 mb-4">
                    City {cityIndex + 1} in Country {countryIndex + 1}
                  </p>
                  <input
                    type="text"
                    placeholder={`City ${cityIndex + 1}`}
                    value={cities[countryIndex][cityIndex]}
                    onChange={(e) => {
                      const updatedCities = [...cities];
                      updatedCities[countryIndex][cityIndex] = e.target.value;
                      setCities(updatedCities);
                    }}
                    className="border p-2 rounded"
                  />
                </div>
              )
            )}
          </div>
        ))}
        <button
          onClick={handleSubmit}
          className="bg-zinc-100 text-black py-2 px-4 rounded border border-zinc-400 mt-4"
        >
          Upload Itinerary
        </button>
      </div>
    </div>
</DashboardLayout>
  );
};

export default Newtripcityscreen;
