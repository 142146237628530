import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Dashboard/DashbordLayout/DashboardLayout";
import { fetchUserAttributes } from 'aws-amplify/auth';
import imageIcon from "../../assets/image.svg"
import { DataStore } from "@aws-amplify/datastore";
import { Userinfo } from "../../models";


const UserProfileScreen = () => {
      const [accountinfo, setUserName] = useState({
    givenName: "",
    familyName: "",
    email: "",
    phoneNumber: "",
    phoneNumberVerified: false,
    emailVerified: false,
  });


    const [userPhoto, setUserPhoto] = useState(null);
 
 useEffect(() => {
  const fetchUserData = async () => {
    try {
      const userInfo = await fetchUserAttributes();
      const { given_name, family_name, email, phone_number, phone_number_verified, email_verified } = userInfo;
      
      const formattedGivenName = given_name.charAt(0).toUpperCase() + given_name.slice(1);
      const formattedFamilyName = family_name.charAt(0).toUpperCase() + family_name.slice(1);
      
     
      setUserName({
        givenName: formattedGivenName,
        familyName: formattedFamilyName,
        email,
        phoneNumber: phone_number,
        phoneNumberVerified: phone_number_verified,
        emailVerified: email_verified
      });

       const usertrips = await DataStore.query(Userinfo, (c) =>
              c.email.eq(userInfo.email)
            );
            if (usertrips.length > 0) {
        // Assuming you want to set the user's photo to the first matching usertrips object
        const { photo } = usertrips[0];
        setUserPhoto(photo);
        } else {
        // Handle the case where no matching usertrips object is found
        console.error('User not found');
        }
            
            } catch (error) {
            console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
        }, []);



  return (
    <DashboardLayout className="">
     <div className="container mx-auto mt-10 pl-10 pr-10 flex items-center">
       <div className="pl-5 pr-15 w-100">
          <div className="w-38 h-38 bg-zinc-100 rounded-full border border-zinc-400">
          {userPhoto ? (
            <img
              src={userPhoto}
              alt="User Photo"
              className="w-full h-full object-cover rounded-full"
            />
          ) : (
            <div className=" flex items-center justify-center w-full h-full">
              <img
                src= {imageIcon}  
                alt="Upload Image"
                className="w-14 h-14" 
          />
            </div>
          )}
        </div>
        <button
          //onClick={handlePhotoUpload}
          className="mt-2 pl-8 text-blue-500 text-sm cursor-pointer"
        >
          Upload Photo
        </button>
      </div>



      <div className="container mx-auto mt-10 pl-10 pr-10">
      <div className="grid grid-cols-2 gap-6">
        {/* Box 1 - First Name */}
        <div className="bg-white p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">First Name</h3>
          {/* You can replace the input element with your preferred form field */}
          <input
            type="text"
            className="border border-zinc-400 w-full p-2 rounded-md"
            placeholder="Enter your first name"
            value={accountinfo.givenName}
          />
        </div>

        {/* Box 2 - Last Name */}
        <div className="bg-white p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Last Name</h3>
          {/* You can replace the input element with your preferred form field */}
          <input
            type="text"
            className="border border-zinc-400 w-full p-2 rounded-md"
            placeholder="Enter your last name"
            value={accountinfo.familyName}
          />
        </div>

        {/* Box 3 - Email */}
        <div className="bg-white p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Email</h3>
          {/* You can replace the input element with your preferred form field */}
          <input
            type="email"
            className="border border-zinc-400 w-full p-2 rounded-md"
            placeholder="Enter your email address"
            value={accountinfo.email}
          />
        </div>

        {/* Box 4 - Phone */}
        <div className="bg-white p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Phone</h3>
          {/* You can replace the input element with your preferred form field */}
          <input
            type="tel"
            className="border border-zinc-400 w-full p-2 rounded-md"
            placeholder="Enter your phone number"
            value={accountinfo.phoneNumber}
          />
        </div>
      </div>
    </div>
</div>
    </DashboardLayout>
  );
};

export default UserProfileScreen;
