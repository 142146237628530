import React, { useState, useEffect } from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Trips } from "../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../ui-components/utils";
import { useNavigate } from "react-router-dom";
import { DataStore } from "aws-amplify/datastore";
import awsmobile from "../../aws-exports";
import DropZoneInputExample from "./imagepicker";
import { uploadData } from 'aws-amplify/storage';
import { deleteTemporaryFile } from "../../components/Dashboard/Itinerary/deletetemp";

const PLACEHOLDER_IMAGE_URL = "https://andanza-storage-f0ae67d0194523-prod.s3.amazonaws.com/public/tripimages/Placeholder.webp";

export default function TripsCreateFormmod(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;

  const initialValues = {
    tripname: "",
    datestart: "",
    dateend: "",
    coverimage: "",
    headerimage: "",
    location: "",
    subheader1: "",
    subheader2: "",
  };

  const [tripname, setTripname] = useState(initialValues.tripname);
  const [datestart, setDatestart] = useState(initialValues.datestart);
  const [dateend, setDateend] = useState(initialValues.dateend);
  const [coverimage, setCoverimage] = useState(initialValues.coverimage);
  const [headerimage, setHeaderimage] = useState(initialValues.headerimage);
  const [location, setLocation] = useState(initialValues.location);
  const [s3Key, sets3key] = useState(null);
  const [fileType, setfiletype] = useState(null);
  const [tempFile, settempfile] = useState(null);
  const [subheader1, setSubheader1] = useState(initialValues.subheader1);
  const [subheader2, setSubheader2] = useState(initialValues.subheader2);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  
  const resetStateValues = () => {
    setTripname(initialValues.tripname);
    setDatestart(initialValues.datestart);
    setDateend(initialValues.dateend);
    setCoverimage(initialValues.coverimage);
    setHeaderimage(initialValues.headerimage);
    setLocation(initialValues.location);
    setSubheader1(initialValues.subheader1);
    setSubheader2(initialValues.subheader2);
    setErrors({});
  };

  const validations = {
    tripname: [],
    datestart: [],
    dateend: [],
    coverimage: [],
    headerimage: [{ type: "URL" }],
    location: [],
    subheader1: [],
    subheader2: [],
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFilesChange = async ({ files, imageSrc }) => {
    try {
      if (!files || !imageSrc) {
        console.log('Files or imageSrc is null or undefined. Exiting handleFilesChange.');
        return;
      }

      console.log('Current Files:', files);
      console.log('Current Image:', imageSrc);

      const fileType = files[0].type.split('/').pop().toLowerCase();
      let convertedUri = imageSrc;
      let customFileName;

      if (fileType !== 'jpg' && fileType !== 'jpeg') {
        const blob = await fetch(imageSrc).then((response) => response.blob());
        const tempFile = new File([blob], `custom_${Date.now()}.jpg`, { type: 'image/jpeg' });
        convertedUri = URL.createObjectURL(tempFile);
        customFileName = tempFile.name;
      }

      const fileName = (fileType === 'jpg' || fileType === 'jpeg') ? files[0].name : customFileName;

      console.log('Converted URI:', convertedUri);
      console.log('File Name:', fileName);

      const s3Key1 = `images/${Date.now()}-${fileName}`;
      const imageUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${s3Key1}`;

      setCoverimage(imageUrl);
      sets3key(s3Key1);
      setfiletype(fileType);
      settempfile(convertedUri);

      if (fileType !== 'jpg' && fileType !== 'jpeg') {
        await deleteTemporaryFile(convertedUri);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const pathToImageFile = async (tempFile, fileType, s3Key) => {
    try {
      const response = await fetch(tempFile);
      const blob = await response.blob();

      const result = await uploadData({
        key: s3Key,
        data: blob,
        options: {
          contentType: `image/${fileType}`,
        }
      }).result;

      console.log('Succeeded: ', result);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          tripname,
          datestart,
          dateend,
          coverimage,
          headerimage,
          location,
          subheader1,
          subheader2,
        };

        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) => runValidationTasks(fieldName, item))
              );
              return promises;
            }
            promises.push(runValidationTasks(fieldName, modelFields[fieldName]));
            return promises;
          }, [])
        );

        if (validationResponses.some((r) => r.hasError)) {
          return;
        }

        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }

        try {
          if (!coverimage) {
            modelFields.coverimage = PLACEHOLDER_IMAGE_URL;
          }

          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });

          const savedTrip = await DataStore.save(new Trips(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
          await pathToImageFile(tempFile, fileType, s3Key);
          navigate("/newtripcity", { state: { tripId: savedTrip.id, name: savedTrip.tripname } });
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "TripsCreateForm")}
      {...rest}
    >
      <TextField
        label="Trip Name"
        isRequired={false}
        isReadOnly={false}
        value={tripname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tripname: value,
              datestart,
              dateend,
              coverimage,
              headerimage,
              location,
              subheader1,
              subheader2,
            };
            const result = onChange(modelFields);
            value = result?.tripname ?? value;
          }
          if (errors.tripname?.hasError) {
            runValidationTasks("tripname", value);
          }
          setTripname(value);
        }}
        onBlur={() => runValidationTasks("tripname", tripname)}
        errorMessage={errors.tripname?.errorMessage}
        hasError={errors.tripname?.hasError}
        {...getOverrideProps(overrides, "tripname")}
      ></TextField>

      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={subheader1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tripname,
              datestart,
              dateend,
              coverimage,
              headerimage,
              location,
              subheader1: value,
              subheader2,
            };
            const result = onChange(modelFields);
            value = result?.subheader1 ?? value;
          }
          if (errors.subheader1?.hasError) {
            runValidationTasks("subheader1", value);
          }
          setSubheader1(value);
        }}
        onBlur={() => runValidationTasks("subheader1", subheader1)}
        errorMessage={errors.subheader1?.errorMessage}
        hasError={errors.subheader1?.hasError}
        {...getOverrideProps(overrides, "subheader1")}
      ></TextField>

      <TextField
        label="Location"
        isRequired={false}
        isReadOnly={false}
        value={location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tripname,
              datestart,
              dateend,
              coverimage,
              headerimage,
              location: value,
              subheader1,
              subheader2,
            };
            const result = onChange(modelFields);
            value = result?.location ?? value;
          }
          if (errors.location?.hasError) {
            runValidationTasks("location", value);
          }
          setLocation(value);
        }}
        onBlur={() => runValidationTasks("location", location)}
        errorMessage={errors.location?.errorMessage}
        hasError={errors.location?.hasError}
        {...getOverrideProps(overrides, "location")}
      ></TextField>

      <TextField
        label="Date Start"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={datestart}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tripname,
              datestart: value,
              dateend,
              coverimage,
              headerimage,
              location,
              subheader1,
              subheader2,
            };
            const result = onChange(modelFields);
            value = result?.datestart ?? value;
          }
          if (errors.datestart?.hasError) {
            runValidationTasks("datestart", value);
          }
          setDatestart(value);
        }}
        onBlur={() => runValidationTasks("datestart", datestart)}
        errorMessage={errors.datestart?.errorMessage}
        hasError={errors.datestart?.hasError}
        {...getOverrideProps(overrides, "datestart")}
      ></TextField>

      <TextField
        label="Date End"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={dateend}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tripname,
              datestart,
              dateend: value,
              coverimage,
              headerimage,
              location,
              subheader1,
              subheader2,
            };
            const result = onChange(modelFields);
            value = result?.dateend ?? value;
          }
          if (errors.dateend?.hasError) {
            runValidationTasks("dateend", value);
          }
          setDateend(value);
        }}
        onBlur={() => runValidationTasks("dateend", dateend)}
        errorMessage={errors.dateend?.errorMessage}
        hasError={errors.dateend?.hasError}
        {...getOverrideProps(overrides, "dateend")}
      ></TextField>

      <TextField
        label="Subheader2"
        isRequired={false}
        isReadOnly={false}
        value={subheader2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tripname,
              datestart,
              dateend,
              coverimage,
              headerimage,
              location,
              subheader1,
              subheader2: value,
            };
            const result = onChange(modelFields);
            value = result?.subheader2 ?? value;
          }
          if (errors.subheader2?.hasError) {
            runValidationTasks("subheader2", value);
          }
          setSubheader2(value);
        }}
        onBlur={() => runValidationTasks("subheader2", subheader2)}
        errorMessage={errors.subheader2?.errorMessage}
        hasError={errors.subheader2?.hasError}
        {...getOverrideProps(overrides, "subheader2")}
      ></TextField>

      <DropZoneInputExample onFilesChange={handleFilesChange} />

      <Flex justifyContent="space-between">
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex gap="15px">
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
