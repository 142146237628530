// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Tripcities, ItineraryInvite, Tripinvite, Userinfo, Favoritelists, Favoriteitems, Food, Activity, Accomodation, Travel, Tripdetails, Useritins, Itin, Photorecord, Usertrips, AccountDelete, Trips } = initSchema(schema);

export {
  Tripcities,
  ItineraryInvite,
  Tripinvite,
  Userinfo,
  Favoritelists,
  Favoriteitems,
  Food,
  Activity,
  Accomodation,
  Travel,
  Tripdetails,
  Useritins,
  Itin,
  Photorecord,
  Usertrips,
  AccountDelete,
  Trips
};