import React, { useState } from 'react';
import { DataStore } from "aws-amplify/datastore";
import { Accomodation, Food, Travel, Activity } from "../../../models";

const DeleteItin = ({ isOpen, onClose, category, children }) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const toDelete = async () => {
    try {
      switch (category.category) {
        case 'Accomodation':
          await DataStore.delete(Accomodation, category.id);
          break;
        case 'Food':
          await DataStore.delete(Food, category.id);
          break;
        case 'Travel':
          await DataStore.delete(Travel, category.id);
          break;
        case 'Activity':
          await DataStore.delete(Activity, category.id);
          break;
        default:
          console.error('Invalid category:', category.category);
      }
     
      setDeleteSuccess(true); // Set delete success state to true
    } catch (error) {
      console.error('Error deleting item:', error);
      // Handle error as needed
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-40 w-full h-full flex justify-center items-center z-50 overflow-y-auto">
          <div className="absolute bg-white rounded w-60 h-70 overflow-y-auto shadow-lg">
            <div className="flex justify-between items-center px-4 py-2 border-b">
              <h2 className="text-lg font-semibold">{category.category} Details</h2>
              <button onClick={onClose}>Close</button>
            </div>
            <div className="p-4 mt-20">{children}</div>
            {!deleteSuccess ? ( // Render delete confirmation message if deletion is not successful
              <>
                <h2 
                  className="p-5 text-lg font-semibold flex justify-center"
                >
                  Are you sure you want to Delete {category.name}?
                </h2>
                <button 
                  onClick={toDelete} 
                  style={{ 
                    backgroundColor: 'black', 
                    color: 'white', 
                    borderRadius: '9999px', 
                    display: 'block', 
                    margin: '0 auto', 
                    padding: '10px 20px', 
                    border: 'none', 
                    cursor: 'pointer' 
                  }}
                >
                  Delete
                </button>
              </>
            ) : ( // Render success message if deletion is successful
              <>
                <h2 
                  className="p-5 text-lg font-semibold flex justify-center"
                >
                  Successfully deleted {category.name}.
                </h2>
                <button 
                  onClick={onClose} 
                  style={{ 
                    backgroundColor: 'black', 
                    color: 'white', 
                    borderRadius: '9999px', 
                    display: 'block', 
                    margin: '0 auto', 
                    padding: '10px 20px', 
                    border: 'none', 
                    cursor: 'pointer' 
                  }}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteItin;
