import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Accomodation } from "../../../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../../../ui-components/utils";
import { DataStore } from "aws-amplify/datastore";
import { post } from 'aws-amplify/api';

export default function AccomodationEditForm(props) {
  const {
    idProp,
    accomodation: accomodationModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    onClose,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    arrivaldate: "",
    departuredate: "",
    confirmation: "",
    phone: "",
    time: "",
    category: "",
    image: "",
    telephone: "",
    website: "",
    place: "", // Add place to initial values
  };
  const [name, setName] = React.useState(initialValues.name);
  const [address, setAddress] = React.useState(initialValues.address);
  const [latitude, setLatitude] = React.useState(initialValues.latitude);
  const [longitude, setLongitude] = React.useState(initialValues.longitude);
  const [arrivaldate, setArrivaldate] = React.useState(initialValues.arrivaldate);
  const [departuredate, setDeparturedate] = React.useState(initialValues.departuredate);
  const [confirmation, setConfirmation] = React.useState(initialValues.confirmation);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [time, setTime] = React.useState(initialValues.time);
  const [category, setCategory] = React.useState(initialValues.category);
  const [image, setImage] = React.useState(initialValues.image);
  const [telephone, setTelephone] = React.useState(initialValues.telephone);
  const [website, setWebsite] = React.useState(initialValues.website);
  const [place, setPlace] = React.useState(initialValues.place); // State for place
  const [errors, setErrors] = React.useState({});
  const [accomodationRecord, setAccomodationRecord] = React.useState(accomodationModelProp);

  const resetStateValues = () => {
    const cleanValues = accomodationRecord ? { ...initialValues, ...accomodationRecord } : initialValues;
    setName(cleanValues.name);
    setAddress(cleanValues.address);
    setLatitude(cleanValues.latitude);
    setLongitude(cleanValues.longitude);
    setArrivaldate(cleanValues.arrivaldate);
    setDeparturedate(cleanValues.departuredate);
    setConfirmation(cleanValues.confirmation);
    setPhone(cleanValues.phone);
    setTime(cleanValues.time);
    setCategory(cleanValues.category);
    setImage(cleanValues.image);
    setTelephone(cleanValues.telephone);
    setWebsite(cleanValues.website);
    setPlace(cleanValues.place); // Reset place
    setErrors({});
  };

  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp ? await DataStore.query(Accomodation, idProp) : accomodationModelProp;
      setAccomodationRecord(record);
    };
    queryData();
  }, [idProp, accomodationModelProp]);

  React.useEffect(resetStateValues, [accomodationRecord]);

  const validations = {
    name: [],
    address: [],
    latitude: [],
    longitude: [],
    arrivaldate: [],
    departuredate: [],
    confirmation: [],
    phone: [],
    time: [],
    category: [],
    image: [],
    telephone: [{ type: "Phone" }],
    website: [{ type: "URL" }],
    place: [], // Add validation for place if needed
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFindClick = async () => {
    try {
      if (!place) {
        console.error('Place is empty');
        return;
      }
      console.log(`Finding place: ${place}`);
      
      const restOperation = await post({
        apiName: 'test2',
        path: '/items',
        options: {
          body: {
            query: place,
          }
        }
      });

      console.log('call', restOperation);
      const { body } = await restOperation.response;
      const response = await body.json();
      
      console.log('POST call succeeded:', response);
      if (response && response.places && response.places.length > 0) {
        const placeData = response.places[0];
        setAddress(placeData.formattedAddress);
        setTelephone(placeData.internationalPhoneNumber);
        setWebsite(placeData.websiteUri);
        setLatitude(placeData.location.latitude);
        setLongitude(placeData.location.longitude);
        setImage(placeData.photoUrl);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          address,
          latitude,
          longitude,
          arrivaldate,
          departuredate,
          confirmation,
          phone,
          time,
          category,
          image,
          telephone,
          website,
          place, // Include place in model fields
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(runValidationTasks(fieldName, modelFields[fieldName]));
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Accomodation.copyOf(accomodationRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
          onClose(); 
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AccomodationUpdateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>

      <TextField
        label="Place"
        isRequired={false}
        isReadOnly={false}
        value={place}
        onChange={(e) => {
          let { value } = e.target;
          setPlace(value);
        }}
        {...getOverrideProps(overrides, "place")}
      ></TextField>
      <Button onClick={handleFindClick} style={{ backgroundColor: "#2CAFBC", color: "white" }}>
        Find
      </Button>

      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address: value,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>
      
      <TextField
        label="Latitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={latitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude: value,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.latitude ?? value;
          }
          if (errors.latitude?.hasError) {
            runValidationTasks("latitude", value);
          }
          setLatitude(value);
        }}
        onBlur={() => runValidationTasks("latitude", latitude)}
        errorMessage={errors.latitude?.errorMessage}
        hasError={errors.latitude?.hasError}
        {...getOverrideProps(overrides, "latitude")}
      ></TextField>

      <TextField
        label="Longitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={longitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude: value,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.longitude ?? value;
          }
          if (errors.longitude?.hasError) {
            runValidationTasks("longitude", value);
          }
          setLongitude(value);
        }}
        onBlur={() => runValidationTasks("longitude", longitude)}
        errorMessage={errors.longitude?.errorMessage}
        hasError={errors.longitude?.hasError}
        {...getOverrideProps(overrides, "longitude")}
      ></TextField>

      <TextField
        label="Arrivaldate"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={arrivaldate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate: value,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.arrivaldate ?? value;
          }
          if (errors.arrivaldate?.hasError) {
            runValidationTasks("arrivaldate", value);
          }
          setArrivaldate(value);
        }}
        onBlur={() => runValidationTasks("arrivaldate", arrivaldate)}
        errorMessage={errors.arrivaldate?.errorMessage}
        hasError={errors.arrivaldate?.hasError}
        {...getOverrideProps(overrides, "arrivaldate")}
      ></TextField>

      <TextField
        label="Departuredate"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={departuredate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate: value,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.departuredate ?? value;
          }
          if (errors.departuredate?.hasError) {
            runValidationTasks("departuredate", value);
          }
          setDeparturedate(value);
        }}
        onBlur={() => runValidationTasks("departuredate", departuredate)}
        errorMessage={errors.departuredate?.errorMessage}
        hasError={errors.departuredate?.hasError}
        {...getOverrideProps(overrides, "departuredate")}
      ></TextField>

      <TextField
        label="Confirmation"
        isRequired={false}
        isReadOnly={false}
        value={confirmation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation: value,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.confirmation ?? value;
          }
          if (errors.confirmation?.hasError) {
            runValidationTasks("confirmation", value);
          }
          setConfirmation(value);
        }}
        onBlur={() => runValidationTasks("confirmation", confirmation)}
        errorMessage={errors.confirmation?.errorMessage}
        hasError={errors.confirmation?.hasError}
        {...getOverrideProps(overrides, "confirmation")}
      ></TextField>

      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone: value,
              time,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>

      <TextField
        label="Time"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={time}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time: value,
              category,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.time ?? value;
          }
          if (errors.time?.hasError) {
            runValidationTasks("time", value);
          }
          setTime(value);
        }}
        onBlur={() => runValidationTasks("time", time)}
        errorMessage={errors.time?.errorMessage}
        hasError={errors.time?.hasError}
        {...getOverrideProps(overrides, "time")}
      ></TextField>

      <TextField
        label="Category"
        isRequired={false}
        isReadOnly={false}
        value={category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category: value,
              image,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          if (errors.category?.hasError) {
            runValidationTasks("category", value);
          }
          setCategory(value);
        }}
        onBlur={() => runValidationTasks("category", category)}
        errorMessage={errors.category?.errorMessage}
        hasError={errors.category?.hasError}
        {...getOverrideProps(overrides, "category")}
      ></TextField>

      <TextField
        label="Image"
        isRequired={false}
        isReadOnly={false}
        value={image}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image: value,
              telephone,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.image ?? value;
          }
          if (errors.image?.hasError) {
            runValidationTasks("image", value);
          }
          setImage(value);
        }}
        onBlur={() => runValidationTasks("image", image)}
        errorMessage={errors.image?.errorMessage}
        hasError={errors.image?.hasError}
        {...getOverrideProps(overrides, "image")}
      ></TextField>

      <TextField
        label="Telephone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={telephone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone: value,
              website,
              place,
            };
            const result = onChange(modelFields);
            value = result?.telephone ?? value;
          }
          if (errors.telephone?.hasError) {
            runValidationTasks("telephone", value);
          }
          setTelephone(value);
        }}
        onBlur={() => runValidationTasks("telephone", telephone)}
        errorMessage={errors.telephone?.errorMessage}
        hasError={errors.telephone?.hasError}
        {...getOverrideProps(overrides, "telephone")}
      ></TextField>

      <TextField
        label="Website"
        isRequired={false}
        isReadOnly={false}
        value={website}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website: value,
              place,
            };
            const result = onChange(modelFields);
            value = result?.website ?? value;
          }
          if (errors.website?.hasError) {
            runValidationTasks("website", value);
          }
          setWebsite(value);
        }}
        onBlur={() => runValidationTasks("website", website)}
        errorMessage={errors.website?.errorMessage}
        hasError={errors.website?.hasError}
        {...getOverrideProps(overrides, "website")}
      ></TextField>

      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || accomodationModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || accomodationModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
