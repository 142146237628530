import React, { useState, useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { RiLayout4Fill, RiLayoutGridFill } from "react-icons/ri";
import { Photorecord } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData, getProperties } from 'aws-amplify/storage';
import awsmobile from '../../../aws-exports';


const Gallery = ({city, userEmail}) => {
  const [isGrid, setIsGrid] = useState(true); 
  const [galleryimages, setImages] = useState([]);
   const [showModal, setShowModal] = useState(false);
    const [images, setImages2] = useState([]);
    const [preview, setPreview] = useState(null);



console.log('selected',galleryimages)
 
useEffect(() => {
    const fetchImages = async () => {
      try {
        const fetchedImages = await DataStore.query(Photorecord, c => c.tripsID.eq(city.id));
        console.log(fetchedImages);
        setImages(fetchedImages.map((image) => ({
          url: image.location,
        })));
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [city.id]);



  const toggleLayout = () => {
    setIsGrid((prevIsGrid) => !prevIsGrid);
  };

const handleFileChange = (event) => {
  const file = event.target.files[0];
   setImages2(file);
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }
};
  

const uploadImages = async () => {
  console.log('check', images);

  try {
    if (!images) {
      console.log('Images is null or undefined. Exiting uploadImages.');
      return;
    }

    // Log information about the current image
    console.log('Current Image:', images);

    if (!images.type) {
      console.log('Skipping current image due to missing or falsy type');
      return; // skip this image if type is missing or invalid
    }

    console.log('check1', images);

    // Check the file type and convert to JPEG if necessary
    const fileType = images.type.split('/').pop().toLowerCase();
  let convertedUri = preview;
  console.log('check2', fileType);
  let customFileName;
if (fileType !== 'jpg' && fileType !== 'jpeg') {
  // Create a Blob from the data URL
  const blob = await fetch(preview).then((response) => response.blob());

  // Use a custom name based on the current timestamp
  const customFileName = `custom_${Date.now()}.jpg`;

  // Create a File from the Blob with the custom name
  const tempFile = new File([blob], customFileName, { type: 'image/jpeg' });

  // Use URL.createObjectURL to create a temporary URL for the file
  convertedUri = URL.createObjectURL(tempFile);
}

// Use either the custom name or images.name based on fileType
const fileName = (fileType === 'jpg' || fileType === 'jpeg') ? images.name : customFileName;
    console.log('check3', convertedUri);
    console.log('check4', fileName);
    const s3Key = `images/${Date.now()}-${fileName}`;
    const imageUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${s3Key}`;
    await pathToImageFile(convertedUri, 'jpg', s3Key);
    const recordInput = {
      username: userEmail,
      imagename: fileName,
      key: s3Key,
      date: new Date().toISOString(),
      tripsID: city.id,
      location: imageUrl,
    };
    await DataStore.save(new Photorecord(recordInput));
    // Delete the temporary file if it was created
    if (fileType !== 'jpg' && fileType !== 'jpeg') {
      await deleteTemporaryFile(convertedUri);
    }

    setImages2(null);
    setShowModal(false);// clear the selected images after upload is complete
  } catch (error) {
    console.error(error);
  }
};

  const pathToImageFile = async (pathToImageFile, fileType, key) => {
  try {
      
      const response = await fetch(pathToImageFile)
      const blob = await response.blob();
    

  const result = await uploadData({
    key: key,
    data: blob,
    options: {
    contentType: `image/${fileType}`,
    }
  }).result;

  console.log('Succeeded: ', result);
} catch (error) {
  console.log('Error : ', error);
}

}

const deleteTemporaryFile = async (fileUri) => {
  try {
    // Create a Blob from the file URI
    const blob = await fetch(fileUri).then((response) => response.blob());

    // Create a File from the Blob
    const file = new File([blob], 'temp', { type: blob.type });

    // Use URL.revokeObjectURL to release the object URL
    URL.revokeObjectURL(fileUri);

    // Use FileReader to read the file as data URL
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // Wait for the reader to finish reading the file
    reader.onloadend = () => {
      // Remove the file by revoking the data URL
      const dataUrl = reader.result;
      URL.revokeObjectURL(dataUrl);
    };

    console.log('Temporary file deleted successfully');
  } catch (error) {
    console.error('Error deleting temporary file:', error);
  }
};

console.log('selected1',images)


  return (
    <div className="w-11/12 mx-auto py-10">
      <div className="flex justify-between items-center mb-6">
         <button
          onClick={() => setShowModal(true)}
          className="py-3 w-28 md:w-36 rounded-10 bg-black text-white font-medium tracking-0.8 text-sm md:text-lg flex justify-center items-center gap-2"
        >
          Add New
          <FiPlus className="text-light_green w-6 h-6" />
        </button>
        <div className="flex items-end gap-1 h-10">
          <p className="text-xl font-medium text-blue_3 tracking-0.8 mr-2">
            Grid:
          </p>
          <RiLayoutGridFill
            onClick={toggleLayout}
            className={`${
              isGrid ? "text-gray_5" : "text-light_green"
            } text-4xl cursor-pointer`}
          />
          <RiLayout4Fill
            onClick={toggleLayout}
            className={`${
              isGrid ? "text-light_green" : "text-gray_5"
            } text-4xl cursor-pointer`}
          />
        </div>
      </div>
 {/* Modal for Image Upload */}

     {showModal && (
  <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
    <div className="bg-white p-4 rounded-md h-200 w-180 flex flex-col items-center gap-10 overflow-hidden">
      {images ? (
        <>
        <div className="bg-white p-4 rounded-md h-96 w-80 flex flex-col items-center gap-10 overflow-hidden">
          <img
            src={preview}
            alt={`Selected Image`}
            className="mb-4 max-h-full w-full object-cover rounded-md"
          />
          </div>
          <button
            onClick={uploadImages}
            className="bg-gray-500 rounded-md py-2 px-4 text-white"
          >
            Upload
          </button>
        </>
      ) : (
        <>
          <label htmlFor="imageUpload" className="cursor-pointer bg-gray-500 rounded-md py-2 px-4 text-white">
            Select Image
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
        </>
      )}
      <button
        onClick={() => {
          setImages2(null); // Reset the selected image
          setShowModal(false); // Close the modal
        }}
        className="bg-gray-500 rounded-md py-2 px-4 text-white"
      >
        Cancel
      </button>
    </div>
  </div>
      )}



      <div className={`container mx-auto ${isGrid ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3" : "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4"} gap-4`}>
        {galleryimages.map((imageUrl, index) => (
          <div
            key={index}
            className={
              isGrid && (index === 2 || index === 5)
                ? "row-span-2 overflow-hidden"
                : "overflow-hidden"
            }
          >
            <img
              src={imageUrl.url}
              alt={`Image ${index}`}
              className={ 
                isGrid
                   ? "aspect-auto h-full w-full rounded-md object-cover"
                   : "aspect-square h-full w-full rounded-md object-cover"
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
