import React, { useState } from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Travel, Useritins } from "../../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../../ui-components/utils";
import awsmobile from "../../../aws-exports";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from 'aws-amplify/storage';
import DropZoneInputExample from "../../../pages/Newtrip/imagepicker";
import { deleteTemporaryFile } from "./deletetemp";
import { post } from 'aws-amplify/api';

export default function NewTravelCreateForm({ onSuccess, ...props }) {
  const {
    clearOnSuccess = true,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    selectedTravel,
    city,
    userEmail,
    ...rest
  } = props;

  const initialValues = {
    type: selectedTravel,
    departuredate: "",
    arrivaldate: "",
    departuretime: "",
    arrivaltime: "",
    confirmation: "",
    tripsID: city.id,
    name: "",
    carrier: "",
    destination: "",
    origin: "",
    category: "Travel",
    latitude: "",
    longitude: "",
    image: "",
    address: "",
    number: "",
    website: "",
    place: "", // Add place to initial values
  };

  const [type, setType] = useState(initialValues.type);
  const [departuredate, setDeparturedate] = useState(initialValues.departuredate);
  const [arrivaldate, setArrivaldate] = useState(initialValues.arrivaldate);
  const [departuretime, setDeparturetime] = useState(initialValues.departuretime);
  const [arrivaltime, setArrivaltime] = useState(initialValues.arrivaltime);
  const [confirmation, setConfirmation] = useState(initialValues.confirmation);
  const [name, setName] = useState(initialValues.name);
  const [carrier, setCarrier] = useState(initialValues.carrier);
  const [destination, setDestination] = useState(initialValues.destination);
  const [origin, setOrigin] = useState(initialValues.origin);
  const [category, setCategory] = useState(initialValues.category);
  const [latitude, setLatitude] = useState(initialValues.latitude);
  const [longitude, setLongitude] = useState(initialValues.longitude);
  const [image, setCoverimage] = useState(initialValues.image);
  const [address, setAddress] = useState(initialValues.address);
  const [number, setNumber] = useState(initialValues.number);
  const [website, setWebsite] = useState(initialValues.website);
  const [place, setPlace] = useState(initialValues.place); // State for place
  const [errors, setErrors] = useState({});
  const [s3Key, sets3key] = useState(null);
  const [fileType, setfiletype] = useState(null);
  const [tempFile, settempfile] = useState(null);

  const resetStateValues = () => {
    setType(initialValues.type);
    setDeparturedate(initialValues.departuredate);
    setArrivaldate(initialValues.arrivaldate);
    setDeparturetime(initialValues.departuretime);
    setArrivaltime(initialValues.arrivaltime);
    setConfirmation(initialValues.confirmation);
    setName(initialValues.name);
    setCarrier(initialValues.carrier);
    setDestination(initialValues.destination);
    setOrigin(initialValues.origin);
    setCategory(initialValues.category);
    setLatitude(initialValues.latitude);
    setLongitude(initialValues.longitude);
    setCoverimage(initialValues.image);
    setAddress(initialValues.address);
    setNumber(initialValues.number);
    setWebsite(initialValues.website);
    setPlace(initialValues.place); // Reset place
    setErrors({});
  };

  const validations = {
    type: [],
    departuredate: [],
    arrivaldate: [],
    departuretime: [],
    arrivaltime: [],
    confirmation: [],
    name: [],
    carrier: [],
    destination: [],
    origin: [],
    category: [],
    latitude: [],
    longitude: [],
    image: [],
    address: [],
    number: [],
    website: [{ type: "URL" }],
    place: [], // Add validation for place if needed
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFilesChange = async ({ files, imageSrc }) => {
    try {
      if (!files || !imageSrc) {
        console.log('Files or imageSrc is null or undefined. Exiting handleFilesChange.');
        return;
      }

      console.log('Current Files:', files);
      console.log('Current Image:', imageSrc);

      const fileType = files[0].type.split('/').pop().toLowerCase();
      let convertedUri = imageSrc;
      let customFileName;

      if (fileType !== 'jpg' && fileType !== 'jpeg') {
        const blob = await fetch(imageSrc).then((response) => response.blob());
        const tempFile = new File([blob], `custom_${Date.now()}.jpg`, { type: 'image/jpeg' });
        convertedUri = URL.createObjectURL(tempFile);
        customFileName = tempFile.name;
      }

      const fileName = (fileType === 'jpg' || fileType === 'jpeg') ? files[0].name : customFileName;

      console.log('Converted URI:', convertedUri);
      console.log('File Name:', fileName);

      const s3Key1 = `images/${Date.now()}-${fileName}`;
      const imageUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${s3Key1}`;

      setCoverimage(imageUrl);
      sets3key(s3Key1);
      setfiletype(fileType);
      settempfile(convertedUri);

      console.log('key', s3Key1);
      console.log('type', fileType);
    } catch (error) {
      console.error(error);
    }
  };

  const pathToImageFile = async (tempFile, fileType, s3Key) => {
    try {
      const response = await fetch(tempFile);
      const blob = await response.blob();

      console.log('key', blob);
      console.log('key', s3Key);
      console.log('type', fileType);

      const result = await uploadData({
        key: s3Key,
        data: blob,
        options: {
          contentType: `image/${fileType}`,
        }
      }).result;

      console.log('Succeeded: ', result);
    } catch (error) {
      console.log('Error : ', error);
    }
  };

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleFindClick = async () => {
    try {
      if (!place) {
        console.error('Place is empty');
        return;
      }

      console.log(`Finding place: ${place}`);

      const restOperation = await post({
        apiName: 'test2',
        path: '/items',
        options: {
          body: {
            query: place,
          }
        }
      });

      console.log('call', restOperation);
      const { body } = await restOperation.response;
      const response = await body.json();

      console.log('POST call succeeded:', response);
      if (response && response.places && response.places.length > 0) {
        const placeData = response.places[0];
        setAddress(placeData.formattedAddress);
        setWebsite(placeData.websiteUri);
        setLatitude(placeData.location.latitude);
        setLongitude(placeData.location.longitude);
        setCoverimage(placeData.photoUrl); // Note the updated property name for photo URL
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          type,
          departuredate,
          arrivaldate,
          departuretime,
          arrivaltime,
          confirmation,
          name,
          carrier,
          destination,
          origin,
          category,
          latitude,
          longitude,
          image,
          address,
          number,
          website,
          tripsID: city.id, // Ensure tripsID is included here
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const savedTrip = await DataStore.save(new Travel(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }

          await pathToImageFile(tempFile, fileType, s3Key);
          handleSuccess();

          await DataStore.save(
            new Useritins({
              tripsID: city.id,
              userid: userEmail,
              type: 'Travel',
              elementid: savedTrip.id
            })
          );

        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "TravelCreateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name: value,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>

      <TextField
        label="Type of Travel"
        isRequired={false}
        isReadOnly={false}
        value={type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type: value,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.type ?? value;
          }
          if (errors.type?.hasError) {
            runValidationTasks("type", value);
          }
          setType(value);
        }}
        onBlur={() => runValidationTasks("type", type)}
        errorMessage={errors.type?.errorMessage}
        hasError={errors.type?.hasError}
        {...getOverrideProps(overrides, "type")}
      ></TextField>

      <Flex
        direction="row"
        alignItems="center"
        alignSelf="flex-end"
        marginTop="auto"
      >
        <TextField
          label="Place"
          isRequired={false}
          isReadOnly={false}
          value={place}
          onChange={(e) => {
            const { value } = e.target;
            setPlace(value);
          }}
          {...getOverrideProps(overrides, "place")}
        ></TextField>
        <Flex
          marginLeft={20}
          direction="row"
          alignItems="center"
          alignSelf="flex-end"
          marginTop="auto"
        >
          <Button
            onClick={handleFindClick}
            style={{ backgroundColor: "#2CAFBC", color: "white" }}
            {...getOverrideProps(overrides, "findButton")}
          >
            Find
          </Button>
        </Flex>
      </Flex>

      <TextField
        label="Destination"
        isRequired={false}
        isReadOnly={false}
        value={destination}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination: value,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.destination ?? value;
          }
          if (errors.destination?.hasError) {
            runValidationTasks("destination", value);
          }
          setDestination(value);
        }}
        onBlur={() => runValidationTasks("destination", destination)}
        errorMessage={errors.destination?.errorMessage}
        hasError={errors.destination?.hasError}
        {...getOverrideProps(overrides, "destination")}
      ></TextField>

      <TextField
        label="Origin"
        isRequired={false}
        isReadOnly={false}
        value={origin}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin: value,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.origin ?? value;
          }
          if (errors.origin?.hasError) {
            runValidationTasks("origin", value);
          }
          setOrigin(value);
        }}
        onBlur={() => runValidationTasks("origin", origin)}
        errorMessage={errors.origin?.errorMessage}
        hasError={errors.origin?.hasError}
        {...getOverrideProps(overrides, "origin")}
      ></TextField>

      <TextField
        label="Departure date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={departuredate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate: value,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.departuredate ?? value;
          }
          if (errors.departuredate?.hasError) {
            runValidationTasks("departuredate", value);
          }
          setDeparturedate(value);
        }}
        onBlur={() => runValidationTasks("departuredate", departuredate)}
        errorMessage={errors.departuredate?.errorMessage}
        hasError={errors.departuredate?.hasError}
        {...getOverrideProps(overrides, "departuredate")}
      ></TextField>

      <TextField
        label="Departure time"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={departuretime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime: value,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.departuretime ?? value;
          }
          if (errors.departuretime?.hasError) {
            runValidationTasks("departuretime", value);
          }
          setDeparturetime(value);
        }}
        onBlur={() => runValidationTasks("departuretime", departuretime)}
        errorMessage={errors.departuretime?.errorMessage}
        hasError={errors.departuretime?.hasError}
        {...getOverrideProps(overrides, "departuretime")}
      ></TextField>

      <TextField
        label="Arrival date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={arrivaldate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate: value,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.arrivaldate ?? value;
          }
          if (errors.arrivaldate?.hasError) {
            runValidationTasks("arrivaldate", value);
          }
          setArrivaldate(value);
        }}
        onBlur={() => runValidationTasks("arrivaldate", arrivaldate)}
        errorMessage={errors.arrivaldate?.errorMessage}
        hasError={errors.arrivaldate?.hasError}
        {...getOverrideProps(overrides, "arrivaldate")}
      ></TextField>

      <TextField
        label="Arrival time"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={arrivaltime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime: value,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.arrivaltime ?? value;
          }
          if (errors.arrivaltime?.hasError) {
            runValidationTasks("arrivaltime", value);
          }
          setArrivaltime(value);
        }}
        onBlur={() => runValidationTasks("arrivaltime", arrivaltime)}
        errorMessage={errors.arrivaltime?.errorMessage}
        hasError={errors.arrivaltime?.hasError}
        {...getOverrideProps(overrides, "arrivaltime")}
      ></TextField>

      <TextField
        label="Carrier"
        isRequired={false}
        isReadOnly={false}
        value={carrier}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier: value,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.carrier ?? value;
          }
          if (errors.carrier?.hasError) {
            runValidationTasks("carrier", value);
          }
          setCarrier(value);
        }}
        onBlur={() => runValidationTasks("carrier", carrier)}
        errorMessage={errors.carrier?.errorMessage}
        hasError={errors.carrier?.hasError}
        {...getOverrideProps(overrides, "carrier")}
      ></TextField>

      <TextField
        label={`${selectedTravel} Number`}
        isRequired={false}
        isReadOnly={false}
        value={number}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number: value,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.number ?? value;
          }
          if (errors.number?.hasError) {
            runValidationTasks("number", value);
          }
          setNumber(value);
        }}
        onBlur={() => runValidationTasks("number", number)}
        errorMessage={errors.number?.errorMessage}
        hasError={errors.number?.hasError}
        {...getOverrideProps(overrides, "number")}
      ></TextField>

      <TextField
        label="Confirmation"
        isRequired={false}
        isReadOnly={false}
        value={confirmation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation: value,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.confirmation ?? value;
          }
          if (errors.confirmation?.hasError) {
            runValidationTasks("confirmation", value);
          }
          setConfirmation(value);
        }}
        onBlur={() => runValidationTasks("confirmation", confirmation)}
        errorMessage={errors.confirmation?.errorMessage}
        hasError={errors.confirmation?.hasError}
        {...getOverrideProps(overrides, "confirmation")}
      ></TextField>

      <DropZoneInputExample onFilesChange={handleFilesChange} />

      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address: value,
              number,
              website,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>

      <TextField
        label="Website"
        isRequired={false}
        isReadOnly={false}
        value={website}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              type,
              departuredate,
              arrivaldate,
              departuretime,
              arrivaltime,
              confirmation,
              name,
              carrier,
              destination,
              origin,
              category,
              latitude,
              longitude,
              image,
              address,
              number,
              website: value,
              tripsID: city.id, // Ensure tripsID is included here
            };
            const result = onChange(modelFields);
            value = result?.website ?? value;
          }
          if (errors.website?.hasError) {
            runValidationTasks("website", value);
          }
          setWebsite(value);
        }}
        onBlur={() => runValidationTasks("website", website)}
        errorMessage={errors.website?.errorMessage}
        hasError={errors.website?.hasError}
        {...getOverrideProps(overrides, "website")}
      ></TextField>

      <Flex justifyContent="space-between" {...getOverrideProps(overrides, "CTAFlex")}>
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex gap="15px" {...getOverrideProps(overrides, "RightAlignCTASubFlex")}>
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
