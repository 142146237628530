import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Dashboard/DashbordLayout/DashboardLayout";
import { DataStore } from "@aws-amplify/datastore";
import { Tripinvite, Usertrips, ItineraryInvite, Food, Accomodation, Activity, Travel, Useritins } from "../../models";
import { fetchUserAttributes } from 'aws-amplify/auth';
import ActivityRenderer from "../../components/Dashboard/Itinerary/boxes";

import breakfastIcon from "../../assets/cafeIcon.svg";
import dinerIcon from "../../assets/Corasiny.svg";
import snackIcon from "../../assets/Corasiny.svg";
import buildingIcon from "../../assets/building.svg";
import travelIcon from "../../assets/arrival.svg";
import activityIcon from "../../assets/activityicon.svg";
import trainIcon from "../../assets/trainicon.svg";
import busIcon from "../../assets/busicon.svg";
import carIcon from "../../assets/caricon.svg";
import lunchIcon from "../../assets/dinerIcon.svg"

const UpdatesAndInvitationsPage = () => {
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [tripInvitations, setTripInvitations] = useState([]);
  const [ItinInvitations, setItinInvitations] = useState([]);
  const [inviteuserTrips, setuserTrips] = useState([]);
  const [itininvites, setitininvites] = useState([]); 
  const [accountinfo, setUserName] = useState({
    givenName: "",
    familyName: "",
    email: "",
    phoneNumber: "",
    phoneNumberVerified: false,
    emailVerified: false,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userInfo = await fetchUserAttributes(); // Fetch user attributes using the appropriate function
        const { given_name, family_name, email, phone_number, phone_number_verified, email_verified } = userInfo;

        const formattedGivenName = given_name.charAt(0).toUpperCase() + given_name.slice(1);
        const formattedFamilyName = family_name.charAt(0).toUpperCase() + family_name.slice(1);
        setCurrentUserEmail(email);
        //console.log('email',email);// Set user data in state
        setUserName({
          givenName: formattedGivenName,
          familyName: formattedFamilyName,
          email,
          phoneNumber: phone_number,
          phoneNumberVerified: phone_number_verified,
          emailVerified: email_verified
        });

      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };

    fetchUserData();
    fetchTripInvitations(); // Call fetchTripInvitations here

  }, [currentUserEmail]);

  // Define fetchTripInvitations function
  const fetchTripInvitations = async () => {
    try {
      const invitations = await DataStore.query(Tripinvite, (c) => c.and(
        c => [
          c.emailinvite.eq(currentUserEmail),
          c.invitestatus.eq('Sent')
        ]
      ));

      setTripInvitations(invitations);
      
      const tripsIDs = invitations.map(invitation => invitation.tripsID);
      const invitedemail = invitations.map(invitation => invitation.invitedby);

      const allUserTrips = await DataStore.query(Usertrips);

      const filteredUserTrips = allUserTrips.filter(userTrip => {
        return tripsIDs.includes(userTrip.tripsID) && invitedemail.includes(userTrip.user_email);
      });

      const items = await DataStore.query(ItineraryInvite, (u) => u.and(
        u => [
          u.emailinvite.eq(currentUserEmail),
          u.invitestatus.eq('Sent')
        ]
      ));
      console.log('invite', items)

      setitininvites(items);

      const elementIdsFood = items
        .filter(item => item.type === 'Food')
        .map(item => item.elementid);

      const elementIdsAccomodation = items
        .filter(item => item.type === 'Accomodation')
        .map(item => item.elementid);

      const elementIdsActivity = items
        .filter(item => item.type === 'Activity')
        .map(item => item.elementid);

      const elementIdsTravel = items
        .filter(item => item.type === 'Travel')
        .map(item => item.elementid);

      // Convert elementIds arrays into lists of predicates for respective queries
      const foodPredicates = elementIdsFood.map(elementid => DataStore.query(Food, c => c.id.eq(elementid)));
      const accomodationPredicates = elementIdsAccomodation.map(elementid => DataStore.query(Accomodation, c => c.id.eq(elementid)));
      const activityPredicates = elementIdsActivity.map(elementid => DataStore.query(Activity, c => c.id.eq(elementid)));
      const travelPredicates = elementIdsTravel.map(elementid => DataStore.query(Travel, c => c.id.eq(elementid)));

      // Perform the queries using Promise.all to get the results
      const [foodItems, accomodationItems, activityItems, travelItems] = await Promise.all([
        Promise.all(foodPredicates),
        Promise.all(accomodationPredicates),
        Promise.all(activityPredicates),
        Promise.all(travelPredicates)
      ]);

      // Flatten the results into single arrays
      const flattenedFoodItems = foodItems.flat();
      const flattenedAccomodationItems = accomodationItems.flat();
      const flattenedActivityItems = activityItems.flat();
      const flattenedTravelItems = travelItems.flat();

      const foodItemsWithDate = flattenedFoodItems.map(item => ({
        ...item,
        date: item.startdate,
      }));

      // Add the 'date' field for accomodationItems
      const accomodationItemsWithDate = flattenedAccomodationItems.map(item => ({
        ...item,
        date: item.arrivaldate,
      }));

      // Add the 'date' field for activityItems
      const activityItemsWithDate = flattenedActivityItems.map(item => ({
        ...item,
        date: item.startdate,
      }));

      // Add the 'date' field for travelItems
      const travelItemsWithDate = flattenedTravelItems.map(item => ({
        ...item,
        date: item.departuredate,
        time: item.departuretime
      }));

      const mergedItems = [...foodItemsWithDate, ...accomodationItemsWithDate, ...activityItemsWithDate, ...travelItemsWithDate];
      console.log('merged', mergedItems)

      // Merge ItinInvitations information into items array based on matching elementid
      const itemsWithItinInvitations = items.map(item => {
        const matchingItinInvite = mergedItems.find(itinInvite => itinInvite.id === item.elementid);
        if (matchingItinInvite) {
          return {
            inviteid: item.id,
            ...matchingItinInvite
          };
        }
        return item;
      });

      // Update state with the merged items array
      setItinInvitations(itemsWithItinInvitations);

      console.log('new2', itemsWithItinInvitations)

      console.log('new', filteredUserTrips)
      setuserTrips(filteredUserTrips);
    } catch (error) {
      console.error('Error fetching trip invitations', error);
    }
  };

  const handleAcceptInvitation = async (tripsID, invitationId) => {
    try {
      // Iterate over inviteuserTrips and create new Usertrips objects
      for (const trip of inviteuserTrips) {
        if (trip.tripsID === tripsID) {
          // Create a new object with only the desired fields
          const newTrip = {
            city: trip.city,
            cityphotourl: trip.cityphotourl,
            countryCallingCode: trip.countryCallingCode,
            countryCode: trip.countryCode,
            countryName: trip.countryName,
            currencyCode: trip.currencyCode,
            currencyName: trip.currencyName,
            officialLanguage: trip.officialLanguage,
            photoid: trip.photoid,
            tripsID: trip.tripsID,
            user_email: currentUserEmail,
            name: accountinfo.givenName + ' ' + accountinfo.familyName,
          };

          console.log('new trip', newTrip);

          // Bulk upload the modified Usertrips object
          const savedTrip = await DataStore.save(new Usertrips(newTrip));
          console.log("Accepted invitation:", savedTrip);

          const tripInviteToUpdate = await DataStore.query(Tripinvite, invitationId);
          if (tripInviteToUpdate) {
            // Use copyOf function to update the record
            const updatedTripInvite = Tripinvite.copyOf(tripInviteToUpdate, updated => {
              // Modify the invitestatus property
              updated.invitestatus = "Accepted";
            });
            // Save the updated object
            await DataStore.save(updatedTripInvite);
          }

          await fetchTripInvitations();
          console.log("Invitation ID:", invitationId);
        }
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
    }
  };

  const handleAcceptItininvitation = async (event) => {
    try {
      // Create a new object for the Useritins table
      const newUseritin = {
        userid: currentUserEmail,
        tripsID: event.tripsID,
        type: event.category,
        elementid: event.id
      };

      // Save the new Useritins record
      const savedUseritin = await DataStore.save(new Useritins(newUseritin));
      console.log("Accepted itinerary invitation:", savedUseritin);

      // Find the corresponding itininvite.id using the event.id
      const tripInviteToUpdate = await DataStore.query(ItineraryInvite, event.inviteid);
      if (tripInviteToUpdate) {
        // Use copyOf function to update the record
        const updatedTripInvite = ItineraryInvite.copyOf(tripInviteToUpdate, updated => {
          // Modify the invitestatus property
          updated.invitestatus = "Accepted";
        });
        // Save the updated object
        await DataStore.save(updatedTripInvite);
      }

      // Fetch trip invitations after successfully accepting the itinerary invitation
      await fetchTripInvitations();
    } catch (error) {
      console.error("Error accepting itinerary invitation:", error);
    }
  };

  const icondefine = (category, type) => {
    switch (category) {
      case "Food":
        switch (type) {
          case "Breakfast":
            return breakfastIcon;
          case "Lunch":
            return lunchIcon;
          case "Dinner":
            return dinerIcon;
          case "Snack":
            return snackIcon;
          default:
            return lunchIcon;
        }

      case "Travel":
        switch (type) {
          case "Airplane":
            return travelIcon;
          case "Train":
            return trainIcon;
          case "Bus":
            return busIcon;
          case "Car":
            return carIcon;
          default:
            return travelIcon;
        }

      case "Activity":
        return activityIcon;

      default:
        return buildingIcon;
    }
  };

  return (
    <DashboardLayout className="">
      <div className="ml-5 container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Updates and Invitations</h1>

        {/* Invitations Section */}
        <section className="mb-8">
          <h2 className="ml-5 text-xl font-semibold mb-4">Invitations</h2>

          {/* Trip Invitations */}
          <div className="ml-10 mb-6">
            <h3 className="text-lg font-medium mb-2">Trip Invitations</h3>
            {tripInvitations.length === 0 && (
              <p className="text-gray-500">You don't have any invites</p>
            )}
            {tripInvitations.map((invitation, index) => (
              <div key={index}>
                <div className="flex gap-6 w-98 l-98 flex-col custom:flex-row mt-10 ml-10">
                  <div className="w-full custom:w-40 h-50 rounded-md">
                    <img src={invitation.image} alt="Chicago" className="w-full h-full" />
                  </div>
                  <div className="flex flex-col justify-between gap-3 custom:gap-0">
                    <div>
                      <h2 className="text-heading_blue text-2xl font-semibold">
                        {invitation.tripname}
                      </h2>
                      <p className="text-blue_3 text-sm font-medium">
                        {invitation.description}
                      </p>
                    </div>
                    <div className="flex gap-7">
                      <ul>
                        <li className="text-heading_blue text-base font-semibold">
                          From:
                        </li>
                        <li className="text-blue_3 text-xs font-medium">
                          {new Date(invitation.datestart).toLocaleDateString('en-US', {
                            weekday: 'long',
                            timeZone: 'UTC'
                          })}
                        </li>
                        <li className="text-blue_3 text-xs font-medium">
                          {new Date(invitation.datestart).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            timeZone: 'UTC'
                          })}
                        </li>
                      </ul>
                      <ul>
                        <li className="text-heading_blue text-base font-semibold">
                          To:
                        </li>
                        <li className="text-blue_3 text-xs font-medium">
                          {new Date(invitation.dateend).toLocaleDateString('en-US', {
                            weekday: 'long',
                            timeZone: 'UTC'
                          })}
                        </li>
                        <li className="text-blue_3 text-xs font-medium">
                          {new Date(invitation.dateend).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            timeZone: 'UTC'
                          })}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-40 h-50 rounded-md relative flex items-center">
                    <button
                      className="bg-black text-white px-3 py-1 rounded"
                      onClick={() => handleAcceptInvitation(invitation.tripsID, invitation.id)}
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Itinerary Invitations */}
          <div className="ml-10">
            <h3 className="text-lg font-medium mb-2">Itinerary Invitations</h3>
            {ItinInvitations.length === 0 && (
              <p className="text-gray-500">You don't have any invites</p>
            )}
            {ItinInvitations && ItinInvitations.map((event, index) => (
              <div key={index} className="py-4 border-t border-gray_1 mt-8 relative">
                <div className="absolute -top-3 left-0 bg-white px-4">
                </div>

                <div className="pl-3 md:pl-6 mt-3 mb-8">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <img src={icondefine(event.category, event.type)} alt={`${event.category} Icon`} className="w-8 h-9" />
                      <h1 className="text-xl font-medium capitalize">{event.name}</h1>
                    </div>
                  </div>

                  <div className="flex flex-col lgmeduim:flex-row justify-between gap-5 mt-6 w-full md:w-11/12 m-auto">
                    <ActivityRenderer event={event} />
                  </div>
                </div>

                <div className="flex flex-col justify-between gap-3 custom:gap-0">
                  <button
                    className="bg-black text-white px-3 py-1 rounded"
                    onClick={() => handleAcceptItininvitation(event)}
                  >
                    Accept
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Updates Section */}
        <section>
          <h2 className="ml-5 text-xl font-semibold mb-4">Updates</h2>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default UpdatesAndInvitationsPage;
