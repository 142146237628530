import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Tripcities } from '../../../models';

const NewTripCityRecord = ({ currentUserEmail, cityID, date, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cityName, setCityName] = useState('');
  const [existingCity, setExistingCity] = useState(null);
  const [success, setSuccess] = useState(false); 


  const fetchExistingCity = async () => {
    try {
      const cities = await DataStore.query(Tripcities,  (c) => c.and( c => [
        c.tripsID.eq(cityID.id),
        c.date.eq(date) // Use userInfo directly here
      ]));// Use userInfo directly here);
      if (cities.length > 0) {
        setExistingCity(cities[0]);
        setCityName(cities[0].city); // Set cityName to existing city name if found
      }
    } catch (error) {
      console.error('Error fetching existing Trip City record:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExistingCity(); // Fetch existing city record on component mount
  }, []);

  // Function to create a new Tripcities record
  const handleCreateRecord = async () => {
    try {
      setLoading(true);

      // Create a new object for the Tripcities table
      const newTripCity = {
        email: currentUserEmail,
        tripsID: cityID.id,
        date: date,
        city: cityName, // Use the cityName state variable
      };

      // Save the new Tripcities record
      await DataStore.save(new Tripcities(newTripCity));

      setLoading(false);
      setError(null);
      setSuccess(true); 
    } catch (error) {
      console.error('Error creating new Trip City record:', error);
      setError(error);
      setLoading(false);
    }
  };

  // Function to update existing Tripcities record
  const handleUpdateRecord = async () => {
    try {
      setLoading(true);
      // Update existing city record
      await DataStore.save(
        Tripcities.copyOf(existingCity, updated => {
          updated.city = cityName; // Update city name
        })
      );
      setLoading(false);
      setError(null);
      setSuccess(true); 
    } catch (error) {
      console.error('Error updating Trip City record:', error);
      setError(error);
      setLoading(false);
    }
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 overflow-y-auto bg-gray-800 bg-opacity-50">
      <div className="relative bg-white rounded-lg w-96 p-6 shadow-lg">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={onClose}
        >
          X
        </button>
        {loading ? (
          <p className="text-black">Loading...</p>
        ) : error ? (
          <p className="text-red-500">Error: {error.message}</p>
        ) : existingCity ? (
          <>
            <p className="text-black">City: {existingCity.city}</p>
            <input
              type="text"
              placeholder="Enter updated city name"
              className="border border-gray-300 rounded-md w-full px-3 py-2 mt-2 focus:outline-none focus:border-blue-500"
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
            />
            <button
              onClick={handleUpdateRecord}
              disabled={loading || !cityName.trim()}
              className="bg-blue-500 text-white rounded-md px-4 py-2 mt-4 hover:bg-blue-600 focus:outline-none"
            >
              Update City
            </button>
          </>
        ) : (
          <>
            <input
              type="text"
              placeholder="Enter city name"
              className="border border-gray-300 rounded-md w-full px-3 py-2 mt-2 focus:outline-none focus:border-blue-500"
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
            />
            <button
              onClick={handleCreateRecord}
              disabled={loading || !cityName.trim()}
              className="bg-blue-500 text-white rounded-md px-4 py-2 mt-4 hover:bg-blue-600 focus:outline-none"
            >
              Save City
            </button>
          </>
        )}
        {success && <p className="text-green-500">New Trip City record created successfully!</p>}
      </div>
    </div>
  );
};

export default NewTripCityRecord;