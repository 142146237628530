  import React, { useState, useEffect } from "react";
  import { DataStore } from "@aws-amplify/datastore";
  import { getCurrentUser } from 'aws-amplify/auth';
  import { Usertrips, Photorecord, Useritins, Food, Accomodation,Activity, Travel } from "../../models";
  import { Link } from "react-router-dom";
  import Favorites from "../../components/Dashboard/Favorites/Favorites";
  import Gallery from "../../components/Dashboard/Gallery/Gallery";
  import Itinerary from "../../components/Dashboard/Itinerary/Itinerary";
  import Main from "../../components/Dashboard/Main/Main";
  import { differenceInDays, addDays, format } from 'date-fns';

  const CityDetails = ({ id, coverimage, subheader1, subheader2, tripname, datestart, dateend, email_user }) => {
    const [tripcities, setImageUrls] = useState([]);
    const [userTrips, setUserTrips] = useState([]);
    const [images, setImages] = useState([]);
    const [filteredUserTrips, setFilteredUserTrips] = useState([]);
    const [userEmail, setUserEmail] = useState(null);
    const [openTab, setOpenTab] = useState(1);
    const [data2, setData] = useState([]);
    const [travelinfo, setTravelinfo]  = useState([]);
    const [accomodationdata, setAccomodation] = useState([]); 
    const [fulllist, setfulllist] = useState([]); 
  


    useEffect(() => {
      const fetchUserTrips = async () => {
        if (email_user) {
          try {
          
            const usertrips = await DataStore.query(Usertrips, (c) => c.and( c => [
              c.tripsID.eq(id),
            
          ]));

            setfulllist(usertrips);
            setUserEmail(email_user);

            const filteredTrips = usertrips.filter(
              (trip) => trip.user_email === email_user
            );
            setFilteredUserTrips(filteredTrips);
             console.log("usertripsfiltered", filteredTrips);

            const uniqueUserTrips = Array.from(
              new Set(
                usertrips.map((trip) =>
                  JSON.stringify({ name: trip.name, userimage: trip.photoid, email: trip.user_email })
                )
              )
            ).map(JSON.parse);
            setUserTrips(uniqueUserTrips);

            const cities = Array.from(
              new Set(
                usertrips.map((trip) =>
                  JSON.stringify({
                    city: trip.city,
                    countryName: trip.countryName,
                    locationimage: trip.cityphotourl,
                    
                  })
                )
              )
            ).map(JSON.parse);
            setImageUrls(cities);
            // console.log("usertripsfiltered", cities);
          } catch (error) {
            console.log("Error fetching user trips", error);
          }
        }
      };

      fetchUserTrips();
    }, [id, email_user]);

    const fetchData = async () => {
    try {
      const items = await DataStore.query(Useritins, (c) => c.and( c => [
        c.tripsID.eq(id),
        c.userid.eq(email_user)
      ]));
      ; // Use userInfo directly here
        console.log('items',items);
    const elementIdsFood = items
        .filter(item => item.type === 'Food')
        .map(item => item.elementid);
    

      const elementIdsAccomodation = items
        .filter(item => item.type === 'Accomodation')
        .map(item => item.elementid);
    

      const elementIdsActivity = items
        .filter(item => item.type === 'Activity')
        .map(item => item.elementid);
    

      const elementIdsTravel = items
        .filter(item => item.type === 'Travel')
        .map(item => item.elementid);
      

      // Convert elementIds arrays into lists of predicates for respective queries
      const foodPredicates = elementIdsFood.reduce((acc, elementid) => {
        acc.push(DataStore.query(Food, c => c.id.eq(elementid)));
        return acc;
      }, []);

      const accomodationPredicates = elementIdsAccomodation.reduce((acc, elementid) => {
        acc.push(DataStore.query(Accomodation, c => c.id.eq(elementid)));
        return acc;
      }, []);

      const activityPredicates = elementIdsActivity.reduce((acc, elementid) => {
        acc.push(DataStore.query(Activity, c => c.id.eq(elementid)));
        return acc;
      }, []);

      const travelPredicates = elementIdsTravel.reduce((acc, elementid) => {
        acc.push(DataStore.query(Travel, c => c.id.eq(elementid)));
        return acc;
      }, []);

      // Perform the queries using Promise.all to get the results
      const foodItems = await Promise.all(foodPredicates);
      const accomodationItems = await Promise.all(accomodationPredicates);
      const activityItems = await Promise.all(activityPredicates);
      const travelItems = await Promise.all(travelPredicates);

      // Flatten the results into single arrays
      const flattenedFoodItems = foodItems.reduce((acc, curr) => acc.concat(curr), []);
      const flattenedAccomodationItems = accomodationItems.reduce((acc, curr) => acc.concat(curr), []);
      const flattenedActivityItems = activityItems.reduce((acc, curr) => acc.concat(curr), []);
      const flattenedTravelItems = travelItems.reduce((acc, curr) => acc.concat(curr), []);

      const foodItemsWithDate = flattenedFoodItems.map(item => ({
    ...item,
    date: item.startdate,
  }));

  // Add the 'date' field for accomodationItems
  const accomodationItemsWithDate = flattenedAccomodationItems.map(item => ({
    ...item,
    date: item.arrivaldate,
    }));

  // Add the 'date' field for activityItems
  const activityItemsWithDate = flattenedActivityItems.map(item => ({
    ...item,
    date: item.startdate,
  }));

  // Add the 'date' field for travelItems
  const travelItemsWithDate = flattenedTravelItems.map(item => ({
    ...item,
    date: item.departuredate,
    time: item.departuretime
  }));

    setTravelinfo(travelItemsWithDate);
  setAccomodation(accomodationItemsWithDate);

      // Combine all arrays to setData
      setData([...foodItemsWithDate, ...accomodationItemsWithDate, ...activityItemsWithDate, ...travelItemsWithDate]);
      
    console.log('Itin', foodItemsWithDate, accomodationItemsWithDate, activityItemsWithDate, travelItemsWithDate);
    } catch (error) {
      console.log('Error fetching image URLs', error);
    }
  };
    
  useEffect(() => {
      fetchData();
    }, [id, userEmail]);

    const groupedData = data2.reduce((acc, curr) => {
          const date = curr.date;
          const existingIndex = acc.findIndex((item) => item.date === date);
          if (existingIndex !== -1) {
            acc[existingIndex].activities.push(curr);
          } else {
            acc.push({
              date: date,
              activities: [curr],
            });
          }
          return acc;
        }, []);

         console.log('grouped',groupedData)
        
      
         groupedData.forEach(group => {
          group.activities.sort((a, b) => {
            const aTime = new Date(`${group.date}T${a.time}:00Z`);
            const bTime = new Date(`${group.date}T${b.time}:00Z`);
            return aTime - bTime;
          });
        });
        
        // Sort the grouped data by the earliest activity time
        const sortedData = groupedData.sort((a, b) => {
          const aDateTime = new Date(`${a.date}T${a.activities[0].time}:00Z`);
          const bDateTime = new Date(`${b.date}T${b.activities[0].time}:00Z`);
          return aDateTime - bDateTime;
        });
        
        console.log(sortedData);
        
  const startDateUTC = new Date(datestart + "Z");
  const endDateUTC = new Date(dateend + "Z");

        
      const today = new Date();
      const year = today.getFullYear().toString();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const todayDate = `${year}-${month}-${day}`;

      
      const todayItineraryItems = sortedData.filter(
        (item) => item.date === todayDate
      );
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const tomorrowyear = tomorrow.getFullYear().toString();
      const tomorrowmonth = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
      const tomorrowday = tomorrow.getDate().toString().padStart(2, "0");
      const tomorrowDate = `${tomorrowyear}-${tomorrowmonth}-${tomorrowday}`;

      const upcomingTravel = travelinfo.filter(
        (item) => item.date === todayDate || item.date === tomorrowDate
      );

const getDatesBetween = (start, end) => {
  const startDateUTC = new Date(start);
  const endDateUTC = new Date(end);

  // Ensure that the dates are treated as UTC
  startDateUTC.setUTCHours(0, 0, 0, 0);
  endDateUTC.setUTCHours(0, 0, 0, 0);

  // console.log('StartDateUTC:', startDateUTC.toISOString());
  // console.log('EndDateUTC:', endDateUTC.toISOString());

  const daysDifference = differenceInDays(endDateUTC, startDateUTC);

  return Array.from({ length: daysDifference + 1 }, (_, index) => {
    const currentDateUTC = addDays(startDateUTC, index);
    return currentDateUTC.toISOString().split('T')[0];
  });
};


    const allDates = getDatesBetween(startDateUTC, endDateUTC);
    
 const accomodationDates = accomodationdata.map(item => {
  // Convert departuredate to a Date object
  const departureDate = new Date(item.departuredate);
  
  // Subtract one day from departuredate
  departureDate.setDate(departureDate.getDate() - 1);

  return {
    arrivaldate: item.arrivaldate,
    departuredate: departureDate.toISOString().split('T')[0], // Convert back to ISO string
  };
});

 // console.log('check',accomodationDates)

  const allAccomodationDays = accomodationDates
    .map(item => getDatesBetween(item.arrivaldate, item.departuredate))
    .flat();

 //console.log('UTC:', allAccomodationDays.map(date => new Date(date).toISOString()));
 const getDatesBetweenextended = (startDate, endDate, id, name, image) => {
  const dates = [];
  let currentDate = new Date(startDate);
  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

  while (currentDate <= adjustedEndDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates.map(date => ({
    id: id,
    name: name,
    image: image,
    date: date.toISOString().split('T')[0], // Convert to ISO string
  }));
};

const accomodationbynight = accomodationdata
  .map(item => getDatesBetweenextended(item.arrivaldate, item.departuredate, item.id, item.name, item.image))
  .flat();

 //console.log('acomo1',accomodationdata) 
 // console.log("acomo",accomodationbynight)

const allAccomodationDaysInAllDates = allDates.every(date => allAccomodationDays.includes(date));

// Find the days without a match in allDates
const daysWithoutMatch = allDates.filter(date => !allAccomodationDays.includes(date));

const mergedData = allDates.map(dateString => {
  const sortedDateItem = sortedData.find(item => item.date === dateString);

  if (sortedDateItem) {
    return {
      date: dateString,
      activities: sortedDateItem.activities || [],
    };
  } else {
    return {
      date: dateString,
      activities: [], // Set to an empty array if no matching date is found
    };
  }
});


// console.log('All Accomodation Days are in All Dates:', allAccomodationDaysInAllDates);
// console.log('Days without a match in All Dates:', daysWithoutMatch);
// console.log('All Accomodation Dates:', todayItineraryItems);
// console.log('dates',upcomingTravel)

const handleUpdate = async () => {
    try {
      // Fetch data again
      await fetchData();
      // Optionally, you can display a success message or perform other actions after updating the data
    } catch (error) {
      console.error('Error updating data:', error);
      // Handle error as needed
    }
  };
    
    return (
      <div className="w-full">
        <div className="text-center border-b border-light_blue px-3 md:px-8">
          {/* Tabs Links  */}
          <ul className="flex flex-wrap gap-3 md:gap-6">
            {/* Main Tab Link  */}
            <li>
              <Link
                className={
                  "inline-block p-2 md:p-4 text-heading_blue text-sm md:text-lg font-medium tracking-0.8" +
                  (openTab === 1
                    ? "font-bold border-b-4 text-light_green border-blue_2"
                    : "")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                to="#link1"
                role="tablist"
              >
                Main
              </Link>
            </li>

            {/* Itinerary Tab Link  */}
            <li>
              <Link
                className={
                  "inline-block p-2 md:p-4 text-heading_blue text-sm md:text-lg font-medium tracking-0.8" +
                  (openTab === 2
                    ? "font-bold border-b-4 text-light_green border-blue_2"
                    : "")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                to="#link2"
                role="tablist"
              >
                Itinerary
              </Link>
            </li>

            {/* Favorites Tab Link  */}
            <li>
              <Link
                className={
                  "inline-block p-2 md:p-4 text-heading_blue text-sm md:text-lg font-medium tracking-0.8" +
                  (openTab === 3
                    ? "font-bold border-b-4 text-light_green border-blue_2"
                    : "")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                to="#link3"
                role="tablist"
              >
                Favorites
              </Link>
            </li>

            {/* Gallery Tab Link  */}
            <li>
              <Link
                className={
                  "inline-block p-2 md:p-4 text-heading_blue text-sm md:text-lg font-medium tracking-0.8" +
                  (openTab === 4
                    ? "font-bold border-b-4 text-light_green border-blue_2"
                    : "")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                to="#link4"
                role="tablist"
              >
                Gallery
              </Link>
            </li>
         <button
        className="bg-blue_2 hover:bg-black text-white font-bold py-2 px-4 rounded m-2 p-6"
        onClick={handleUpdate}
      >
        Update Data
      </button>

          </ul>

          
        </div>



        {/* Tabs Components */}

        <div className="relative flex flex-col w-full break-words">
          <div className={openTab === 1 ? "block" : "hidden"} id="link1">
            <Main
              city={{
                id,
                coverimage,
                subheader1,
                subheader2,
                tripname,
                datestart,
                dateend,
              }}
              todayItineraryItems={todayItineraryItems}
              upcomingTravel={upcomingTravel}
              userTrips={userTrips}
              tripcities={tripcities}
              filteredUserTrips ={filteredUserTrips}
              nohoteldates={daysWithoutMatch}
              userEmail={userEmail}
            />
          </div>
          <div className={openTab === 2 ? "block" : "hidden"} id="link2">
            <Itinerary 
            sortedData={sortedData}
            allDates={allDates}
            city={{
                id,
                coverimage,
                subheader1,
                subheader2,
                tripname,
                datestart,
                dateend,
             }}
             accomodationbynight ={accomodationbynight}
             fulllist ={fulllist}
             userTrips ={userTrips}
             userEmail={userEmail}
            
            />
          </div>
          <div className={openTab === 3 ? "block" : "hidden"} id="link3">
            <Favorites 
               city={{
                id,
                
             }}
            />
          </div>
          <div className={openTab === 4 ? "block" : "hidden"} id="link4">
            <Gallery 
            city={{
                id,
                
             }}
             userEmail={userEmail}

            />
          </div>
        </div>
      </div>
    );
  };
  export default CityDetails;
