import React, { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Tripinvite } from "../../../models";
import { getCurrentUser } from 'aws-amplify/auth';

const InvitePopup = ({ onClose, onSendInvite, city }) => {
  const [inviteEmail, setInviteEmail] = useState(""); // State to store the email address entered by the user
  const [currentUserEmail, setCurrentUserEmail] = useState(""); // State to store the current user's email
  const [inviteSent, setInviteSent] = useState(false); // State to track if invite is sent successfully

  useEffect(() => {
    // Function to get the current authenticated user's email
    const getCurrentUserEmail = async () => {
      try {
        const user = await getCurrentUser();
        setCurrentUserEmail(user.signInDetails.loginId);
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };

    getCurrentUserEmail();
  }, []);

  // Function to handle sending the invite
  const sendInvite = async () => {
    try {
      onSendInvite(inviteEmail, city);
      const newTripInvite = await DataStore.save(
        new Tripinvite({
          emailinvite: inviteEmail,
          tripsID: city.id,
          tripname: city.tripname,
          datestart: city.datestart,
          dateend: city.dateend,
          description: city.subheader1,
          image: city.coverimage,
          invitestatus: "Sent",
          invitedby: currentUserEmail, // Set the invited by email to the current user's email
        })
      );

      console.log("New Trip Invite:", newTripInvite);

      // Reset the input field
      setInviteEmail("");
      // Set inviteSent state to true to display "Sent!" message
      setInviteSent(true);
    } catch (error) {
      console.error("Error sending invite:", error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-4">Send Invite</h2>
          <input
            type="email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            placeholder="Enter email address"
            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded focus:outline-none"
          />
          {/* Conditional rendering for "Sent!" message */}
          {inviteSent ? (
            <p className="text-green-500 font-semibold">Sent!</p>
          ) : (
            <button
              onClick={sendInvite}
              className="w-full px-4 py-2 bg-blue_4 text-white rounded hover:bg-blue_3 focus:outline-none"
            >
              Send Invite
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvitePopup;
