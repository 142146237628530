import React, { useState, useEffect } from "react";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { FaCaretDown } from "react-icons/fa";
import { fetchUserAttributes } from 'aws-amplify/auth';

import Logo from "../../../assets/ANDANZA.svg";
import Profile from "../../../assets/profile.png";

const Navbar = ({ onToggleSidebar }) => {
const [userName, setUserName] = useState("Loading..");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userInfo = await fetchUserAttributes();
        const { given_name, family_name } = userInfo;

      const formattedGivenName = given_name.charAt(0).toUpperCase() + given_name.slice(1);
      const formattedFamilyName = family_name.charAt(0).toUpperCase() + family_name.slice(1);
      
       setUserName(`${formattedGivenName} ${formattedFamilyName}`);
      //console.log('chech naes',userInfo);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };


    fetchUserData();
  }, []);

   const getInitials = (name) => {
    const words = name.split(" ");
    return words.map((word) => word[0]).join("").toUpperCase();
  };


  return (
    <div className=" flex gap-4 w-full justify-between items-center px-4 md:px-8 border-b border-gray_1 bg-white h-18 z-10">
      {/* Logo  */}
      <div className="md:hidden flex">
        <img src={Logo} alt="ANDANZA" className="w-32" />
      </div>

      {/* Search Input  */}
      <input
        type="search"
        placeholder="Search"
        className="h-11 w-1/3 bg-input_bg rounded-sm	outline-none px-4 text-xl placeholder:text-black tracking-0.8 md:flex hidden"
      />

      {/* Profile Menu  & Toggle Menu */}
      <div className="flex items-center gap-2">
        {/* Profile Menu   */}
        <div className="border border-gray_1 rounded-md py-1 md:py-2 px-1 md:px-2.5 flex justify-center items-center gap-3 cursor-pointer">
           <div
          className=" rounded-full w-9 h-9 flex items-center justify-center bg-blue_2 text-white cursor-pointer"
          title={userName}
        >
          {getInitials(userName)}
        </div>
          <span className="text-lg font-medium md:flex hidden bold">
            {userName}
          </span>
          <FaCaretDown className="text-blue_3 w-5" />
        </div>

        {/* Toggle Menu  */}
        <HiMiniBars3CenterLeft
          onClick={onToggleSidebar}
          className="cursor-pointer text-heading_blue text-3xl md:hidden flex"
        />
      </div>
    </div>
  );
};

export default Navbar;
