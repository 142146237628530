import React from "react";
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { IoArrowForward, IoCloseSharp } from "react-icons/io5";
import { signOut } from 'aws-amplify/auth'; // Import signOut from AWS Amplify
import Logo from "../../../assets/ANDANZA.svg";
import LogOutIcon from "../../../assets/LogOutIcon.svg";

const Sidebar = ({ isVisible, onToggleSidebar }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate(); // Use the useNavigate hook

  const handleSignOut = async () => {
    try {
      await signOut();
      // Redirect to /login after signing out
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div
      className={`${
        isVisible ? "translate-x-0 h-full" : "-translate-x-full"
      } transform transition-transform duration-300  md:translate-x-0 fixed top-0 bottom-0 h-screen w-4/5 md:w-80 border-r flex flex-col justify-between border-gray_4 z-50 bg-white`}
    >
      <div>
        {/* Logo and Toggle Menu  */}
        <div className="p-4 h-18 flex justify-between items-center border-b border-gray_1">
          <img src={Logo} alt="Logo" className="w-44 h-6" />
          <IoCloseSharp
            onClick={onToggleSidebar}
            size={25}
            className="cursor-pointer text-tintcolor md:hidden flex"
          />
        </div>

        {/* New Trip Button  */}
        <div className="p-4 mb-3">
          <Link to="/newtrip" className="py-3 w-full rounded-10 bg-black text-white font-medium tracking-0.8 text-lg flex justify-center items-center gap-3">
            New Trip
            <IoArrowForward className="text-light_green w-6 h-6" />
          </Link>
        </div>

        {/* Routing Links */}
        <div className="flex flex-col p-4 gap-1">
        
          <li
            className={`text-heading_blue font-medium text-base hover:bg-hover_bg hover:text-black list-none p-3 rounded-md duration-300 ease-in-out cursor-pointer w-full ${
              pathname.includes("dashboard") && "bg-hover_bg text-black"
            }`}
          >
            <Link to="/dashboard">Upcoming Trips</Link>
          </li>


             {/* Updates Link  */}
             <li
            className={`text-heading_blue font-medium text-base hover:bg-hover_bg hover:text-black list-none p-3 rounded-md duration-300 ease-in-out cursor-pointer w-full ${
              pathname.includes("#") && "bg-hover_bg text-black"
            }`}
          >
            <Link to="/updates">Updates and Invitations</Link>
          </li>

          {/* Wishlist Link */}
          <li
            className={`text-heading_blue font-medium text-base hover:bg-hover_bg hover:text-black list-none p-3 rounded-md duration-300 ease-in-out cursor-pointer w-full ${
              pathname.includes("#") && "bg-hover_bg text-black"
            }`}
          >
            <Link to="#">Wishlist</Link>
          </li>

          {/* Past Trips Link */}
          <li
            className={`text-heading_blue font-medium text-base hover:bg-hover_bg hover:text-black list-none p-3 rounded-md duration-300 ease-in-out cursor-pointer w-full ${
              pathname.includes("#") && "bg-hover_bg text-black"
            }`}
          >
            <Link to="#">Past Trips</Link>
          </li>

          {/* Account Link  */}
          <li
            className={`text-heading_blue font-medium text-base hover:bg-hover_bg hover:text-black list-none p-3 rounded-md duration-300 ease-in-out cursor-pointer w-full ${
              pathname.includes("#") && "bg-hover_bg text-black"
            }`}
          >
            <Link to="/account">Account</Link>
          </li>


        </div>
      </div>

      {/* Logout Button & Copyright  */}
      <div>
        <button onClick={handleSignOut} className="border-y border-gray_1 w-full p-4 flex items-center gap-3 text-black_2 text-base">
          <img src={LogOutIcon} alt="LogOut Icon" className="w-4" />
          Logout
        </button>
        <p className="w-full p-4 text-black_2 text-sm">
          Adansa LTD, © 2005-2023
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
