import React, { useState } from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Accomodation, Useritins } from "../../../models";
import { fetchByPath, getOverrideProps, validateField } from "../../../ui-components/utils";
import awsmobile from "../../../aws-exports";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from 'aws-amplify/storage';
import DropZoneInputExample from "../../../pages/Newtrip/imagepicker";
import { post } from 'aws-amplify/api';

export default function AccomodationCreate({ onSuccess, ...props }) {
  const {
    clearOnSuccess = true,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    selectedAccommodation,
    city,
    userEmail,
    ...rest
  } = props;

  const initialValues = {
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    arrivaldate: "",
    departuredate: "",
    confirmation: "",
    tripsID: city.id,
    phone: "",
    time: "",
    category: "Accomodation",
    image: "",
    telephone: "",
    website: "",
    place: "", // Add place to initial values
    type: selectedAccommodation,
  };

  const [name, setName] = useState(initialValues.name);
  const [address, setAddress] = useState(initialValues.address);
  const [latitude, setLatitude] = useState(initialValues.latitude);
  const [longitude, setLongitude] = useState(initialValues.longitude);
  const [arrivaldate, setArrivaldate] = useState(initialValues.arrivaldate);
  const [departuredate, setDeparturedate] = useState(initialValues.departuredate);
  const [confirmation, setConfirmation] = useState(initialValues.confirmation);
  const [phone, setPhone] = useState(initialValues.phone);
  const [s3Key, sets3key] = useState(null);
  const [fileType, setfiletype] = useState(null);
  const [tempFile, settempfile] = useState(null);
  const [time, setTime] = useState(initialValues.time);
  const [category, setCategory] = useState(initialValues.category);
  const [image, setCoverimage] = useState(initialValues.image);
  const [telephone, setTelephone] = useState(initialValues.telephone);
  const [website, setWebsite] = useState(initialValues.website);
  const [place, setPlace] = useState(initialValues.place); // State for place
  const [errors, setErrors] = useState({});

  const resetStateValues = () => {
    setName(initialValues.name);
    setAddress(initialValues.address);
    setLatitude(initialValues.latitude);
    setLongitude(initialValues.longitude);
    setArrivaldate(initialValues.arrivaldate);
    setDeparturedate(initialValues.departuredate);
    setConfirmation(initialValues.confirmation);
    setPhone(initialValues.phone);
    setTime(initialValues.time);
    setCategory(initialValues.category);
    setCoverimage(initialValues.coverimage);
    setTelephone(initialValues.telephone);
    setWebsite(initialValues.website);
    setPlace(initialValues.place); // Reset place
    setErrors({});
  };

  const validations = {
    name: [],
    address: [],
    latitude: [],
    longitude: [],
    arrivaldate: [],
    departuredate: [],
    confirmation: [],
    phone: [],
    time: [],
    category: [],
    image: [],
    telephone: [{ type: "Phone" }],
    website: [{ type: "URL" }],
    place: [], // Add validation for place if needed
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const handleFilesChange = async ({ files, imageSrc }) => {
    try {
      if (!files || !imageSrc) {
        console.log('Files or imageSrc is null or undefined. Exiting handleFilesChange.');
        return;
      }

      const fileType = files[0].type.split('/').pop().toLowerCase();
      let convertedUri = imageSrc;
      let customFileName;

      if (fileType !== 'jpg' && fileType !== 'jpeg') {
        const blob = await fetch(imageSrc).then((response) => response.blob());
        const tempFile = new File([blob], `custom_${Date.now()}.jpg`, { type: 'image/jpeg' });
        convertedUri = URL.createObjectURL(tempFile);
        customFileName = tempFile.name;
      }

      const fileName = (fileType === 'jpg' || fileType === 'jpeg') ? files[0].name : customFileName;

      const s3Key1 = `images/${Date.now()}-${fileName}`;
      const imageUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${s3Key1}`;

      setCoverimage(imageUrl);
      sets3key(s3Key1);
      setfiletype(fileType);
      settempfile(convertedUri);
    } catch (error) {
      console.error(error);
    }
  };

  const pathToImageFile = async (tempFile, fileType, s3Key) => {
    try {
      const response = await fetch(tempFile);
      const blob = await response.blob();

      const result = await uploadData({
        key: s3Key,
        data: blob,
        options: {
          contentType: `image/${fileType}`,
        }
      }).result;

      console.log('Succeeded: ', result);
    } catch (error) {
      console.log('Error : ', error);
    }
  };

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleFindClick = async () => {
    try {
      if (!place) {
        console.error('Place is empty');
        return;
      }

      const restOperation = await post({
        apiName: 'test2',
        path: '/items',
        options: {
          body: {
            query: place,
          }
        }
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      console.log('response',response);

      if (response && response.places && response.places.length > 0) {
        const placeData = response.places[0];
        setAddress(placeData.formattedAddress);
        
        setPhone(placeData.internationalPhoneNumber);
        setTelephone(placeData.internationalPhoneNumber);
        setWebsite(placeData.websiteUri);
        setLatitude(placeData.location.latitude);
        setLongitude(placeData.location.longitude);
        setCoverimage(placeData.photoUrl !== "N/A" ? placeData.photoUrl : (selectedAccommodation === "Airbnb" ? "https://andanza-storage-f0ae67d0194523-prod.s3.amazonaws.com/public/accomodations/Airbnb.jpg" : "")); // Updated conditional check for image
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          address,
          latitude,
          longitude,
          arrivaldate,
          departuredate,
          confirmation,
          phone,
          time,
          category,
          image,
          telephone,
          website,
          tripsID: city.id, // Ensure tripsID is included here
        };

        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );

        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });

          const savedTrip = await DataStore.save(new Accomodation(modelFields));

          if (clearOnSuccess) {
            resetStateValues();
          }

          await pathToImageFile(tempFile, fileType, s3Key);
          handleSuccess();

          await DataStore.save(
            new Useritins({
              tripsID: city.id,
              userid: userEmail,
              type: 'Accomodation',
              elementid: savedTrip.id
            })
          );

        } catch (err) {
          console.error('Error saving data:', err);
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AccomodationCreateForm")}
      {...rest}
    >
      <TextField
        label="Name of the place"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>

      <Flex direction="row" alignItems="center" alignSelf="flex-end" marginTop="auto">
        <TextField
          label="Place or Address Search"
          isRequired={false}
          isReadOnly={false}
          value={place}
          onChange={(e) => {
            const { value } = e.target;
            setPlace(value);
          }}
          {...getOverrideProps(overrides, "place")}
        ></TextField>
        <Flex marginLeft={20} direction="row" alignItems="center" alignSelf="flex-end" marginTop="auto">
          <Button
            onClick={handleFindClick}
            style={{ backgroundColor: "#2CAFBC", color: "white" }}
            {...getOverrideProps(overrides, "findButton")}
          >
            Find
          </Button>
        </Flex>
      </Flex>

      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address: value,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>

      <TextField
        label="Arrival date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={arrivaldate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate: value,
              departuredate,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.arrivaldate ?? value;
          }
          if (errors.arrivaldate?.hasError) {
            runValidationTasks("arrivaldate", value);
          }
          setArrivaldate(value);
        }}
        onBlur={() => runValidationTasks("arrivaldate", arrivaldate)}
        errorMessage={errors.arrivaldate?.errorMessage}
        hasError={errors.arrivaldate?.hasError}
        {...getOverrideProps(overrides, "arrivaldate")}
      ></TextField>

      <TextField
        label="Departure date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={departuredate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate: value,
              confirmation,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.departuredate ?? value;
          }
          if (errors.departuredate?.hasError) {
            runValidationTasks("departuredate", value);
          }
          setDeparturedate(value);
        }}
        onBlur={() => runValidationTasks("departuredate", departuredate)}
        errorMessage={errors.departuredate?.errorMessage}
        hasError={errors.departuredate?.hasError}
        {...getOverrideProps(overrides, "departuredate")}
      ></TextField>

      <TextField
        label="Confirmation code"
        isRequired={false}
        isReadOnly={false}
        value={confirmation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation: value,
              phone,
              time,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.confirmation ?? value;
          }
          if (errors.confirmation?.hasError) {
            runValidationTasks("confirmation", value);
          }
          setConfirmation(value);
        }}
        onBlur={() => runValidationTasks("confirmation", confirmation)}
        errorMessage={errors.confirmation?.errorMessage}
        hasError={errors.confirmation?.hasError}
        {...getOverrideProps(overrides, "confirmation")}
      ></TextField>

      <TextField
        label="Phone"
        placeholder="+XX-XXX-XXX-XXXX"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone: value,
              time,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>

      <TextField
        label="Check in Time"
        isRequired={false}
        isReadOnly={false}
        type="time"
        value={time}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time: value,
              category,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.time ?? value;
          }
          if (errors.time?.hasError) {
            runValidationTasks("time", value);
          }
          setTime(value);
        }}
        onBlur={() => runValidationTasks("time", time)}
        errorMessage={errors.time?.errorMessage}
        hasError={errors.time?.hasError}
        {...getOverrideProps(overrides, "time")}
      ></TextField>

      <TextField
        label="Category"
        isRequired={false}
        isReadOnly={false}
        value={category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              address,
              latitude,
              longitude,
              arrivaldate,
              departuredate,
              confirmation,
              phone,
              time,
              category: value,
              image,
              telephone,
              website,
              tripsID: city.id, // Ensure tripsID is passed here
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          if (errors.category?.hasError) {
            runValidationTasks("category", value);
          }
          setCategory(value);
        }}
        onBlur={() => runValidationTasks("category", category)}
        errorMessage={errors.category?.errorMessage}
        hasError={errors.category?.hasError}
        {...getOverrideProps(overrides, "category")}
      ></TextField>

<DropZoneInputExample onFilesChange={handleFilesChange} />

<TextField
  label="Website"
  isRequired={false}
  isReadOnly={false}
  value={website}
  onChange={(e) => {
    let { value } = e.target;
    if (onChange) {
      const modelFields = {
        name,
        address,
        latitude,
        longitude,
        arrivaldate,
        departuredate,
        confirmation,
        phone,
        time,
        category,
        image,
        telephone,
        website: value,
        tripsID: city.id, // Ensure tripsID is passed here
      };
      const result = onChange(modelFields);
      value = result?.website ?? value;
    }
    if (errors.website?.hasError) {
      runValidationTasks("website", value);
    }
    setWebsite(value);
  }}
  onBlur={() => runValidationTasks("website", website)}
  errorMessage={errors.website?.errorMessage}
  hasError={errors.website?.hasError}
  {...getOverrideProps(overrides, "website")}
></TextField>

<Flex
  justifyContent="space-between"
  {...getOverrideProps(overrides, "CTAFlex")}
>
  <Button
    children="Clear"
    type="reset"
    onClick={(event) => {
      event.preventDefault();
      resetStateValues();
    }}
    {...getOverrideProps(overrides, "ClearButton")}
  ></Button>
  <Flex
    gap="15px"
    {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
  >
    <Button
      children="Submit"
      type="submit"
      variation="primary"
      isDisabled={Object.values(errors).some((e) => e?.hasError)}
      {...getOverrideProps(overrides, "SubmitButton")}
    ></Button>
  </Flex>
</Flex>
</Grid>
);
}