import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Places from "../../../assets/places.png";
import buildingIcon from "../../../assets/building.svg";
import dinerIcon from "../../../assets/dinerIcon.svg";
import travelIcon from "../../../assets/arrival.svg";
import activityIcon from "../../../assets/activityicon.svg";
import trainIcon from "../../../assets/trainicon.svg";
import busIcon from "../../../assets/busicon.svg";
import carIcon from "../../../assets/caricon.svg";
import InvitePopup from "./tripinvite";
import Places1 from "../../../assets/Places.jpg"
import UserTripsManagement from "./places";
import { Usertrips } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";


const Main = ({ city, todayItineraryItems, upcomingTravel, userTrips, filteredUserTrips, tripcities, nohoteldates, userEmail }) => {
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);
  const [isUserTripsManagementOpen, setIsUserTripsManagementOpen] = useState(false); 
  const [trips, setTrips] = useState([]);
 
  
 
const uniqueCountryNamesString = Array.from(new Set(filteredUserTrips.map(city => city.countryName))).join(', ');
const uniquecurrenyString = Array.from(new Set(filteredUserTrips.map(city => city.currencyName))).join(', ');
const uniquelanguageString = Array.from(new Set(filteredUserTrips.map(city => city.officialLanguage))).join(', ');
const uniquephoneString = Array.from(new Set(filteredUserTrips.map(city => city.countryCallingCode))).join(', ');

const fetchData = async () => {
  try {
    const usertrips = await DataStore.query(Usertrips, (c) => c.and( c => [
      c.tripsID.eq(city.id),
      c.user_email.eq(userEmail)
    ]));
    setTrips(usertrips);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  fetchData();
}, [userEmail]);


const openInvitePopup = () => {
  setIsInvitePopupOpen(true);
};

// Function to close the invite popup
const closeInvitePopup = () => {
  setIsInvitePopupOpen(false);
};

const openCityPopup = () => {
  setIsUserTripsManagementOpen(true);
};

// Function to close the invite popup
const closeCityPopup = () => {
  setIsUserTripsManagementOpen(false);
  fetchData(); // Rerun fetchData when closing the city popup
};

 const getCategoryIcon = (item) => {
  if (item.category) {
    switch (item.category) {
      case 'Food':
        return dinerIcon;
      case 'Activity':
        return activityIcon;
      case 'Travel':
        return travelIcon;
      default:
        return buildingIcon;
    }
  }

  return buildingIcon; // Default to buildingIcon if no category found
};

const getTravelIcon = (type) => {
  switch (type) {
    case 'Airplane':
      return travelIcon; 
    case 'Train':
      return trainIcon; 
    case 'Bus':
      return busIcon; 
     case 'Car':
      return carIcon;   

    default:
      return travelIcon; // Replace with a default icon for unknown types
  }
};

const formatTime = (time) => {
  if (!time) return '';

  const [hours, minutes] = time.split(':').map(Number);

  if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    // Invalid time format
    return '';
  }

  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${period}`;
};

const UTCnohotel = new Date(nohoteldates + "Z");


  
  return (
    <div className="px-3 md:px-8 mt-10">
      {/* City Details  */}
      <div className="border-b border-light_green pb-4 pt-6">
      <div className="flex gap-5 pb-5 items-start">
          <div className="flex w-full lg:w-3/5 gap-6">
            <div className="w-200 h-200 rounded-md overflow-hidden">
              <img src={city.coverimage} alt={city.tripname} className="w-full h-full object-cover" />
            </div>
            <div className="flex flex-col justify-between gap-3 min-w-0">
              <div>
                <h2 className="text-heading_blue text-2xl font-semibold">
                  {city.tripname}
                </h2>
                <p className="text-blue_3 text-sm font-medium">
                  {city.subheader1}
                </p>
              </div>
              <div className="flex gap-7">
                <ul className="min-w-0">
                  <li className="text-heading_blue text-base font-semibold">
                    From:
                  </li>
                  <li className="text-blue_3 text-xs font-medium">
                    {new Date(city.datestart).toLocaleDateString('en-US', {
                      weekday: 'long',
                      timeZone: 'UTC'
                    })}
                  </li>
                  <li className="text-blue_3 text-xs font-medium">
                    {new Date(city.datestart).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      timeZone: 'UTC'
                    })}
                  </li>
                </ul>
                <ul className="min-w-0">
                  <li className="text-heading_blue text-base font-semibold">
                    To:
                  </li>
                  <li className="text-blue_3 text-xs font-medium">
                    {new Date(city.dateend).toLocaleDateString('en-US', {
                      weekday: 'long',
                      timeZone: 'UTC'
                    })}
                  </li>
                  <li className="text-blue_3 text-xs font-medium">
                    {new Date(city.dateend).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      timeZone: 'UTC'
                    })}
                  </li>
                </ul>
              </div>
            </div>
          </div>

  <div className="flex flex-col w-full lg:w-2/5 gap-2 lg:flex lg:items-start">
    <h2 className="text-heading_blue text-base font-semibold mb-3">
      General Info
    </h2>
    <div className="grid grid-cols-2 gap-x-5 gap-y-2">
      <div className="text-blue_3 text-sm">Country:</div>
      <div className="text-black text-sm font-medium break-words whitespace-normal">
        {uniqueCountryNamesString}
      </div>

      <div className="text-blue_3 text-sm">City:</div>
      <div className="text-black text-sm font-medium break-words whitespace-normal">
        {tripcities.map((city) => city.city).join(', ')}
      </div>

      <div className="text-blue_3 text-sm">Language:</div>
      <div className="text-black text-sm font-medium break-words whitespace-normal">
        {uniquelanguageString}
      </div>

      <div className="text-blue_3 text-sm">Currency:</div>
      <div className="text-black text-sm font-medium break-words whitespace-normal">
        {uniquecurrenyString}
      </div>

      <div className="text-blue_3 text-sm">Phone Code:</div>
      <div className="text-black text-sm font-medium break-words whitespace-normal">
        {uniquephoneString}
      </div>
    </div>
  
  </div>
</div>





      </div>

      {/* Who’s Coming Container  */}
      <div className="border-b border-light_green pb-4 pt-6">
  <h1 className="text-heading_blue font-semibold uppercase text-base py-4">
    Who’s Coming
  </h1>
  <div className="grid grid-cols-1 custom:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-10 py-5">
    {userTrips.map((trip, index) => (
      <div key={index} className="flex items-center gap-3">
        <img
          src={trip.userimage}
          alt={`User ${index + 1}`}
          className="rounded-full w-18 h-18 object-cover"
        />
        <div className="flex flex-col gap-1">
          <h2 className="text-heading_blue text-base font-semibold">
            {trip.name}
          </h2>
          <p className="text-blue_3 text-xs">+123 567 890</p>
        </div>
      </div>
    ))}
    {/* Button to open InvitePopup component */}
    <div className="flex items-center gap-3 justify-end">
          <button onClick={openInvitePopup} className="bg-black text-white rounded-full px-4 py-2">Invite Someone</button>
        </div>
  </div>
</div>

{/* InvitePopup component */}
{isInvitePopupOpen && (
  <InvitePopup
    onClose={closeInvitePopup}
    onSendInvite={(email, city) => {}}
    city={city}
  />
)}

      {/* Places  */}
      <div className="border-b border-light_green pb-4 pt-6">
        <h1 className="text-heading_blue font-semibold uppercase text-base py-4">
          Places
        </h1>
        <div className="flex gap-5 items-center justify-start mb-4 overflow-x-hidden w-full">
          {trips.map((city) => (
            <div
              className="flex flex-col items-center gap-1 justify-center"
              key={city.id}
            >
                {city.locationimage ? (
                <img
                  src={city.locationimage}
                  alt={`City: ${city.city}`}
                  className="min-w-24 h-24 rounded-xl"
                />
              ) : (
                <img
                  src={Places1}
                  alt="Food Box"
                  className="min-w-24 h-24 rounded-xl"
                />
              )}
                <p className="text-sm font-semibold text-heading_blue">
                {city.city}
              </p>
            </div>
          ))}
        </div>

        <Link to="#" className="text-blue_4 capitalize underline text-base">
          See more
        </Link>

        <button
            onClick={() => openCityPopup(true)} // Open UserTripsManagement when clicked
            className="ml-10 text-blue_4 capitalize underline text-base"
          >
            Edit Places
          </button>
      </div>


      {isUserTripsManagementOpen && (
        <UserTripsManagement
          userEmail={userEmail} // Pass necessary props to UserTripsManagement
          city={city}
          onClose={closeCityPopup}
        />
      )}
      {/* Today  */}
      <div className="border-b border-light_green pb-4 pt-6">
        <h1 className="text-heading_blue font-semibold uppercase text-base py-4">
          Today
        </h1>

      <div className="flex flex-wrap gap-10 py-5">
      {todayItineraryItems.map((day, index) => (
        <React.Fragment key={index}>
          {day.activities.map((item, activityIndex) => (
            <div className="flex items-center flex-col justify-center gap-3" key={activityIndex}>
              <div className="rounded-full w-14 h-14 bg-light_green bg-opacity-10 flex justify-center items-center">
                <img src={getCategoryIcon(item)} alt={item.name} className="w-5 h-6" />

              </div>
              <div className="flex flex-col justify-center items-center gap-1">
                <h2 className="text-heading_blue text-sm font-semibold">{item.name}</h2>
                <p className="text-blue_3 text-xs">Time: {formatTime(item.time) || item.startdate}</p>
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
      </div>

      {/* Upcoming Tavel */}
      <div className="border-b border-light_green pb-4 pt-6">
        <h1 className="text-heading_blue font-semibold uppercase text-base py-4">
          Upcoming Tavel
        </h1>
        <div className="flex flex-wrap gap-10 py-5">
          {upcomingTravel.map((travelItem, index) => (
            <div key={index} className="flex items-center flex-col justify-center gap-3">
              <div className="rounded-full w-14 h-14 bg-light_green bg-opacity-10 flex justify-center items-center">
                <img
                  src={getTravelIcon(travelItem.type)}
                  alt={`Upcoming Travel ${index}`}
                  className="w-5 h-6"
                />
              </div>
              <div className="flex flex-col justify-center items-center gap-1">
                <h2 className="text-heading_blue text-sm font-semibold">
                  {travelItem.origin} to {travelItem.destination}
                </h2>
                <p className="text-blue_3 text-xs">Time: {formatTime(travelItem.time) || travelItem.startdate}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* To dos */}
      <div className="border-b border-light_green pb-4 pt-6">
        <h1 className="text-heading_blue font-semibold uppercase text-base py-4">
          To do's
        </h1>
        <div className="flex flex-wrap gap-10 py-5">
          {nohoteldates.map((date, index) => (
            <React.Fragment key={index}>
              <div className="flex items-center flex-col justify-center gap-3">
                <div className="rounded-full w-14 h-14 bg-light_green bg-opacity-10 flex justify-center items-center">
                <img src={buildingIcon} alt={date} className="w-5 h-6" />
                </div>
                <div className="flex flex-col justify-center items-center gap-1">
                  <h2 className="text-heading_blue text-sm font-semibold">Book Hotel for</h2>
                  <p className="text-blue_3 text-xs">
                  {new Date(date).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    timeZone: 'UTC'
                  })}
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>

      </div>

      {/* Where to Stay */}
      <div className="border-b border-light_green pb-4 pt-6">
        <h1 className="text-heading_blue font-semibold uppercase text-base py-4">
          Recommendations
        </h1>
        <div className="flex justify-between items-center flex-wrap gap-10 py-3">
          <div className="flex gap-4 max-w-96">
            <div className="w-24 h-24 overflow-hidden rounded-xl">
              <img src={Places} alt="Hotal name" className="w-full h-full" />
            </div>
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="text-heading_blue font-semibold text-xl">
                  Hotel California
                </h2>
                <Link to="#" className="text-sm text-blue_4 underline">
                  Get Direction
                </Link>
              </div>
              <p className="text-blue_3 text-sm font-semibold">
                Check in: <span className="text-light_green">10:00 AM</span>
              </p>
            </div>
          </div>
          <div className="flex gap-8">
            <ul className="flex flex-col gap-1">
              <li className="text-blue_3 text-sm">Arrival:</li>
              <li className="text-blue_3 text-sm">Departure:</li>
              <li className="text-blue_3 text-sm">Confirmation:</li>
            </ul>
            <ul className="flex flex-col gap-1">
              <li className="text-sm font-medium">Tue, Aug 22 2023</li>
              <li className="text-sm font-medium">Sun, Dec 24 2023</li>
              <li className="text-sm font-medium">3456</li>
            </ul>
          </div>
          <div>
            <div className="flex items-start gap-4">
              <h4 className="text-blue_3 text-sm">Location:</h4>
              <h4 className="text-sm text-black font-medium max-w-[198px] w-full">
                2201 SW Genesee St. Seattle WA 98106
              </h4>
            </div>
            <div className="flex items-center gap-8">
              <h4 className="text-blue_3 text-sm">Phone</h4>
              <h4 className="text-sm text-black font-medium">+12067624513</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
