import React, { useState, useEffect } from "react";
import TripBg from "../../../assets/tripBackground.png";
import lunchIcon from "../../../assets/dinerIcon.svg";
import breakfastIcon from "../../../assets/cafeIcon.svg";
import dinerIcon from "../../../assets/Corasiny.svg";
import snackIcon from "../../../assets/Corasiny.svg";
import buildingIcon from "../../../assets/building.svg";
import travelIcon from "../../../assets/arrival.svg";
import activityIcon from "../../../assets/activityicon.svg";
import trainIcon from "../../../assets/trainicon.svg";
import busIcon from "../../../assets/busicon.svg";
import carIcon from "../../../assets/caricon.svg";
import { DataStore } from "@aws-amplify/datastore";
import { Useritins, Tripcities } from "../../../models";
import DropdownComponent from "./newactiitybutton";
import EditItineary from "./editactivity";
import DeleteItin from "./delete";
import IntinInvitePopup from "./itininvite";
import { Link } from "react-router-dom";
import ActivityRenderer from "./boxes";
import FullInvitePopup from "./fullitininvite";
import scheduleData from "../../../data.json";
import NewTripCityRecord from "./city";

const Itinerary = ({ allDates, sortedData, city, accomodationbynight, userTrips, userEmail }) => {
  const [schedule, setSchedule] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [fulllist, setFullList] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editActivityCategory, setEditActivityCategory] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedcategory, setSelectedcategory] = useState(null);
  const [selectedtripsID, setSelectedtripsID] = useState(null);
  const [isFullInvitePopupOpen, setIsFullInvitePopupOpen] = useState(false);
  const [isNewTripCityRecordOpen, setIsNewTripCityRecordOpen] = useState(false);
  const [cityday, setcityday] = useState(null);
  const [citytripsID, setcitytripsID] = useState(null);
  const [daycities, setdaycities] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false); 


  useEffect(() => {
    setSchedule(scheduleData.daysData);
  }, []);



  useEffect(() => {
    const handleClickOutside = () => {
      setOpenDropdown(null);
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (eventIndex) => {
    setOpenDropdown((prevIndex) =>
      prevIndex === eventIndex ? null : eventIndex
    );
  };
 console.log("alldates",allDates);

  const openInvitePopup = (eventId, eventCategory, eventtripsID) => {
    setSelectedEventId(eventId);
    setSelectedcategory(eventCategory);
    setSelectedtripsID(eventtripsID);
    setIsInvitePopupOpen(true);
  };

  const closeInvitePopup = () => {
    setIsInvitePopupOpen(false);
  };

  const openFullInvitePopup = () => {
    setIsFullInvitePopupOpen(true);
  };
  
  const closeFullInvitePopup = () => {
    setIsFullInvitePopupOpen(false);
  };
  
  const closeCityPopup = () => {
    setIsNewTripCityRecordOpen(false);
  };
console.log('info',sortedData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userTrips) return;
        const useritins = await DataStore.query(Useritins, (c) => c.tripsID.eq(city.id));

        const updatedFullList = useritins.map((item) => {
          const matchingUser = userTrips.find((userTrip) => userTrip.email === item.userid);
          return {
            ...item,
            username: matchingUser ? matchingUser.name : 'Unknown',
            userimage: matchingUser ? matchingUser.userimage : 'Unknown',
          };
        });

        const daycities = await DataStore.query(Tripcities, (c) => c.tripsID.eq(city.id));

        setdaycities(daycities);
       
        setFullList(updatedFullList);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userTrips, city.id, rerenderFlag]);

  const formatTimeToAmPm = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const formattedTime = new Date(0, 0, 0, hours, minutes);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getMealIcon = (type) => {
    switch (type) {
      case "Breakfast":
        return <img src={breakfastIcon} alt="Breakfast Icon" />;
      case "Lunch":
        return <img src={lunchIcon} alt="Lunch Icon" />;
      case "Dinner":
        return <img src={dinerIcon} alt="Dinner Icon" />;
      case "Snack":
        return <img src={snackIcon} alt="Snack Icon" />;
      default:
        return null;
    }
  };

  const icondefine = (category, type) => {
    switch (category) {
      case "Food":
        switch (type) {
          case "Breakfast":
            return breakfastIcon;
          case "Lunch":
            return lunchIcon;
          case "Dinner":
            return dinerIcon;
          case "Snack":
            return snackIcon;
          default:
            return lunchIcon;
        }

      case "Travel":
        switch (type) {
          case "Airplane":
            return travelIcon;
          case "Train":
            return trainIcon;
          case "Bus":
            return busIcon;
          case "Car":
            return carIcon;
          default:
            return travelIcon;
        }

      case "Activity":
        return activityIcon;

      default:
        return buildingIcon;
    }
  };

  const openEditItinerary = (event) => {
    setEditActivityCategory(event);
    setIsEditOpen(true);
  };

  const handleEditSuccess = () => {
    setReloadFlag(!reloadFlag);
  };

  const openDeleteModal = (event) => {
    setEventToDelete(event);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setEventToDelete(null);
    setDeleteModalOpen(false);
  };

  const openNewTripCityRecord = (day, city) => {
    
    setcityday(day);
    setIsNewTripCityRecordOpen(true);
    setcitytripsID(city);
  };

  const handleComponentClose = () => {
    setRerenderFlag(prev => !prev);
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-1/5 py-5 lg:py-10">
        <h2 className="text-sm font-bold tracking-1 uppercase text-light_green text-center mb-3">
          your Itinerary
        </h2>

        <div>
          <ul className={`border-b border-gray_1 px-3 py-2 mb-2`} onClick={() => setSelectedDay(null)} style={{ cursor: 'pointer' }}>
            <li className={`text-heading_blue text-sm uppercase tracking-1 ${selectedDay === null ? "font-bold" : ""}`}>
              All Itinerary
            </li>
          </ul>

          {allDates.map((date, index) => (
            <ul className={`border-b border-gray_1 px-3 py-2 ${index === allDates.length - 1 ? "" : "mb-2"}`} key={index} style={{ cursor: 'pointer' }}>
              <li className={`text-blue_3 text-sm capitalize ${selectedDay === date ? "font-bold" : ""}`} onClick={() => setSelectedDay(date)}>
                {new Date(date).toLocaleDateString("en-US", { month: "short", day: "numeric", timeZone: "UTC" })}
              </li>
              <li className={`text-heading_blue text-sm uppercase tracking-1 ${selectedDay === date ? "font-bold" : ""}`} onClick={() => setSelectedDay(date)}>
                {new Date(date).toLocaleDateString("en-US", { weekday: "long", timeZone: "UTC" })}
              </li>
            </ul>
          ))}
        </div>
      </div>

      <div className="border-l border-gray_1 w-full lg:w-4/5 py-5 pr-3 md:pr-6 z-0">
        <div className="pl-3 md:pl-6">
          <div className="relative w-full h-48 md:h-56">
            <img src={TripBg} alt="Trip Background" className="w-full h-full object-fill" />
            <div className="absolute bottom-4 flex justify-between w-full px-3 md:px-6 text-white uppercase font-bold text-sm flex-wrap gap-1">
              <h2 style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                {city.tripname}
              </h2>
              <h2 style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                {`${new Date(city.datestart).toLocaleDateString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' })} - ${new Date(city.dateend).toLocaleDateString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' })}`}
              </h2>
            </div>
            <div className="absolute top-0 right-0 mt-2 mr-2">
            <button  onClick={openFullInvitePopup} className="text-black px-4 py-1 bg-white rounded-md hover:bg-blue_2 hover:text-white focus:outline-none ">
              Share all itinerary
            </button>
          </div>
          </div>
        </div>

        <div>
          {allDates &&
            allDates.map((day, index) => (
              <React.Fragment key={index}>
                {(selectedDay === null || selectedDay === day) && (
                  <div key={index} className="py-4 border-t-2 border-light_green mt-8 relative">
                    <div className="absolute -top-3 left-16 bg-white px-2 flex items-center gap-2">
                      <h3 className="text-sm font-bold text-heading_blue uppercase">
                        {new Date(day).toLocaleDateString("en-US", { weekday: "long", timeZone: "UTC" })}
                      </h3>
                      <span className="text-blue_3 text-sm capitalize">
                        {new Date(day).toLocaleDateString("en-US", { month: "short", day: "numeric", timeZone: "UTC" })}
                      </span>
                    </div>

                    <div className="pl-3 md:pl-6">
                      <div className="flex flex-wrap justify-between lgmeduim:justify-around gap-4 border border-dashed py-6 px-4 lgmeduim:px-2 border-light_green rounded-md bg-blue_5 my-4">
                      <div>
                        <div className="flex flex-row">
                          <h2 className="text-base font-semibold tracking-1 uppercase text-heading_blue mb-4">
                            Day in:
                          </h2>
                          <div className="ml-5">
                          <button onClick={() => openNewTripCityRecord(day, city)} className="text-gray_4">
                            Set
                          </button>
                        </div>

                          </div>
                          <div className="flex items-center gap-3">
                          {daycities &&
                            daycities.map((dayCity, index) => {
                              if (dayCity.date === day) {
                                return (
                                  <div key={index}>
                                   
                                    <p>{dayCity.city}</p>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>

                        <div>
                          <h2 className="text-base font-semibold tracking-1 uppercase text-heading_blue mb-4">
                            Staying at:
                          </h2>
                          <div className="flex items-center gap-3">
                            {accomodationbynight.map(accommodation => {
                              if (accommodation.date === day) {
                                return (
                                  <React.Fragment key={accommodation.id}>
                                    <img src={accommodation.image} alt={`Image for ${accommodation.name}`} className="w-15 h-15 rounded-lg" />
                                    <h3 className="text-heading_blue text-sm font-semibold capitalize">
                                      {accommodation.name}
                                    </h3>
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })}
                            {!accomodationbynight.some(accommodation => accommodation.date === day) && (
                              <h3 className="text-heading_blue text-sm font-semibold capitalize">
                                You have no place to stay tonight
                              </h3>
                            )}
                          </div>
                        </div>

                        <div>
                          <h2 className="text-base font-semibold tracking-1 uppercase text-heading_blue mb-4">
                            Meals:
                          </h2>
                          <ul className="flex flex-col gap-3">
                            {sortedData.map(sortedDay => {
                              if (sortedDay.date === day) {
                                return (
                                  <ul key={sortedDay.date} className="flex flex-col gap-3">
                                    {sortedDay.activities.filter(activity => activity.category === "Food").map((meal, mealIndex) => (
                                      <li key={mealIndex} className="flex items-center gap-3">
                                        {getMealIcon(meal.type)}
                                        <span className="text-heading_blue text-sm font-semibold capitalize">
                                          {meal.name}
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                );
                              }
                              return null;
                            })}
                          </ul>
                        </div>

                        <div>
                          <h2 className="text-base font-semibold tracking-1 uppercase text-heading_blue mb-4">
                            Free Time:
                          </h2>
                          {day.freetime && day.freetime.length > 0 && (
                            <ul className="flex flex-col gap-3">
                              {day.freetime.map((freetime, freetimeIndex) => (
                                <li key={freetimeIndex} className="flex items-center gap-3">
                                  <img src={freetime.image} alt={`${freetime.name} Icon`} />
                                  <span className="text-heading_blue text-sm font-semibold capitalize">
                                    {freetime.hours}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="pl-3 md:pl-6">
                      <DropdownComponent city={city} userEmail={userEmail} />
                    </div>

                    {sortedData.map(sortedDay => {
                      if (sortedDay.date === day) {
                        return (
                          <div key={sortedDay.date}>
                            {sortedDay.activities.map((event, eventIndex) => (
                              <div className="py-4 border-t border-gray_1 mt-8 relative" key={eventIndex}>
                                <div className="absolute -top-3 left-0 bg-white px-4">
                                  <h3 className="text-sm font-semibold text-heading_blue uppercase">
                                    {formatTimeToAmPm(event.time)}
                                  </h3>
                                </div>

                                <div className="pl-3 md:pl-6 mt-3 mb-8">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-3">
                                      <img src={icondefine(event.category, event.type)} alt={`${event.category} Icon`} className="w-8 h-9" />
                                      <h1 className="text-xl font-medium capitalize">{event.name}</h1>
                                    </div>
                                    <button onClick={() => openEditItinerary(event)} className="text-gray-500 hover:text-gray-700 focus:outline-none">Edit</button>
                                  </div>

                                  <div className="flex flex-col lgmeduim:flex-row justify-between gap-5 mt-6 w-full md:w-11/12 m-auto">
                                    <ActivityRenderer event={event} />
                                    <div className="w-full lgmeduim:w-2/6">
                                    <div className="flex flex-row w-98">
                                      <h2 className="text-base font-semibold text-heading_blue mb-1 mr-8">Who’s Coming</h2>
                                     
                                      </div>
                                      <div className="flex h-20 justify-start items-end relative">
                                        {event.id && (
                                          <>
                                            {fulllist.filter(user => user.elementid === event.id).slice(0, 5).map((user, index) => (
                                              <div key={index} className="flex flex-col items-center">
                                                <img src={user.userimage} alt={user.username} className="w-10 h-10 rounded-full mt-10 ml-5 object-cover" />
                                                <p className="text-xs ml-5 mt-2">{user.username}</p>
                                              </div>
                                            ))}
                                            {fulllist.filter(user => user.elementid === event.id).length > 5 && (
                                              <Link to="#" className="text-blue_4 underline text-sm font-medium absolute left-28" onClick={(e) => { e.stopPropagation(); toggleDropdown(eventIndex); }}>
                                                And {fulllist.filter(user => user.elementid === event.id).length - 5} more...
                                              </Link>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                               
                                </div>
                                <button className="ml-40 mb-1 text-green-500 hover:text-green-700 focus:outline-none" onClick={() => openInvitePopup(event.id, event.category, event.tripsID)}>Invite</button>
                                <button onClick={() => openDeleteModal(event)} className="absolute bottom-5 right-5 text-red-500 hover:text-red-700 focus:outline-none">Delete</button>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>

        {isInvitePopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-transparent z-50">
          <IntinInvitePopup 
            eventId={selectedEventId} 
            eventCategory={selectedcategory} 
            eventtripsID={selectedtripsID} 
            onClose={closeInvitePopup}
            onSendInvite={(email, city) => {}} />
          </div>
          )}

          {isFullInvitePopupOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-transparent z-50">
              <FullInvitePopup 
              sortedData={sortedData} 
              tripsID={city.id}
              onClose={closeFullInvitePopup} />
            </div>
          )}

{isNewTripCityRecordOpen && (
  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-transparent z-50">
    <NewTripCityRecord
      currentUserEmail={userEmail}
      date={cityday}
      cityID={citytripsID} 
      onClose={() => {
        closeCityPopup(); // Close the city popup
        handleComponentClose(); // Fetch data again
      }}
    />
  </div>
)}

        {deleteModalOpen && eventToDelete && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-transparent z-50">
          <DeleteItin isOpen={deleteModalOpen} onClose={closeDeleteModal} category={eventToDelete} onDelete={() => console.log("Deleted")} />
      </div>
        )}

        <EditItineary
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          category={editActivityCategory}
          onEditSuccess={handleEditSuccess}
        />
      </div>
    </div>
  );
};

export default Itinerary;
