import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage.js/LandingPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import CityDetails from "./pages/CityDetails/CityDetails";
import LoginScreen from "./pages/Login/login";
import UserProfileScreen from "./pages/Accountpage/accountscreen"
import Newtripscreen from "./pages/Newtrip/newtrip";
import Newtripcityscreen from "./pages/Newtrip/newtripcities";
import Completescreen from "./pages/Newtrip/donescreen";
import UpdatesAndInvitationsPage from "./pages/updatesinvites/updatesscreen";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/cityDetails" element={<CityDetails />} />
        <Route path= "/login" element={<LoginScreen />} />
        <Route path= "/account" element={<UserProfileScreen />} />
        <Route path= "/newtrip" element={<Newtripscreen />} />
        <Route path= "/newtripcity" element={<Newtripcityscreen />} />
        <Route path= "/done" element={<Completescreen />} />
        <Route path= "/updates" element={<UpdatesAndInvitationsPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
