import React from "react";
import Features from "../../components/Features/Features";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import { IoArrowForward } from "react-icons/io5";
import Gallery from "../../components/Gallery/Gallery";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Gallery />
      <Features />
      <div className="px-3 py-20">
        <div className="max-w-6xl mx-auto">
          <h1 className="max-w-808 text-45 md:text-45 font-bold capitalize leading-56 md:leading-68">
            Ready to turn your travel dreams into reality?
          </h1>
          <div className="flex flex-col md:flex-row justify-between md:items-center gap-4 py-6 mt-5">
            <p className="text-heading_blue capitalize font-semibold text-l md:text-20 max-w-832">
              Sign up for Andanza now and unlock a world of collaborative and
              personalized trip planning with integrated social media
              recommendations. Start your journey today!
            </p>
            <button className="py-15 w-60 rounded-10 bg-black text-white font-medium tracking-0.8 text-lg flex justify-center items-center gap-2 mt-7 md:mt-0">
              Download the App{" "}
              <IoArrowForward className="text-light_green w-6 h-6" />
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
