import React from "react";
import Logo from "../../assets/ANDANZA.svg";
import { FaTwitter, FaFacebookF, FaInstagram, FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="px-3 border-t border-gray_3 py-14">
      <div className="max-w-6xl mx-auto flex justify-between gap-3 flex-wrap">
        <div className="max-w-sm flex flex-col gap-8 md:mb-0 mb-7">
          <img src={Logo} alt="Logo" className="w-48" />
          <p className="text-heading_blue text-sm leading-22">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum aliquet accumsan porta lectus ridiculus in mattis. Netus
            sodales in volutpat ullamcorper amet adipiscing fermentum.
          </p>

          <ul className="flex items-center gap-4">
            <li>
              <FaTwitter className="w-3 md:w-5 h-5 cursor-pointer" />
            </li>
            <li>
              <FaFacebookF className="w-2 md:w-5 h-5 cursor-pointer" />
            </li>
            <li>
              <FaInstagram className="w-3 md:w-5 h-5 cursor-pointer" />
            </li>
            <li>
              <FaGithub className="w-3 md:w-5 h-5 cursor-pointer" />
            </li>
          </ul>
        </div>

        <div className="md:mb-0 mb-7">
          <h2 className="text-heading_blue text-base mb-5 font-bold">
            Company
          </h2>
          <ul className="flex flex-col gap-3">
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">About</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Features</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Works</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Career</Link>
            </li>
          </ul>
        </div>

        <div className="md:mb-0 mb-7">
          <h2 className="text-heading_blue text-base mb-5 font-bold">Help</h2>
          <ul className="flex flex-col gap-3">
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Customer Support</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Delivery Details</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Terms & Conditions</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Privacy Policy</Link>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-heading_blue text-base mb-5 font-bold">
            Resources
          </h2>
          <ul className="flex flex-col gap-3">
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Free eBooks</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Development Tutorial</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">How to - Blog</Link>
            </li>
            <li className="text-heading_blue text-sm font-normal md:font-semibold">
              <Link to="">Youtube Playlist</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
