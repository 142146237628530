import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Dashboard/DashbordLayout/DashboardLayout";
import CityDetails from "../CityDetails/CityDetails";
import { Usertrips, Trips } from '../../models';
import { getCurrentUser } from 'aws-amplify/auth';
import { DataStore  } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify/utils';



const Dashboard = () => {
  const [showBoxes, setShowBoxes] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [imageUrls, setImageUrls] = useState([]); 
  const [selectedCity, setSelectedCity] = useState(null); // State to store the selected city id
  const [emptyScreen, setEmptyScreen] = useState(false); // State to track if the screen is empty
  const [email_user, setEmail_user] = useState(null);

  const navigateToCityDetails = (city) => {
    setSelectedCity(city);
    setShowBoxes(false);
  };

  const refreshData = () => {
    setShowBoxes(true);
    setSelectedCity(  );
    setEmptyScreen(false);
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log('Fetching user data...');
        const { username, userId, signInDetails } = await getCurrentUser();
        console.log('User data fetched:', { username, userId, signInDetails });
        setUserInfo({ username, userId, signInDetails });
        setEmail_user(signInDetails.loginId);
       
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };

    fetchUserData();

    const listener = Hub.listen('datastore', (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      console.log('DataStore event:', event, data);
    });

    console.log('Hub listener set up.');

    // Cleanup the listener on component unmount
    return () => {
      console.log('Removing Hub listener.');
      listener();
    };
  }, []); // Run once when the component mounts

  useEffect(() => {

        const fetchImageUrls = async () => {
      try {
        if (userInfo && userInfo.signInDetails) {
          console.log('Fetching user trips...');
          const userTrips = await DataStore.query(Usertrips, c => c.user_email.eq(userInfo.signInDetails.loginId));
          console.log('User trips fetched:', userTrips);
          const tripsIds = userTrips.map((userTrip) => userTrip.tripsID);
          const firstTrips = await DataStore.query(Trips);
          const trips = firstTrips.filter(firsttrip => tripsIds.includes(firsttrip.id));

          trips.sort((a, b) => new Date(a.datestart) - new Date(b.datestart));

          setImageUrls(trips);
          setEmptyScreen(trips.length === 0); // Check if screen is empty after fetching trips
          console.log(userTrips)
        }
      } catch (error) {
        console.log('Error fetching image URLs', error);
      }
    };

    fetchImageUrls();
  }, [userInfo]);

  return (
    <DashboardLayout className="">
      {emptyScreen ? (
        <div className="flex justify-center items-center h-full">
          <button onClick={refreshData} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Refresh
          </button>
          <h2>or create a new trip</h2>
        </div>
      ) : showBoxes ? (
        <div className="grid grid-cols-2 lg:grid-cols-4  gap-3 pl-3 pr-3">
          {imageUrls.map((city) => (
        
        <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70 cursor-pointer"
     onClick={() => navigateToCityDetails(city)}
     key={city.id}
>
        <div className="w-200 h-200 overflow-hidden rounded-t-xl">
          <img className="w-full h-full object-cover" 
               src={city.coverimage}
               alt={city.tripname}
          />
        </div>
        <div className="p-4 md:p-5">
          <h3 className="text-lg font-bold text-gray-800 dark:text-white">
            {city.tripname}
          </h3>
          <p className="mt-1 text-gray-500 dark:text-neutral-400">
            {city.datestart}
          </p>
        </div>
      </div>
      
          ))}
        </div>
      ) : (
        <CityDetails
          city={selectedCity}
          id={selectedCity?.id}
          coverimage={selectedCity?.coverimage}
          subheader1={selectedCity?.subheader1}
          subheader2={selectedCity?.subheader2}
          tripname={selectedCity?.tripname}
          datestart={selectedCity?.datestart}
          dateend={selectedCity?.dateend}
          sortedData={selectedCity?.sortedData}
          travelinfo={selectedCity?.travelinfo}
          email_user={email_user}
        />
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
