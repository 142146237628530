import React, { useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import config from '../../amplifyconfiguration.json';
import { getCurrentUser } from 'aws-amplify/auth';

import { Amplify } from 'aws-amplify';
Amplify.configure(config);

const LoginScreen = ({ signOut, user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        // Check if the user is authenticated
        await getCurrentUser();
        // If successful, navigate to /dashboard
        navigate('/dashboard');
      } catch (error) {
        // If not authenticated, the user will remain on the login screen
        console.log('User not authenticated:', error);
      }
    };

    // Call the checkAuthState function when the component mounts
    checkAuthState();
  }, [navigate]);

  // If user is already signed in, render nothing
  if (user && user.signInUserSession) {
    return null;
  }

  // If user is not signed in, render the login screen
  return (
    <>
      <h1>Hello {user?.username}</h1>
      <button onClick={signOut}>Sign out</button>
    </>
  );
};

export default withAuthenticator(LoginScreen);
