import React, { useState, useEffect } from "react";
import { DataStore } from '@aws-amplify/datastore';
import { Favoriteitems } from '../../../models';
import ProductModal from './favoritesmodal';
import LinkPreviewScreen from "./newfav";

const Favorites = ({ city }) => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [products, setItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false); // New state to control the visibility of the new modal

  useEffect(() => {
    async function fetchFavoriteItems() {
      try {
        const fetchedItems = await DataStore.query(Favoriteitems, c => c.tripsID.eq(city.id));
        setItems(fetchedItems);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchFavoriteItems();
  }, [city]); // Update the data whenever the city changes

  const categories = [
    "All",
    "Food",
    "Other",
    "Museums",
    "Places",
    "Monuments",
    "Hotels",
    "What to do",
  ];

  const groupedProducts =
    activeCategory === "All"
      ? products.reduce((acc, product) => {
          acc[product.list] = acc[product.list] || [];
          acc[product.list].push(product);
          return acc;
        }, {})
      : {
          [activeCategory]: products.filter(
            (product) => product.list === activeCategory
          ),
        };

  const openModal = (description) => {
    setSelectedProduct(description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  const handleAddNew = () => {
  setIsNewModalOpen(true); // Set state to open the new modal
  console.log("isNewModalOpen:", isNewModalOpen); // Log the value of isNewModalOpen
};

  return (
    <div className="px-3 md:px-8 mt-10">
      {/* Search Input  */}
      <div className="flex items-center justify-between">
        <input
          type="search"
          placeholder="Search"
          className="h-11 w-4/5 md:w-1/3 bg-input_bg rounded-sm outline-none px-4 text-xl placeholder:text-black tracking-0.8"
        />
        {/* Add New Button */}
        <button
          onClick={handleAddNew}
          className="bg-blue_2 hover:bg-black text-white font-bold py-2 px-4 rounded"
        >
          Add New
        </button>
      </div>

      {/* Show all categories buttons */}
      <div className="my-6 flex items-center flex-wrap gap-2 custom:gap-3">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`px-2 custom:px-6 py-2 border rounded-md capitalize text-heading_blue text-sm custom:text-base font-semibold ${
              activeCategory === category ? "bg-activebtnbg font-bold" : ""
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Show products for the active category */}
      <div>
        {Object.entries(groupedProducts).map(([category, categoryProducts]) => (
          <div key={category}>
            <h2 className="font-semibold text-heading_blue text-base tracking-1 my-7 uppercase">
              {category}
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
              {categoryProducts.map((product) => (
                <div
                  key={product.id}
                  className="flex gap-3 flex-col custom:flex-row w-full"
                >
                  <div className="w-full custom:w-48 h-38 rounded-md overflow-hidden">
                    <a
                      href={product.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={product.image}
                        alt={product.category}
                        className="w-full object-cover h-full cursor-pointer"
                      />
                    </a>
                  </div>
                  <div className="flex flex-col justify-between gap-3">
                    <div className="flex justify-between items-center">
                      <h3 className="text-base font-bold text-light_green">
                        {product.name}
                      </h3>
                      <span className="text-xs font-semibold text-blue_3">
                        {product.listtrip}
                      </span>
                    </div>
                    <p className="text-sm font-semibold text-heading_blue">
                      {product.description.length > 150 ? (
                        <>
                          {product.description.slice(0, 150)}{' '}
                          <span
                            className="text-light_green cursor-pointer"
                            onClick={() => openModal(product.description)}
                          >
                            More
                          </span>
                        </>
                      ) : (
                        product.description
                      )}
                    </p>

                    <p className="text-base font-semibold text-blue_3">
                      Platform:
                      <span className="text-light_green ml-1">
                        {product.platform}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Render ProductModal */}
      <ProductModal
        visible={isModalOpen}
        description={selectedProduct}
        onClose={closeModal}
      />

      {/* Render LinkPreviewScreen when isNewModalOpen is true */}
      {isNewModalOpen && (
        <LinkPreviewScreen item={city} onClose={() => setIsNewModalOpen(false)} />
      )}
    </div>
  );
};

export default Favorites;
