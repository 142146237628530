import React, { useState } from "react";
import axios from "axios";
import * as cheerio from 'cheerio';

const LinkPreviewScreen = ({ onClose }) => {
  const [url, setUrl] = useState('');
  const [previewData, setPreviewData] = useState(null);
  const [favoriteName, setFavoriteName] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const fetchLinkPreviewData = async () => {
    try {
      const response = await axios.get(url);
      const html = response.data;
      const $ = cheerio.load(html);

      // Extract data from the webpage using Cheerio selectors
      const description = $('meta[name="description"]').attr('content');
      const firstImage = $('img').first().attr('src');

      // Set preview data
      setPreviewData({
        description,
        firstImage
      });
    } catch (error) {
      console.error('Error fetching link preview data:', error);
    }
  };

  const handleFetchLinkPreview = async () => {
    try {
      await fetchLinkPreviewData();
    } catch (error) {
      console.error('Error fetching link preview data:', error);
    }
  };

  const handleSaveToDatabase = async () => {
    try {
      // Perform save to database operation
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error saving to database:', error);
    }
  };

  const handleSuccessModalOK = () => {
    setShowSuccessModal(false);
  };

  const handleCategorySelection = (selectedCategory) => {
    // Handle category selection logic
  };

  return (
    <div className="fixed top-0 left-40 w-full h-full flex justify-center items-center z-50 overflow-y-auto">
      <div className="absolute bg-white rounded w-60 h-70 overflow-y-auto shadow-lg">
        <div className="flex justify-between items-center px-4 py-2 border-b">
          <button className="absolute top-5 right-5 px-4 py-2 bg-gray-200 text-gray-800 rounded-md shadow-sm hover:bg-gray-300" onClick={onClose}>Close</button>
          <h1 className="text-3xl font-bold mb-4">This is a simple screen</h1>
        </div>
        <p className="text-lg">With a white background</p>
        <label>Enter a URL:</label>
        <input
          placeholder="https://example.com"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <label>Name:</label>
        <input
          placeholder="Name"
          value={favoriteName}
          onChange={(e) => setFavoriteName(e.target.value)}
        />
        <button onClick={handleFetchLinkPreview}>
          Preview
        </button>
      </div>
    </div>
  );
};

export default LinkPreviewScreen;
