import React from "react";
import GaleryImage1 from "../../assets/GalleryImage1.png";
import GaleryImage2 from "../../assets/GalleryImage2.png";
import GaleryImage3 from "../../assets/GalleryImage3.png";
import GaleryImage4 from "../../assets/GalleryImage4.png";
import GaleryImage5 from "../../assets/GalleryImage5.png";
import GaleryImage6 from "../../assets/GalleryImage6.png";
import GaleryImage7 from "../../assets/GalleryImage7.png";

const Gallery = () => {
  return (
    <div className="py-5">
      <div className="flex gap-2 md:gap-7 items-center w-full justify-between">
        <div className="mb-10 md:block hidden w-15">
          <img src={GaleryImage1} alt="Gallery" className="w-full h-auto" />
        </div>
        <div className="flex flex-col gap-2 lg:gap-7 mb-10 md:mb-36 w-30 md:w-20">
          <div>
            <img src={GaleryImage2} alt="Gallery" className="w-full h-auto" />
          </div>
          <div>
            <img src={GaleryImage3} alt="Gallery" className="w-full h-auto" />
          </div>
        </div>
        <div className="w-2/5 md:w-30">
          <img src={GaleryImage4} alt="Gallery" className="w-full h-auto" />
        </div>
        <div className="flex flex-col gap-2 lg:gap-7 mb-20 md:mb-56 w-30 md:w-20">
          <div>
            <img src={GaleryImage5} alt="Gallery" className="w-full h-auto" />
          </div>
          <div>
            <img src={GaleryImage6} alt="Gallery" className="w-full h-auto" />
          </div>
        </div>
        <div className="md:block hidden w-15">
          <img src={GaleryImage7} alt="Gallery" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
