import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Logo from "../../assets/ANDANZA.svg";
import ToggleIcon from "../../assets/toggle.svg";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="px-3">
      <header className="max-w-6xl mx-auto flex items-center justify-between py-8">
        <div>
          <img src={Logo} alt="Logo" className="md:w-64 w-48" />
        </div>

        <div className="hidden md:flex justify-center items-center space-x-12">
          <li className="text-xl font-medium list-none">
            <Link to="#">Home</Link>
          </li>
         
        </div>
        <div className="md:hidden">
          <button className="focus:outline-none" onClick={toggleMobileMenu}>
            <img src={ToggleIcon} alt="Toggle Icon" className="w-12 h-4" />
          </button>
        </div>
      </header>

      {/* ======================================= On Mobile Screen */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed top-0 right-0 bottom-0 bg-grayish_blue text-white w-4/5 z-50 h-screen p-4">
          <button
            className="absolute top-4 right-4 text-2xl"
            onClick={closeMobileMenu}
          >
            <RxCross2 />
          </button>
          <div className="flex flex-col items-left space-y-6 py-8 mt-5">
            <li className="text-lg font-medium list-none">
              <Link to="#">Home</Link>
            </li>
            <li className="text-lg font-medium list-none">
              <Link to="#">App</Link>
            </li>
            <li className="text-lg font-medium list-none">
              <Link to="#">Gallery</Link>
            </li>
            <li className="text-lg font-medium list-none">
              <Link to="#">Home</Link>
            </li>
          </div>
        </div>
      )}
    </div>
  );
}
