import React, { useState } from 'react';
const CountryCodes = require('country-codes-list');

function CountryFilter({ onCountrySelect, selectedCountry }) {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);

  const filterCountries = (text) => {
   const filteredData = CountryCodes.customList(
      'countryNameEn',
      '{countryNameEn}. +{countryCallingCode}. {currencyCode}. {currencyNameEn}. {officialLanguageNameEn}. {countryCode}',
      {
        filter: (country) =>
          country.countryNameEn.toLowerCase().includes(text.toLowerCase()),
      }
    );

    const entries = Object.values(filteredData).map((entry) =>
      entry.replace(/"/g, '')
    );

    const arrayOfObjects = entries.map((entry, index) => {
      const parts = entry.split('. ');
      return {
        countryName: parts[0].trim(),
        countryCallingCode: parts[1].trim(),
        currencyCode: parts[2].trim(),
        currencyName: parts[3].trim(),
        officialLanguage: parts[4].trim(),
        countryCode: parts[5].trim(),
        // Add more properties as needed
      };
    });

    return arrayOfObjects;
  };

  const handleInputChange = (text) => {
    setSearchText(text);
  };

  const handleSearch = () => {
    const filtered = filterCountries(searchText);
    setFilteredData(filtered);
    setModalVisible(true);
  };

  const handleCountrySelect = (country) => {
    setSearchText(country.countryName);
    setModalVisible(false);
    onCountrySelect(country);
  };

  return (
     <div className="w-800 pt-6 bg-white">
      <input
        type="text"
        className="input border border-zinc-400 h-10 w-600 rounded"
        placeholder="Search for a country"
        onChange={(e) => handleInputChange(e.target.value)}
        value={searchText}
      />
      <button onClick={handleSearch} className="ml-10 bg-zinc-100 h-10 w-16  text-black rounded border border-zinc-400">Search</button>

      {isModalVisible && (
        <div className="modal-overlay" onClick={() => setModalVisible(false)}>
          <div className="container p-10 bg-white rounded border border-zinc-400">
            <ul>
              {filteredData.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleCountrySelect(item)}
                  className="resultItem p-2 border-b border-gray-300"
                >
                  {item.countryName} ({item.countryCode})
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default CountryFilter;
