import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="flex h-screen overflow-y-auto mx-auto">
      <Sidebar isVisible={isSidebarOpen} onToggleSidebar={toggleSidebar} />
      <div className="flex-grow-1 ml-0 md:ml-80 w-full">
        <Navbar onToggleSidebar={toggleSidebar} />
        <main className="py-4">{children}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
