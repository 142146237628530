import React from "react";
import CollaborativePlanning from "../../assets/Collaborative-Planning.svg";
import CustomizableItineraries from "../../assets/Customizable-Itineraries.svg";
import RealTimeUpdates from "../../assets/Real-Time-Updates.svg";
import AcrossDevices from "../../assets/Across-Devices.svg";
import TravelRecommendations from "../../assets/Travel.svg";
import SocialMedia from "../../assets/Social-Media-Integration.svg";
import ShareableMemories from "../../assets/Shareable-Memories.svg";

const Features = () => {
  const FeaturesData = [
    {
      image: CollaborativePlanning,
      heading: "Collaborative Planning",
      disc: "Join forces with friends and family to plan the perfect trip together. Andanza's collaborative tools make coordination a breeze.",
    },
    {
      image: CustomizableItineraries,
      heading: "Customizable Itineraries",
      disc: "Tailor your journey with precision. From must-visit landmarks to hidden gems, craft the ideal itinerary to suit your travel style.",
    },
    {
      image: RealTimeUpdates,
      heading: "Real-Time Updates",
      disc: "Stay in the loop with live updates. Know where everyone is, receive timely notifications, and ensure a smooth travel experience.",
    },
    {
      image: AcrossDevices,
      heading: "Seamless Sync Across Devices",
      disc: "Plan on the go with our mobile app and fine-tune details on the web. Your itinerary syncs seamlessly, ensuring you're always on the same page.",
    },
    {
      image: TravelRecommendations,
      heading: "Travel Recommendations",
      disc: "Discover local hotspots and recommendations curated by fellow travelers. Unlock insider tips for an authentic experience.",
    },
    {
      image: SocialMedia,
      heading: "Social Media Integration",
      disc: "Save and access recommendations from your favorite social media platforms. Gather insights from your network and infuse your itinerary with the best local gems.",
    },
    {
      image: ShareableMemories,
      heading: "Shareable Memories",
      disc: "Capture and share your favorite moments effortlessly. Andanza lets you create a digital scrapbook of your adventures.",
    },
  ];
  return (
    <div className="px-3 py-14 bg-grayish_blue text-white">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl md:text-50 font-bold tracking-1.1 mb-10">Key Features:</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {FeaturesData.map((item, index) => (
            <div key={index} className="flex flex-col gap-2 mb-4">
              <img src={item.image} alt={item.heading} className="w-6" />
              <h2 className="text-xl font-bold">{item.heading}</h2>
              <p className="text-sm">{item.disc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
