import React from "react";
import { Link } from "react-router-dom";
import TravelImage from "../../../assets/Travel.jpg"
import AcommoImage from "../../../assets/Acommo.jpg"
import ActivityImage from "../../../assets/Activity.jpg"
import FoodImage from "../../../assets/Food.jpg"


const ActivityRenderer = ({ event }) => {
  const MapLink = () => {
  const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${event.latitude},${event.longitude}`;
  
  
  return (
    <a
        href={mapsUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue_4 underline capitalize text-sm font-medium"
        >
        {event.address}
    </a>
  );
};
  const getPriceSigns = (price) => {
    switch (price) {
      case 1:
        return '$';
      case 2:
        return '$$';
      case 3:
        return '$$$';
      case 4:
        return '$$$$';
      default:
        return '';
    }
  };

const formatTime = (time) => {
  const date = new Date(`2000-01-01T${time}`);
  return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

const eventDescription = () => {
  const { type, time, numberofpeople } = event;
  const formattedTime = formatTime(time);
  const peopleLabel = numberofpeople <= 1 ? 'person' : 'people';

  return (
    <span>
      <strong>{type}</strong> at <strong>{formattedTime}</strong> for <strong>{numberofpeople}</strong> {peopleLabel}
    </span>
  );
};

  const renderContent = () => {
    switch (event.category) {
      case "Food":
        return (
          <div className="w-full lgmeduim:w-5/5">
            <p className="text-gray_2 text-base leading-4 mb-5">
              {`${event.type} for ${event.numberofpeople}`}
            </p>
         <div className="flex items-start justify-between gap-8">
             <div>
                <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Price:</span> <strong>{getPriceSigns(event.price)}</strong>
                </h2>
            </div>
              <div>
                <p className="text-gray_2 text-base leading-4 mb-5">
                Tel: {event.telephone}
                </p>
                <p className="text-blue_4 underline capitalize text-sm font-medium  mb-8">
                <a href={event.website} target="_blank" rel="noopener noreferrer" style={{ color: '#0066cc', textDecoration: 'underline' }}>
                    website
                </a>
                </p>
            </div>
            
            </div>
        <div className="flex justify-between gap-8">
            {event.image ? (
                <img
                  src={event.image}
                  alt={event.title2}
                  className="w-24 h-24 rounded-md"
                />
              ) : (
                <img
                  src={FoodImage}
                  alt="Food Box"
                  className="w-24 h-24 rounded-md"
                />
              )}
              <div>
                 <p className="text-gray_2 text-base leading-4 mb-5">
                    {event.address}
                    </p>
                <MapLink />
              </div>
            </div>
          </div>
        );

    case "Accomodation":
        return (
          <div className="w-full lgmeduim:w-11/12">
           <div className="flex items-start justify-between gap-11 w-98">
            <div className="flex-column w-40 pr-5">
              <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Check in:</span> 
               </h2>
               <h2>
               <strong>{event.arrivaldate}</strong>
               </h2>
              </div>
              <div className="flex-column w-40 ">
            <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Check out:</span> 
              </h2>
              <h2>
              <strong>{event.departuredate}</strong>
              </h2>
              </div>
              <div className="flex-column w-30 ">
              <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Check in time:</span> <strong>{event.time}</strong>
              </h2>
              </div>
            </div>
            <div className="flex items-start justify-between gap-8 pt-4">
             <div>
                <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Confirmation:</span> <strong>{event.confirmation}</strong>
                </h2>
            </div>
              <div>
                <p className="text-gray_2 text-base leading-4 mb-5">
                Tel: {event.telephone}
                </p>
                <p className="text-blue_4 underline capitalize text-sm font-medium  mb-8">
                <a href={event.website} target="_blank" rel="noopener noreferrer" style={{ color: '#0066cc', textDecoration: 'underline' }}>
                    website
                </a>
                </p>
            </div>
            
            </div>
            <div className="flex items-center gap-3">
            {event.image ? (
                <img
                  src={event.image}
                  alt={event.title2}
                  className="w-24 h-24 rounded-md"
                />
              ) : (
                <img
                  src={AcommoImage}
                  alt="Accomo Box"
                  className="w-24 h-24 rounded-md"
                />
              )}
              <div>
                <h2 className="text-lg font-medium text-heading_blue">
                  Directions
                </h2>
                <MapLink />
              </div>
            </div>
          </div>
        );
      // Add cases for other categories (Accommodation, Travel, etc.) as needed

 case "Travel":
        return (
          <div className="w-full lgmeduim:w-11/12">
           <div className="flex items-start justify-between gap-20">
            <div>
             <div>
              <h2 className="text-base text-heading_blue mb-1">
                 <strong>{event.origin}</strong>
              </h2>
              </div>
              <div className="w-60">
              <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Leaving:</span> <strong>{event.departuredate}</strong>
              </h2>
              </div>
               <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>At </span> <strong>{formatTime(event.departuretime)}</strong>
              </h2>
            </div>
           <div>
            <div>
              <h2 className="text-base text-heading_blue mb-1">
                 <strong>{event.destination}</strong>
              </h2>
              </div>
              <div className="w-60">
             <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Arriving:</span> <strong>{event.arrivaldate}</strong>
              </h2>
           </div>
                <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>At </span> <strong>{formatTime(event.arrivaltime)}</strong>
              </h2>
              </div>
           </div>
            <div className="flex items-start justify-between gap-8 pt-4">
             <div>
              <h2 className="text-base text-heading_blue mb-1">
                <strong>{event.carrier} {event.number}</strong>
                </h2>
                <h2 className="text-base text-heading_blue mb-1">
                <span style={{ fontWeight: 'normal' }}>Confirmation:</span> <strong>{event.confirmation}</strong>
                </h2>
            </div>
              <div>
                <p className="text-gray_2 text-base leading-4 mb-5">
                Tel: {event.telephone}
                </p>
                <p className="text-blue_4 underline capitalize text-sm font-medium  mb-8">
                <a href={event.website} target="_blank" rel="noopener noreferrer" style={{ color: '#0066cc', textDecoration: 'underline' }}>
                    website
                </a>
                </p>
            </div>
            
            </div>
            <div className="flex items-center gap-3">
            {event.image ? (
                <img
                  src={event.image}
                  alt={event.title2}
                  className="w-24 h-24 rounded-md"
                />
              ) : (
                <img
                  src={TravelImage}
                  alt="Travel Box"
                  className="w-24 h-24 rounded-md"
                />
              )}
              <div>
                <h2 className="text-lg font-medium text-heading_blue">
                  Directions
                </h2>
                <MapLink />
              </div>
            </div>
          </div>
        );
   
case "Activity":
        return (
          <div className="w-full lgmeduim:w-11/12">
           <div className="flex items-start justify-between gap-8">
            <div>
              {eventDescription()}
              </div>
             </div>
             <div className="gap-8 mt-8">
                <h2 className="text-base text-heading_blue mb-1">
                <strong>Description</strong>
                </h2>
                   <p className="text-gray_2 text-base leading-4 mb-5">
                {event.description}
                </p>
            </div>
            <div className="flex items-start justify-between gap-8 pt-4">
             <div>
                <p className="text-gray_2 text-base leading-4 mb-5">
                email: {event.email}
                </p>
                <p className="text-gray_2 text-base leading-4 mb-5">
                <a href={`mailto:${event.email}`} className="text-blue_4 underline capitalize text-sm font-medium">
                    Contact
                </a>
                </p>
            </div>
              <div>
                <p className="text-gray_2 text-base leading-4 mb-5">
                Tel: {event.telephone}
                </p>
                <p className="text-blue_4 underline capitalize text-sm font-medium  mb-8">
                <a href={event.website} target="_blank" rel="noopener noreferrer" style={{ color: '#0066cc', textDecoration: 'underline' }}>
                    website
                </a>
                </p>
            </div>
            
            </div>
            <div className="flex items-center gap-3">
            {event.image ? (
                <img
                  src={event.image}
                  alt={event.title2}
                  className="w-24 h-24 rounded-md"
                />
              ) : (
                <img
                  src={ActivityImage}
                  alt="Activity Box"
                  className="w-24 h-24 rounded-md"
                />
              )}
              <div>
                <h2 className="text-lg font-medium text-heading_blue">
                  Directions
                </h2>
                <MapLink />
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div>
            {/* Add a default rendering or handle unknown categories */}
          </div>
        );
    }
  };

  return <div>{renderContent()}</div>;
};

export default ActivityRenderer;
