import React from "react";
import { IoArrowForward } from "react-icons/io5";
import Assets1 from "../../assets/circle-1.png";
import Assets2 from "../../assets/circle-2.png";
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="px-3 md:py-14">
      <div className="max-w-6xl mx-auto">
        <div>
          <h1 className="text-3xl md:text-5xl font-medium leading-10 md:leading-71"></h1>
          <h1 className="text-3xl md:text-5xl font-medium leading-10 md:leading-71 whitespace-wrap break-words">
            Discover <b>Andanza </b> <br className="hidden md:flex" />
            Your Ultimate Trip Planner 
          </h1>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between md:items-center py-10 gap-4 relative">
          <div className="flex relative">
            <img src={Assets1} alt="Assets" className="w-50 md:w-60" />
            <img
              src={Assets2}
              alt="Assets"
              className="w-50 md:w-60 absolute left-16"
            />
            <img
              src={Assets1}
              alt="Assets"
              className="w-50 md:w-60  absolute left-32"
            />
          </div>
          <div className="flex justify-end items-end flex-col gap-2">
            <h3 className="font-semibold text-xl md:text-2xl">
              Get full access free 
            </h3>
            <Link to="/login">
            <button className="py-15 w-52 rounded-10 bg-black text-white font-medium tracking-0.8 text-lg flex justify-center items-center gap-2 my-7 md:my-0">
              Sign in
              <IoArrowForward className="text-light_green w-6 h-6" />
            </button>
            </Link>
          </div>
        </div>

        <div className="text-center w-full lgmeduim:w-2/3 mx-auto my-5">
          <p className="text-xl md:text-xl font-semibold leading-9">
            Embark on unforgettable journeys with Andanza, your go-to platform
            for seamless trip planning. Whether you're a solo adventurer or
            traveling with friends and family, our web and mobile apps empower
            you to create personalized itineraries that turn every moment into a
            cherished memory.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
